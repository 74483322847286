import dayjs from "dayjs";
import _, { trim } from "lodash";
import {
  MENU_PERMISSIONS,
  MENU_URL_PERMISSIONS,
} from "../util/constants/permissions.constants";
export function toQueryParam(url: string, params: any) {
  let query = url;
  const keys = Object.keys(params);
  const values = Object.values(params);
  keys.forEach((key, i) => {
    if (i === 0) {
      query += `?${key}=${values[i]}`;
    } else {
      query += `&${key}=${values[i]}`;
    }
  });
  return query;
}

export function formatDate(
  date?: string | null,
  format: string = "MM/DD/YYYY"
) {
  if (!date) dayjs(new Date()).format(format);
  return dayjs(date).format(format);
}

export type GenericObject = { [key: string]: string };

export const ACTION: GenericObject = {
  FETCH: "_FETCH",
  SUCCESS: "_SUCCESS",
  FAILED: "_FAILED",
  STATE_KEY: "_STATE_KEY",
  COLUMN: "_COLUMN",
  DATA_TYPE: "_DATA_TYPE",
};

export function actionKeyGenerate(key: string) {
  const Keys: any = {};

  Object.keys(ACTION).forEach((actionKey: string) => {
    const action_key = `${key}${ACTION[actionKey]}`;
    Keys[action_key] = action_key;
  });

  return Keys;
}

export function hasValue(obj: GenericObject) {
  return Boolean(Object.keys(obj).filter((key: string) => obj[key]).length);
}

export function insertAt(
  items: any,
  from: number,
  toRemove: number,
  item: any
) {
  let newItems = _.cloneDeep(items);
  newItems.splice(from, toRemove, item);
  return newItems;
}

export function getDuplicates(arr: [any]) {
  const duplicates: { [key: string]: number } = {};
  arr.forEach((val) => {
    duplicates[val] = duplicates[val] ? duplicates[val] + 1 : 1;
  });
  return Object.keys(duplicates)
    .filter((key: string) => duplicates[key] > 1)
    .map((key: string) => key);
}

export const replenishmentType = [
  { label: "Inventory Based", value: "Inventory Based" },
  { label: "Sales Based", value: "Sales Based" },
];
export const StatusValue = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const STATUS = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const LifeCycleStatusList = [
  "Not Yet Released",
  "Active",
  "Discontinued",
  "Markdown",
  "Recalled",
  "Undelivered NPI",
];

export const SKUGrade = ["A", "B", "C"];

export const ImportType: GenericObject = {
  PlanogramImport: "PlanogramImport",
  ValidateAcuityImport: "ValidateAcuityImport",
  OfficeCollectionImport: "OfficeCollectionImport",
  AssortmentImport: "AssortmentImport",
};

export const convertStrToArr = (data: any) => {
  return data?.trim()?.split(/[\s,\\]+/);
};

export function hasMenuLevelPermissions(menuTitle: string, roleName: string) {
  const titleName = menuTitle.replace(/\s/g, "").toLowerCase() + "_read";
 
  const roleBasedMenuPermission = geCombinedUniqueScopes(roleName); // Use roleName directly
  if (roleBasedMenuPermission) {
    return (
      Object.values(roleBasedMenuPermission).indexOf(titleName) > -1
    );
  }

  return false; // Default return when permission is not found
}

export function getMenuPathAndPermissions(roleName: any) {
  const roleBasedMenuPermission = geCombinedUniqueScopes(roleName);
  const urlpermissions = MENU_URL_PERMISSIONS;
  const requestUrl: string = window.location.pathname.toLowerCase();
  let basemenu: string | undefined;
  for (let menu in urlpermissions) {
    if (urlpermissions[menu].includes(requestUrl)) {
      basemenu = menu;
      break;
    }
  }
  if (!basemenu) return true;
  return roleBasedMenuPermission
    ? Object.values(roleBasedMenuPermission).indexOf(basemenu) > -1
    : true;
}

function geCombinedUniqueScopes(roleName: any) {
  const roles = roleName.split(',');
  const menuPermissionData: Record<string, any> = MENU_PERMISSIONS;
  var combinedscopes: string[][] = [];
  roles.forEach((role: string) => {
    combinedscopes.push(menuPermissionData[role]?.scopes)
  });
  let allscopes: string[] = combinedscopes.flat();
  return [...new Set(allscopes)];
}

