import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import {
  ContentCopyOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  ArchiveOutlined,
  UnarchiveOutlined,
} from "@mui/icons-material";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import useRolePermission from "src/hooks/useRolePermission";
import { formatDate } from "src/util/date";
import ColorTag from "src/components/common/ColorTag/ColorTag";

export default function filterColumns(
  deleteModal?: ((record: any) => void) | any,
  editDrawer?: ((record: any) => void) | any,
  filteredData?: any,
  archiveModal?: ((record: any) => void) | any,
  frameData?: any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const { hasPermission } = useRolePermission();
  const [records, setRecords] = useState(data);
  useEffect(() => {
    setRecords(data);
  }, [data]);
  useEffect(() => {
    if (filteredData?.length && filteredData[0] !== "none") {
      setRecords(filteredData);
    } else {
      setRecords(data);
    }
  }, [filteredData]);
  const vendors: any | null | undefined = [];
  const kitCollection: any | null | undefined = [];
  records?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    kitCollection.push({ text: row.Collection, value: row.Collection });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const columns = [
    {
      header: "KIT ID",
      accessorKey: "KitId",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 70,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      header: "KIT NAME",
      accessorKey: "KitName",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      header: "VENDOR NAME",
      accessorKey: "Vendor",
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: vendorName,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      header: "COLLECTION NAME",
      accessorKey: "Collection",
      enableColumnFilter: false,
      enableSorting: false,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      header: "DATE",
      accessorKey: "ModifiedDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        const modifiedDate = cell.row?.original.ModifiedDate;
        if (!modifiedDate || modifiedDate === "null") {
          return "N/A";
        }
        const formattedModifiedDate = formatDate(modifiedDate);
        return formattedModifiedDate;
      }
    },
    {
      header: "PLANNED SHIP DATE",
      accessorKey: "PlannedShipDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        const plannedShipDate = cell.row?.original.PlannedShipDate;
        if (!plannedShipDate || plannedShipDate === "null") {
          return "N/A";
        }
        const formattedPlannedShipDate = formatDate(plannedShipDate);
        return formattedPlannedShipDate;
      }
    },
    {
      header: "STATUS",
      accessorKey: "StatusText",
      enableColumnFilter: false,
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        const statusText = cell.row?.original.StatusText;
        const status =
          statusText === "Active"
            ? "Active"
            : statusText === "Archived"
              ? "Archived"
              : "Inactive";

        return <ColorTag type={status.toLowerCase()}>{statusText}</ColorTag>;
      }
    },
    {
      header: "ACTIONS",
      accessorKey: "actions",
      enableColumnFilter: false,
      enableSorting: false,
      size: 80,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            <div className="d-flex">
              <div>
                <Tooltip placement="top" title="Clone">
                  <Button
                    icon={<ContentCopyOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                    onClick={() => editDrawer(cell.row?.original, "Clone")}
                    children={undefined}
                    disabled={!hasPermission("kitmaintenance_clone")}
                  ></Button>
                </Tooltip>
              </div>

              {cell.row?.original.ProcessStatus === 3 || cell.row?.original.ProcessStatus === 4 ? (
                <Tooltip placement="top" title="Kit Details">
                  <Button
                    style={{ marginLeft: "20px" }}
                    icon={<SearchOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                    children={undefined}
                    onClick={() => editDrawer(cell.row?.original, "Details")}
                  ></Button>
                </Tooltip>
              ) : (
                <>
                  <Tooltip placement="top" title="Edit">
                    <Button
                      icon={<EditOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                      style={{ marginLeft: "20px" }}
                      children={undefined}
                      onClick={() => editDrawer(cell.row?.original, "Edit")}
                      disabled={!hasPermission("kitmaintenance_edit")}
                    ></Button>
                  </Tooltip>
                  <div>
                    <Tooltip placement="top" title="Delete">
                      <Button
                        style={{ marginLeft: "20px" }}
                        icon={<DeleteOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                        onClick={() => deleteModal(cell.row?.original)}
                        children={undefined}
                        danger={true}
                        disabled={!hasPermission("kitmaintenance_delete")}
                      ></Button>
                    </Tooltip>
                  </div>
                </>
              )}
              {cell.row?.original.ProcessStatus === 3 && cell.row?.original.IsArchived && (
                <Tooltip placement="top" title="Unarchive">
                  <Button
                    style={{ marginLeft: "20px" }}
                    icon={<UnarchiveOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                    children={undefined}
                    onClick={() => archiveModal(cell.row?.original)}
                    danger={true}
                  ></Button>
                </Tooltip>
              )}
              {cell.row?.original.ProcessStatus === 3 && !cell.row?.original.IsArchived && (
                <Tooltip placement="top" title="Archive">
                  <Button
                    style={{ marginLeft: "20px" }}
                    icon={<ArchiveOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                    children={undefined}
                    onClick={() => archiveModal(cell.row?.original)}
                    danger={true}
                    disabled={!hasPermission("kitmaintenance_archive")}
                  ></Button>
                </Tooltip>
              )}
            </div>
          </>
        );
      }
    },
  ];

  // frame data table filters
  const collection: any | null | undefined = [];
  const modal: any | null | undefined = [];
  const color: any | null | undefined = [];
  const frameType: any | null | undefined = [];
  frameData?.data?.map((row: any) => {
    collection.push({ text: row.Collection, value: row.Collection });
    modal.push({ text: row.Modal, value: row.Modal });
    color.push({ text: row.Color, value: row.Color });
    frameType.push({ text: row.FrameType, value: row.FrameType });
  });

  const collectionColumns = {
    header: "COLLECTION",
    accessorKey: "Collection",
    enableColumnFilter: true,
    filterFn: 'contains',
    enableSorting: true,
    size: 150,
  };
  const modalColumns = {
    header: "MODEL",
    accessorKey: "Model",
    enableColumnFilter: true,
    filterFn: 'contains',
    enableSorting: true,
    size: 150,
  };
  const colorColumns = {
    header: "COLOR",
    accessorKey: "Color",
    enableColumnFilter: true,
    filterFn: 'contains',
    enableSorting: true,
    size: 120,
    Cell: ({ cell }: { cell: any }) => {
      const color = cell.row?.original.Color;
      if (!color || color === "null") {
        return "N/A";
      }
      return color;
    }
  };
  const frameTypeColumns = {
    header: "FRAME TYPE",
    accessorKey: "FrameType",
    enableColumnFilter: true,
    filterFn: 'contains',
    enableSorting: true,
    size: 120,
  };
  const KITS_FRAME_COLUMN = [
    {
      header: "COLOR DESCRIPTION",
      accessorKey: "ColorDescription",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
    },
    {
      header: "EYE SIZE",
      accessorKey: "EyeSize",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
    },
    {
      id: "UPC",
      header: "UPCCODE",
      accessorKey: "UPC",
      enableColumnFilter: true,
      filterFn: 'contains',
      size: 120,
    },
    {
      header: "MED COST",
      accessorKey: "MEDCost",
      enableSorting: true,
      size: 100,
    },
  ];
  let frameColumns = insertAt(KITS_FRAME_COLUMN, 0, 0, collectionColumns);
  frameColumns = insertAt(frameColumns, 1, 0, modalColumns);
  frameColumns = insertAt(frameColumns, 2, 0, colorColumns);
  frameColumns = insertAt(frameColumns, 6, 0, frameTypeColumns);
  const FrameActionColumn = {
    header: "ACTION",
    accessorKey: "operation",
    align: "center",
    size: 100,
    Cell: ({ cell }: { cell: any }) => {
      return (
        <>
          <div className="d-flex w-100">
            <Button
              style={{ marginLeft: "20px" }}
              icon={<DeleteOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
              onClick={() => deleteModal(cell.row?.original)}
              children={undefined}
              danger={true}
            ></Button>
          </div>
        </>
      );
    },
  };
  frameColumns = insertAt(frameColumns, 9, 0, FrameActionColumn);

  return { columns, frameColumns, data, loading };
}
