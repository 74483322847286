import { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import styled from "styled-components";
import { BULK_COST_UPDATE_DETAIL_COLUMN, BULK_COST_UPDATE_VALIDATION_COLUMN } from "./bcuColumns";
import { showErrorNotification } from "src/util/notifications";
import { fetchBulkCostUpdateDetails, fetchBulkCostUpdateValidations, runBulkCostUpdateValidation } from "src/components/bulkCostUpdate/sagas";
import GenericGrid from "src/components/common/Grid/GenericGrid";

interface Props {
  param: any;
  modalTitle: any;
}

function BulkCostUpdateLinks({ param, modalTitle }: Props) {
  const [open, setOpen] = useState(false);
  const [bcuData, setBcuData] = useState<any>();
  
  useEffect(() => {
    if (open) {
       handleLink();
    }
  }, [open]);

  const handleLink = () => {
    let res;
    if (modalTitle === "Bulk Cost Update Details") {
      res = fetchBulkCostUpdateDetails(param);
    }
    else {
      res = fetchBulkCostUpdateValidations(param);
    }
    res
      ?.then((res: any) => {
        if (res?.length) {
          setBcuData(res);
          setOpen(true);
        } else {
          if (modalTitle === "Bulk Cost Update Details") {
            showErrorNotification(`Error: Unable to display data due to an issue with the uploaded file. Please check and re-upload.`);
          }
          else {
            showErrorNotification(`Validation Failed: The uploaded file contains errors. Please correct them and try again.`);
          }
        }
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };
  const modelWidth = 1000;
  let content;
  let tblContent;
  if (modalTitle === "Bulk Cost Update Details") {
    content = <StyledLocation>
      <span onClick={handleLink}>View Data</span>
    </StyledLocation>;
    tblContent = <GenericGrid 
    columns={BULK_COST_UPDATE_DETAIL_COLUMN}
    data={bcuData}
    enableRowSelection={false}
    rowSelection={[]}
    columnPinning={{}}
    filterDisplayMode="popover"
    highlightFilterMatch={true}
    paginationDisplayMode="pages"
    enablePagination={false}
    enableStickyHeader={true}
    enableStickyFooter={true}
    enableColumnPinning={false}
    isLoading={false}
    muiTableBodyCellProps={{
      sx: {
        fontSize: '11px',
        textAlign: 'center',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        padding: '2px 10px'
      },
    }}
    muiTableHeadCellProps={{
      sx: {
        fontSize: '10px',
        fontWeight: '800',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        textAlign: 'center',
        padding: '2px 10px'
      }
    }}
    muiTableBodyRowProps={{
      sx: {
        "& td": {
          padding: "2px 10px",
        },
        textAlign: 'left',
      },
    }}
  ></GenericGrid>;
  
  } else {
    content = <StyledLocation>
      <span onClick={handleLink}>View Validations</span>
    </StyledLocation>;
    tblContent = <GenericGrid 
    columns={BULK_COST_UPDATE_VALIDATION_COLUMN}
    data={bcuData}
    enableRowSelection={false}
    rowSelection={[]}
    columnPinning={{}}
    filterDisplayMode="popover"
    highlightFilterMatch={true}
    paginationDisplayMode="pages"
    enablePagination={false}
    enableStickyHeader={true}
    enableStickyFooter={true}
    enableColumnPinning={false}
    isLoading={false}
    muiTableBodyCellProps={{
      sx: {
        fontSize: '11px',
        textAlign: 'center',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        padding: '2px 10px'
      },
    }}
    muiTableHeadCellProps={{
      sx: {
        fontSize: '10px',
        fontWeight: '800',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        textAlign: 'center',
        padding: '2px 10px'
      }
    }}
    muiTableBodyRowProps={{
      sx: {
        "& td": {
          padding: "2px 10px",
        },
        textAlign: 'left',
      },
    }}
  ></GenericGrid>;
  }
  return (
    <>
      {content}
      <Modal
        title={modalTitle}
        okText="Ok"
        centered
        width={modelWidth}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        {" "}
        {tblContent}
      </Modal>
    </>
  );
}

const StyledLocation = styled.div`
  color: #40668c;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledItem = styled.div`
  align: "center";
`;

export default BulkCostUpdateLinks;
