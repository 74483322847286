import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Select,
  Checkbox,
  Descriptions,
} from "antd";
import { useEffect, useState } from "react";
import { fetchVendorList } from "src/components/vendor/vendor-sagas";
import {
  getCollection,
  getCollectionType,
  saveVendorCollectionMapping,
  updateVendorCollectionMapping,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { useMsal } from "@azure/msal-react";

interface AddVCMappingType {
  visible: boolean;
  setVisibleStatus: any;
  onLoad: any;
  data: any;
  setData: any;
}

function AddVCMapping({
  visible,
  setVisibleStatus,
  onLoad,
  data,
  setData,
}: AddVCMappingType) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [vendors, setVendors] = useState([]);
  const [collection, setCollection] = useState([]);
  const { accounts }: any = useMsal();
  const [formData, setFormData] = useState<any>({
    AcuityCollectionID: "",
    AcuityVendorID: "",
  });
  const [collectionTypes, setCollectionTypes] = useState([]);
  useEffect(() => {
    if (Object.keys(data).length) {
      const editFieldObj = {
        Vendor: data?.VendorName,
        Collection: data?.CollectionName,
        CollectionType: data?.CollectionType,
        Notes: data?.Notes,
        DatabaseCollectionID: data?.DatabaseCollectionID,
        DCReplenishment: data?.DCReplen,
      };
      frameDetailForm.setFieldsValue(editFieldObj);
    }
    setFormData({
      AcuityCollectionID: data?.AcuityCollectionID,
      AcuityVendorID: data?.AcuityVendorID,
    });
  }, [data]);

  useEffect(() => {
    Promise.all([fetchVendorList(), getCollection(), getCollectionType() ]).then((res) => {
      const sortedVendors: any = res[0]?.sort((a: any, b: any) => {
        return a.VendorName.localeCompare(b.VendorName);
      });
      setVendors(sortedVendors);
      const sortedFrameType: any = res[1]?.sort((a: any, b: any) => {
        return a.Description.localeCompare(b.Description, "sv");
      });
      setCollection(sortedFrameType);

      const sortedCollectionType: any = res[2]?.sort((a: any, b: any) => {
        return a.Description.localeCompare(b.Description, "sv");
      });
      setCollectionTypes(sortedCollectionType);
    });
  }, []);
  const handleClose = () => {
    frameDetailForm.resetFields();
    setData({});
    setVisibleStatus(false);
    setFormData({
      AcuityCollectionID: "",
      AcuityVendorID: "",
    });
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;
      let fieldData: any = {
        Notes: fields.Notes,
        DCReplen: fields.DCReplenishment ? fields.DCReplenishment : false,
        CollectionType: fields.CollectionType,
      };
      let payload = { ...fieldData, ...formData };
      if (Object.keys(data).length) {
        payload = {
          ...payload,
          VendorName: data.VendorName,
          CollectionName: data.CollectionName,
          DatabaseCollectionID: data?.DatabaseCollectionID,
          UserName: accounts[0]?.username,
        };
        res = await updateVendorCollectionMapping(payload);
      } else {
        const vendorName: any = vendors.filter(
          (item: any) => fields.Vendor === item.ID
        );
        const collectionName: any = collection.filter(
          (item: any) => fields.Collection === item.ID
        );
        const collectionType: any = collectionTypes.filter(
          (item: any) => fields.CollectionType === item.Description
        );
        payload = {
          ...payload,
          VendorName: vendorName[0].VendorName,
          CollectionName: collectionName[0].Description,
          CollectionType: collectionType[0].Description,
          UserName: accounts[0]?.username,
        };
        res = await saveVendorCollectionMapping(payload);
      }
      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error.response.data.error.messages[0];
        showErrorNotification(errMessage);
      }
    }
  };

  const drawerTitle = Object.keys(data).length
    ? "Edit Vendor Collection Mapping"
    : "Add Vendor Collection Mapping";
  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        open={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button danger onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        <div className="">
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <Card>
              <div>
                <Form.Item
                  label="Vendor"
                  name="Vendor"
                  className="label-text region-field"
                  rules={[
                    !Object.keys(data).length
                      ? { required: true, message: "Please select vendor" }
                      : {},
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Vendor"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                    onChange={(value) =>
                      setFormData({
                        AcuityCollectionID: formData.AcuityCollectionID,
                        AcuityVendorID: value,
                      })
                    }
                  >
                    {vendors?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.ID}>
                          {item?.VendorName} ({item?.ID})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Collection"
                  name="Collection"
                  className="label-text region-field"
                  rules={[
                    { required: true, message: "Please select collection" },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select collection"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    disabled={!!Object.keys(data).length}
                    filterOption={(input, option) => {
                      return option!.children?.[0] !== null
                        ? (option!.children?.[0] as unknown as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        : false;
                    }}
                    onChange={(value) =>
                      setFormData({
                        AcuityCollectionID: value,
                        AcuityVendorID: formData.AcuityVendorID,
                      })
                    }
                  >
                    {collection?.map((item: any, index) => {
                      return (
                        <Option key={index} value={item?.ID}>
                          {item?.Description} ({item?.ID})
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>  
                <Form.Item
                  label="Collection Type"
                  name="CollectionType"
                  className="label-text region-field"
                  rules={[{ required: true, message: "Please select vendor" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Collection Type"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {collectionTypes?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.Description}>
                          {item?.Description}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Card>
            <Card>
              <div>
                <Form.Item
                  label="Notes"
                  name="Notes"
                  className="label-text region-field"
                >
                  <Input name="Notes" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  valuePropName="checked"
                  name="DCReplenishment"
                  className="label-text region-field"
                >
                  <Checkbox name="DCReplenishment" value={true}>
                    {" "}
                    DC Replenishment{" "}
                  </Checkbox>
                </Form.Item>
              </div>
            </Card>
          </Form>
        </div>
      </Drawer>
    </>
  );
}

export default AddVCMapping;
