import { Divider, Form, Input, Spin } from "antd";
import { MODULE_KEY } from "./actions";
import { useEffect, useRef, useState } from "react";
import {
  fetchOrderItems,
  fetchVendorOrderList,
  saveVendorOrderItems,
} from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import TableFilter from "../common/TableFilter/TableFilter";
import Button from "../common/Button/Button";
import DescriptionItems from "./DescriptionItems";
import { getUserName } from "../common/UserDetails";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "../common/Grid/GenericGrid";
import { ACTION } from "src/util/utils";
import { useSelector } from "react-redux";

function VendorOrders() {
  const userName = getUserName();
  const [show, setShow] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [updateData, setupdateData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>([]);
  const [invoiceNumber, setInvoiceNumber] = useState<any>("");
  const inputRef = useRef<any>();
  const [form] = Form.useForm();
  const [orderDetails, setOrderDetails] = useState({
    OrderNumber: null,
    OrderDate: "",
    VendorName: "",
  });

  const { loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const validateRequired = (value: string) => !!value.length;
  const columns = [
    {
      header: "ID",
      accessorKey: "ID",
      size: 80,
      show: false,
    },
    {
      header: "ITEM ID",
      accessorKey: "ItemID",
      size: 80,
      show: false,
    },
    {
      header: "ITEM NAME",
      accessorKey: "ItemName",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableEditing: false,
    },
    {
      header: "UPCCODE",
      accessorKey: "UPCCode",
      size: 100,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableEditing: false,
    },
    {
      header: "ORDERED QTY",
      accessorKey: "Quantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
    },
    {
      header: "PREV RECEIVED QTY",
      accessorKey: "PreviousReceived",
      size: 125,
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
    },
    {
      header: "PREV ITEM PRICE",
      accessorKey: "PreviousCost",
      size: 115,
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      Cell: ({ cell }: { cell: any }) => {
        const previousCost = cell.row?.original?.PreviousCost;
        return "$" + previousCost;
      },
    },
    {
      header: "RECEIVED QTY",
      accessorKey: "Received",
      enableColumnFilter: true,
      filterFn: 'contains',
      muiEditTextFieldProps: (
        cell: any,
        column: any,
        row: any,
        table: any
      ) => ({
        type: "text",
        required: true,
        error: !!validationErrors?.[cell.cell.id],
        helperText: validationErrors?.[cell.cell.id],
        onBlur: (event: any) => {
          const validationError = !validateRequired(event.currentTarget.value)
            ? "Required"
            : undefined;

          setValidationErrors({
            ...validationErrors,
            [cell.cell.id]: validationError,
          });

          var index = getItemIndex(
            (item) => item.ItemID === cell.row.original.ItemID
          );
          if (index === -1)
            updateData.push({
              ID: cell.row.original.ID,
              ItemID: cell.row.original.ItemID,
              Received: event.target.value,
              Cost: cell.row.original.Cost,
            });
          else updateData[index].Received = event.currentTarget.value;

          setupdateData([...updateData]);
        },
      }),
    },
    {
      header: "ITEM PRICE",
      accessorKey: "Cost",
      enableColumnFilter: true,
      filterFn: 'contains',
      size: 110,
      muiEditTextFieldProps: (cell: any, row: any) => ({
        type: "text",
        required: true,
        error: !!validationErrors?.[cell.cell.id],
        helperText: validationErrors?.[cell.cell.id],
        onBlur: (event: any) => {
          const validationError = !validateRequired(event.currentTarget.value)
            ? "Required"
            : undefined;

          setValidationErrors({
            ...validationErrors,
            [cell.cell.id]: validationError,
          });

          var index = getItemIndex(
            (item) => item.ItemID === cell.row.original.ItemID
          );
          if (index === -1)
            updateData.push({
              ID: cell.row.original.ID,
              ItemID: cell.row.original.ItemID,
              Received: cell.row.original.Received,
              Cost: event.target.value,
            });
          else updateData[index].Cost = event.currentTarget.value;

          setupdateData([...updateData]);
        },
      }),
    },
  ];

  const getItemIndex = (
    condition: (item: {
      ID: any;
      ItemID: any;
      Received: any;
      Cost: any;
    }) => boolean
  ): number => {
    return updateData.findIndex(condition);
  };

  const handleData = async () => {
    const items = fetchOrderItems({ orderNumber: orderNumber });
    items
      .then((res) => {
        const notEqualQuntity = res?.filter(
          (record: any) => record?.PreviousReceived !== record?.Quantity
        );
        const equalQuntity = res?.filter(
          (record: any) => record?.PreviousReceived === record?.Quantity
        );
        const data = [...notEqualQuntity, ...equalQuntity];
        setInitialData(data);
      })
      .catch((error) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };

  useEffect(() => {
    setInitialData([]);
    if (orderNumber !== "") {
      handleData();
    }
  }, [orderNumber]);

  const handleCancel = () => {
    setInitialData([]);
    handleData();
    setupdateData([]);
    form.resetFields();
    setInvoiceNumber("");
  };

  const handleOrderDetails = async (values: any) => {
    setConfirmLoading(true);
    setShow(false);
    setOrderNumber(values?.OrderNumber);
    try {
      const res = await fetchVendorOrderList({
        VendorOrderNumber: values?.OrderNumber,
        UserName: userName,
      });
      if (res) {
        form.resetFields();
        setOrderDetails(res);
        setShow(true);
      } else {
        showErrorNotification(res.error.message[0]);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
    setConfirmLoading(false);
  };

  const onSaveChanges = async () => {
    try {
      const row = (await form.validateFields()) as any;
      setConfirmLoading(true);
      let objs: { ID: any; ItemID: any; Received: any; Cost: any }[] = [];

      updateData.map((item: any, ind: number) => {
        if (
          item.Received !== 0 &&
          item.Received !== "0" &&
          item.Received.trim() != ""
        ) {
          objs.push({
            ID: item.ID,
            ItemID: item.ItemID,
            Received: item.Received,
            Cost: item.Cost,
          });
        }
      });
      const vendorItem = [...objs];
      let payload = {
        vendorItem: vendorItem,
        VendorReceiptNumber: invoiceNumber,
        UserName: userName,
        OrderNumber: orderNumber,
      };
      if (vendorItem.length > 0) {
        const res = await saveVendorOrderItems(payload);
        if (res) {
          setInitialData([]);
          handleData();
          setupdateData([]);
          showSuccessNotification(res);
        } else {
          showErrorNotification(res);
        }
      } else {
        showErrorNotification("Please enter at least one Received Quantity");
      }
    } catch (error: any) {
      let errMessage = "";
      if (error?.errorFields?.[0]?.errors) {
        errMessage = error.errorFields[0].errors[0];
      } else if (error?.response?.data) {
        errMessage = error?.response?.data;
        showErrorNotification(errMessage);
      }
    }
    setConfirmLoading(false);
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Vendor Receipt</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex">
            <Form name="order" size="large" onFinish={handleOrderDetails}>
              <div style={{ display: "flex" }}>
                <Form.Item
                  name="OrderNumber"
                  rules={[
                    {
                      required: true,
                      message: `Please enter order number`,
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Order number should be numeric`,
                    },
                  ]}
                >
                  {/* <Label label="Order Number"> */}
                  <Input placeholder="Order Number" ref={inputRef} />
                  {/* </Label> */}
                </Form.Item>
                <Form.Item>
                  <div>
                    <Button
                      htmlType="submit"
                      size="middle"
                      style={{ marginLeft: 10 }}
                    >
                      Get Order Details
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>
          {show && (
            <div className="d-flex justify-content-end">
              <Button
                size="middle"
                onClick={onSaveChanges}
                style={{ marginLeft: "10px" }}
                // disabled={!updateData.length}
              >
                Update
              </Button>
              <Button
                onClick={handleCancel}
                size="middle"
                style={{ marginLeft: "10px" }}
                danger
              >
                Cancel
              </Button>
            </div>
          )}
        </TableFilter>
        {show && (
          <>
            <DescriptionItems
              orderDetails={orderDetails}
              form={form}
              invoiceNumber={invoiceNumber}
              setInvoiceNumber={setInvoiceNumber}
            />
            <GenericGrid
              columns={columns}
              data={initialData}
              enableRowSelection={false}
              rowSelection={[]}
              columnPinning={{}}
              filterDisplayMode="popover"
              highlightFilterMatch={true}
              paginationDisplayMode="pages"
              paginationProps={{
                color: "primary",
                shape: "rounded",
                showRowsPerPage: true,
                variant: "outlined",
                rowsPerPageOptions: [10, 50, 100, 500, 1000],
              }}
              columnVisibility={{ ID: false, ItemID: false }}
              enableStickyHeader={true}
              enableStickyFooter={true}
              enableColumnPinning={true}
              isLoading={loading}
              enableEditing={true}
              onSaveChanges={onSaveChanges}
              muiTableBodyCellProps={{
                sx: {
                  fontSize: "11px",
                  textAlign: "center",
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                  padding: "2px 10px",
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  fontSize: "10px",
                  fontWeight: "800",
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                  textAlign: "center",
                  padding: "2px 10px",
                },
              }}
              muiTableBodyRowProps={{
                sx: {
                  "& td": {
                    padding: "2px 10px",
                  },
                  textAlign: "left",
                },
              }}
            ></GenericGrid>
          </>
        )}
      </Spin>
    </>
  );
}

export default VendorOrders;
