import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import useRolePermission from "src/hooks/useRolePermission";
import SearchIcon from '@mui/icons-material/Search';
import { formatDate } from "src/util/date";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((record: any) => void) | any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null[] | undefined = [];
  const collection: any | null | undefined = [];
  const collectionType: any | null | undefined = [];
  const { hasPermission } = useRolePermission();

  data?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    collection.push({ text: row.Collection, value: row.Collection });
    collectionType.push({
      text: row.CollectionType,
      value: row.CollectionType,
    });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let collectionTypeName = collectionType.filter(
    (ele: any, ind: any) =>
      ind ===
      collectionType.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const columns = [
    {
      id: 'OfficeNum',
      header: 'OFFICE NUMBER',
      accessorKey: 'OfficeNum',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'OFFICE NAME',
      accessorKey: 'LocationName',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'ASSORTMENT ID',
      accessorKey: 'FrameArrangerID',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'ASSORTMENT NAME',
      accessorKey: 'AssortmentName',
      size: 160,
      enableSorting: true,
      enableColumnFilter: true,
      filterFn: 'contains',
    },
    {
      header: 'ASSORTMENT QUANTITY',
      accessorKey: 'ArrangerQty',
      size: 160,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: 'VENDOR NAME',
      accessorKey: 'Vendor',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: vendorName 
    },
    {
      header: 'COLLECTION NAME',
      accessorKey: 'Collection',
      size: 160,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: collectionName
    },
    {
      header: 'COLLECTION TYPE',
      accessorKey: 'CollectionType',
      size: 160,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: collectionTypeName 
    },
    {
      header: 'REGION',
      accessorKey: 'Region',
      size: 100,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'DISTRICT',
      accessorKey: 'District',
      size: 100,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'START DATE',
      accessorKey: 'UpdatedDate',
      size: 120,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any  }) => {
        const startDate = cell.row?.original.UpdatedDate;
        if (!startDate || startDate === "null") {
            return "N/A";
        }
        const formattedStartDate = formatDate(startDate);
        return formattedStartDate;
       }
    },
    {
      header: 'CHANGED NOTES',
      accessorKey: 'UpdateNotes',
      size: 130,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: 'STOP DATE',
      accessorKey: 'StopDate',
      size: 120,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any  }) => {
        const stopDate = cell.row?.original.StopDate;
        if (!stopDate || stopDate === "null") {
            return "N/A";
        }
        const formattedStopDate = formatDate(stopDate);
        return formattedStopDate;   

       }
    },
    {
      accessorKey: 'actions',
      header: 'ACTIONS',
      size: 80,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            <div className="d-flex w-100">
              <Button
                icon={<EditOutlined sx={{ fontSize: 20 ,marginLeft: "10px", marginRight: "10px"}}/>}
                children={undefined}
                onClick={() => editDrawer(cell.row?.original)}
                disabled={!hasPermission("officecollectionsmapping_edit")}
              ></Button>
              <Button
                style={{ marginLeft: "10px", marginRight: "10px" }}
                icon={<DeleteOutlined sx={{ fontSize: 20,marginLeft: "10px", marginRight: "10px" }} />}
                onClick={() => deleteModal(cell.row?.original?.ID)}
                children={undefined}
                danger={true}
                disabled={!hasPermission("officecollectionsmapping_delete")}
              ></Button>
            </div>
          </>
        );
      }
    }
  ]

  return { columns, data, loading };
}
