import { useSelector } from "react-redux";
import { ACTION } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { PrinterOutlined } from "@ant-design/icons";
import { MODULE_KEY } from "./actions";
import dayjs from "dayjs";
import { formatDate } from "src/util/date";

export default function filterColumns(reprint: ((record: any) => void) | any) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  const columns = [
    {
      id: 'OfficeNumber',
      header: 'OFFICE ID',
      accessorKey: 'OfficeNumber',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: 'OfficeName',
      header: 'OFFICE NAME',
      accessorKey: 'OfficeName',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: 'Address',
      header: 'ADDRESS',
      accessorKey: 'Address',
      size: 180,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    },
    {
      id: 'PhoneNumber',
      header: 'PHONE',
      accessorKey: 'PhoneNumber',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      accessorKey: 'TransactionId',
      header: 'TRANSACTION NUMBER',
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      accessorKey: 'TrackingNumber',
      header: 'TRACKING NUMBER',
      size: 150,
      enableColumnFilter: false,
      enableSorting: false
    },
    {
      accessorKey: 'ServiceType',
      header: 'SERVICE TYPE',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false

    },
    {
      accessorKey: 'ExpectedDeliveryDate',
      header: 'EXPECTED DELIVERY DATE',
      size: 150,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        const expectedDeliveryDate = cell.row?.original.ExpectedDeliveryDate;
        if (!expectedDeliveryDate || expectedDeliveryDate === "null") {
          return "N/A";
        }
        const formattedExpectedDeliveryDate = formatDate(expectedDeliveryDate);
        return formattedExpectedDeliveryDate;

      }
    },
    {
      accessorKey: 'EstimatedCost',
      header: 'ESTIMATED COST',
      size: 150,
      enableColumnFilter: false,
      enableSorting: false
    },
    {
      accessorKey: 'actions',
      header: 'ACTION',
      size: 150,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            {dayjs(cell.row?.original?.CreatedDate).isSame(dayjs(), "day") ? (
              <Button
                icon={<PrinterOutlined />}
                onClick={() => reprint(cell.row?.original)}
                children={undefined}
              ></Button>
            ) : (
              "No Action"
            )}
          </>
        );
      }
    }
  ]

  return { columns, data, loading };
}
