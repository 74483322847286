import { call, put } from "redux-saga/effects";
import {
  fetchBulkCostUpdateSummarySuccess,
  importFileFailed,
  importFileSuccess,
  cleanImportLayout,
} from "./actions";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import {
  BULK_COST_UPDATE_URL,
} from "src/util/constants/api.constants";

export function* fetchBulkCostUpdateSummary(): Generator {
  try {
    let url = BULK_COST_UPDATE_URL.BULK_COST_UPDATE_SUMMARY_LIST;
    const data: any = yield call(api.get, url);
    yield put(fetchBulkCostUpdateSummarySuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function* importBulkCostUpdateFile({ payload, actionType }: any): Generator {
  try {
    let url = "distribution/v1/ImportBulkCostUpdateData/" + payload.UserName;
    //let url = "ImportBulkCostUpdateData/" + payload.UserName; // For Local testing
    const formdata = new FormData();
    formdata.append("file", payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(importFileSuccess(data));
  } catch (e: any) {
    yield put(importFileFailed(e.response.data));
  }
}

export function fetchBulkCostUpdateDetails(payload: any) {
  let url = BULK_COST_UPDATE_URL.BULK_COST_UPDATE_DETAILS + payload;
  return api.get(url);
}

export function fetchBulkCostUpdateValidations(payload: any) {
  let url = BULK_COST_UPDATE_URL.BULK_COST_UPDATE_VALIDATIONS + payload;
  return api.get(url);
}

export function runBulkCostUpdateValidation(payload: any) {
  let url = BULK_COST_UPDATE_URL.BULK_COST_UPDATE_VALIDATION;
  return api.post(url, payload);
}

export function finalUpdate(payload: any) {
  let url = BULK_COST_UPDATE_URL.BULK_COST_UPDATE_FINAL_UPDATE;
  return api.post(url, payload);
}