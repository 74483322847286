import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import ColorTag from "src/components/common/ColorTag/ColorTag";
import useRolePermission from "src/hooks/useRolePermission";
import { EditOutlined } from "@mui/icons-material";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null | undefined = [];
  const collection: any | null | undefined = [];
  const collectionTypes: any | null | undefined = [];
  const dcReplen: any | null | undefined = [];
  data?.map((row: any) => {
    vendors.push({ text: row.VendorName, value: row.VendorName });
    collection.push({ text: row.CollectionName, value: row.CollectionName });
    collectionTypes.push({ text: row.CollectionType, value: row.CollectionType });
    if (row.DCReplen === true) {
      dcReplen.push({ text: "Yes", value: row.DCReplen });
    } else if (row.DCReplen === false) {
      dcReplen.push({ text: "No", value: row.DCReplen });
    } else {
      dcReplen.push({ text: "NA", value: row.DCReplen });
    }
  });
  const { hasPermission } = useRolePermission();

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let collectionType = collectionTypes.filter(
    (ele: any, ind: any) =>
      ind ===
      collectionTypes.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const columns = [
    {
      id: 'DatabaseCollectionID',
      header: 'DATABASE COLLECTION ID',
      accessorKey: 'DatabaseCollectionID',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'ACUITY COLLECTION ID',
      accessorKey: 'AcuityCollectionID',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'ACUITY VENDOR ID',
      accessorKey: 'AcuityVendorID',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'VENDOR NAME',
      accessorKey: 'VendorName',
      size: 140,
      enableSorting: true,
      enableColumnFilter: true,
      filterVariant: 'multi-select',
      filterSelectOptions: vendorName
    },
    {
      header: 'COLLECTION NAME',
      accessorKey: 'CollectionName',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: collectionName
    },
    {
      header: 'COLLECTION TYPE',
      accessorKey: 'CollectionType',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: collectionType
    },
    {
      header: 'NOTES',
      accessorKey: 'Notes',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        const notes = cell.row?.original.Notes;
        if (!notes || notes === "null") {
          return "N/A";
        }
        return notes;

      }
    },
    {
      header: 'DC REPLENISHMENT',
      accessorKey: 'DCReplen',
      size: 160,
      enableColumnFilter: true,
      filterVariant: 'multi-select',  
      filterFn: (row: any, columnId: any, filterValue: any) => {
        let value = row?.original?.DCReplen === true ? 'Yes' : row?.original?.DCReplen === false ? 'No' : 'NA';
        if (Array.isArray(filterValue)) {
          if (Array.isArray(filterValue)) {
            if (filterValue.length === 0) {
              return true;
            }
            return filterValue.includes(value);
          }
        }
        return value === filterValue
      },
      filterSelectOptions: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'NA', value: 'NA' },
      ],
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const status = cell?.row?.original?.DCReplen === true ? 'Yes' : cell?.row?.original?.DCReplen === false ? 'No' : 'NA';
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      }
    },
    {
      accessorKey: 'actions',
      header: 'ACTION',
      size: 80,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            <div className="d-flex w-100">
              <Button
                icon={<EditOutlined sx={{ fontSize: 20, marginLeft: "10px", marginRight: "10px" }} />}
                children={undefined}
                onClick={() => editDrawer(cell.row?.original)}
                disabled={!hasPermission("vendorscollectionsmapping_edit")}
              ></Button>
            </div>
          </>
        );
      }
    }
  ]

  return { columns, data, loading };
}
