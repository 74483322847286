import { DatePicker, Divider, Form, Input, Select, Spin } from "antd";
import "./../../styles/vendor.scss";
import GenericGrid from "src/components/common/Grid/GenericGrid";
import { fetchOrderStatus } from "./actions";
import TableFilter from "../common/TableFilter/TableFilter";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button/Button";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import { fetchPicker } from "../framePick/actions";
import orderStatusFilterColumns from "./orderStatusFilterColumns";

function OrderStatus() {
  const { Option } = Select;
  let memorizeDate = dayjs().format("YYYY-MM-DD");
  const [ordernumber, setOrderNumber] = useState<any>("");
  const [username, setUsername] = useState<any>("");
  const [orderdate, setOrderDate] = useState<any>(memorizeDate);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { picker } = useSelector((state: any) => state["FRAME_PICK_STATE_KEY"]);
  const unAssignedPicker = picker?.filter(
    (a: { PickerName: string }) => a.PickerName === "Unassigned"
  );
  const remainingPicker = picker?.filter(
    (a: { PickerId: number; PickerName: string }) =>
      a.PickerName !== "Unassigned" && a.PickerId !== 0
  );
  const pickerArr = remainingPicker?.sort(
    (a: { PickerName: string }, b: { PickerName: string }) => {
      return a.PickerName.localeCompare(b.PickerName);
    }
  );
  const finalPickerArr = [...unAssignedPicker, ...pickerArr];
  const [form] = Form.useForm();
  let filterValues = orderStatusFilterColumns();
  useEffect(() => {
    dispatch(fetchPicker({}));
  }, []);
  useEffect(() => {
    onLoad();
  }, []);
  const handleData = () => {
    setLoader(true);
    if (username || ordernumber || orderdate) {
      onLoad();
    }
    setLoader(false);
  };
  const handleDate = (date: any) => {
    memorizeDate = date && date?.format("YYYY-MM-DD");
    setOrderDate(memorizeDate);
  };
  const onLoad = () => {
    let data: any = {
      offset: 0,
      limit: 10000,
      orderDate: orderdate ? orderdate : "",
    };
    if (username) {
      data = { ...data, pickerid: username };
    }
    if (ordernumber) {
      data = { ...data, ordernumber: ordernumber };
    }
    dispatch(fetchOrderStatus(data));
  };
  const handleReset = () => {
    memorizeDate = dayjs().format("YYYY-MM-DD");
    setOrderDate(memorizeDate);
    setOrderNumber("");
    setUsername("");
    form.resetFields();
    let data: any = {
      offset: 0,
      limit: 10000,
      orderDate: memorizeDate,
    };
    dispatch(fetchOrderStatus(data));
  };

  const handleOrderNumber = (
    oNum: any,
    OrderDate: string,
    UserName: string
  ) => {
    setOrderDate("");
    setUsername("");
    form.setFieldsValue({ [OrderDate]: "", [UserName]: "" });
    setOrderNumber(oNum.target.value);
  };
  return (
    <>
      <Spin tip="Loading..." spinning={loader}>
        <Divider />
        <Breadcrumb>
          <span>View Orders</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end">
            <Form form={form} name="order" onFinish={handleData}>
              <div style={{ display: "flex" }}>
                <Form.Item
                  name="OrderDate"
                  initialValue={orderdate && dayjs(orderdate)}
                >
                  <DatePicker
                    value={orderdate && dayjs(orderdate)}
                    disabledDate={(d: any) => !d || d.isAfter(dayjs())}
                    onChange={handleDate}
                    className="search-field-size"
                    style={{ width: 120 }}
                    placeholder="Order Date"
                    size="middle"
                  />
                </Form.Item>
                <Form.Item name="UserName">
                  <Select
                    placeholder="Select"
                    showSearch
                    value={username}
                    style={{ width: 120, marginLeft: 10 }}
                    optionFilterProp="children"
                    size="middle"
                    onSelect={(val: any) => {
                      setUsername(val);
                    }}
                  >
                    {finalPickerArr?.map(({ PickerId, PickerName }: any) => {
                      return (
                        <Option key={PickerId} value={PickerId}>
                          {PickerName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="OrderNumber"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Order number should be numeric`,
                    },
                  ]}
                >
                  <Input
                    placeholder="Order Number"
                    style={{ marginLeft: 10, width: 120 }}
                    className="search-field-size"
                    value={ordernumber}
                    onChange={(val) =>
                      handleOrderNumber(val, "OrderDate", "UserName")
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="middle"
                    style={{ marginLeft: 20 }}
                  >
                    Get Order Details
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="button"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={handleReset}
                    size="middle"
                  >
                    Reset
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </TableFilter>
        <GenericGrid
          columns={filterValues.columns}
          data={filterValues.data}
          enableRowSelection={false}
          rowSelection={[]}
          columnPinning={{ left: ['OfficeNumber'] }}
          filterDisplayMode="popover"
          highlightFilterMatch={true}
          paginationDisplayMode="pages"
          paginationProps={{
            color: 'primary',
            shape: 'rounded',
            showRowsPerPage: true,
            variant: 'outlined',
            rowsPerPageOptions: [10, 50, 100, 500, 1000]
          }}
          enableStickyHeader={true}
          enableStickyFooter={true}
          enableColumnPinning={true}
          isLoading={loader}
          muiTableBodyCellProps={{
            sx: {
              fontSize: '11px',
              textAlign: 'center',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              padding: '2px 10px'
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: '10px',
              fontWeight: '800',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              textAlign: 'center',
              padding: '2px 10px'
            }
          }}
          muiTableBodyRowProps={{
            sx: {
              "& td": {
                padding: "2px 10px",
              },
              textAlign: 'left',
            },
          }}
        >
        </GenericGrid>
      </Spin>
    </>
  );
}

export default OrderStatus;
