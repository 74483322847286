import { useSelector } from "react-redux";
import { MODULE_KEY } from "./openPull-actions";
import { ACTION } from "../../util/utils";
import { formatDate } from "src/util/date";

export default function filterColumns() {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const collectionName: any | null | undefined = [];
  const vendors: any | null | undefined = [];
  const pickers: any | null | undefined = [];
  const itemStatus: any | null | undefined = [];
  const lab: any | null | undefined = [];
  const labStatus: any | null | undefined = [];
  const locationStatus: any | null | undefined = [];
  const colors: any | null | undefined = [];
  data?.map((row: any) => {
    collectionName.push({ text: row.Collection, value: row.Collection });
    vendors.push({ text: row.Vendor, value: row.Vendor });
    pickers.push({ text: row.NickName, value: row.NickName });
    itemStatus.push({ text: row.ItemStatus, value: row.ItemStatus });
    lab.push({ text: row.LabName, value: row.LabName });
    labStatus.push({ text: row.LabStatus, value: row.LabStatus });
    locationStatus.push({
      text: row.LocationStatus,
      value: row.LocationStatus,
    });
    colors.push({ text: row.ColorRow, value: row.ColorRow });
  });
  let frameCollectionName = collectionName.filter(
    (ele: any, ind: any) =>
      ind === collectionName.findIndex((elem: any) => elem.text === ele.text)
  );
  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind === vendors.findIndex((elem: any) => elem.text === ele.text)
  );
  let pickerName = pickers.filter(
    (ele: any, ind: any) =>
      ind ===
      pickers.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let itemStatusFilter = itemStatus.filter(
    (ele: any, ind: any) =>
      ind ===
      itemStatus.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let locationStatusFilter = locationStatus.filter(
    (ele: any, ind: any) =>
      ind ===
      locationStatus.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let colorsName = colors.filter(
    (ele: any, ind: any) =>
      ind ===
      colors.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );

  const columns = [
    {
      id: "OfficeNum",
      header: "Office #",
      accessorKey: "OfficeNum",
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "PICKER NAME",
      accessorKey: "NickName",
      size: 170,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: pickerName,
    },
    {
      header: "EMPLOYEE",
      accessorKey: "Employee",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: "Collection",
      header: "COLLECTION",
      accessorKey: "Collection",
      size: 170,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: frameCollectionName,
    },
    {
      id: "Collections",
      header: "COLLECTION",
      accessorKey: "Collection",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: "ItemStatus",
      header: "ITEM STATUS",
      accessorKey: "ItemStatus",
      size: 170,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: itemStatusFilter,
    },
    {
      header: "ITEM NAME",
      accessorKey: "ItemName",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "UPC Code",
      accessorKey: "UPCCode",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "VENDOR NAME",
      accessorKey: "Vendor",
      size: 170,
      enableSorting: true,
      enableColumnFilter: true,
      filterVariant: "multi-select",
      filterSelectOptions: vendorName,
    },
    {
      accessorKey: "OrderDate",
      header: "ORDER DATE",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const orderDate = cell.row?.original.OrderDate;
        if (!orderDate || orderDate === "null") {
          return "N/A";
        }
        const formattedOrderDate = formatDate(orderDate);
        return formattedOrderDate;

      },
    },
    {
      accessorKey: "Status",
      header: "STATUS",
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: "ItemsStatus",
      header: "ITEM STATUS",
      accessorKey: "ItemStatus",
      size: 170,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: itemStatusFilter,
    },
    {
      header: "LOCATION STATUS",
      accessorKey: "LocationStatus",
      size: 170,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: locationStatusFilter,
    },
    {
      header: "NOTES",
      accessorKey: "Notes",
      size: 150,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "OFFICE NAME",
      accessorKey: "OfficeName",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "COLOR",
      accessorKey: "ColorRow",
      size: 170,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: "multi-select",
      filterSelectOptions: colorsName,
    },
    {
      header: "ORDER NUMBER",
      accessorKey: "OrderNum",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
  ];

  return { columns, data, loading };
}
