import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import {
  DeleteOutlined,
  DownSquareOutlined,
  EditOutlined,
  FileOutlined,
  SearchOutlined,
  UpSquareOutlined,
} from "@ant-design/icons";
import { Modal, Spin, Tooltip } from "antd";
import { printLabelReport } from "src/components/framePick/sagas";
import { useEffect, useState } from "react";
import PDFStream from "src/components/common/PDFStream/PDFStream";
import { showErrorNotification } from "src/util/notifications";
import useRolePermission from "src/hooks/useRolePermission";
import { formatDate } from "src/util/date";
import ColorTag from "src/components/common/ColorTag/ColorTag";
import OfficeLocations from "src/components/common/OfficeLocations/OfficeLocations";

export default function filterColumns(
  deleteModal: (record: any) => void,
  editDrawer?: ((record: any) => void) | any,
  filteredData?: any,
  archiveModal?: ((record: any) => void) | any,
  setConfirmLoading?: ((arg0: boolean) => void) | any
) {
  const { hasPermission } = useRolePermission();
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const [records, setRecords] = useState(data);
  useEffect(() => {
    setRecords(data);
  }, [data]);
  useEffect(() => {
    if (filteredData?.length && filteredData[0] !== "none") {
      setRecords(filteredData);
    } else {
      setRecords(data);
    }
  }, [filteredData]);
  const vendors: any | null | undefined = [];
  const kitCollection: any | null | undefined = [];
  const [stream, setStream] = useState<any>("");

  records?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    kitCollection.push({ text: row.Collection, value: row.Collection });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const vendorColumns = {
    header: "VENDOR NAME",
    accessorKey: "Vendor",
    enableColumnFilter: true,
    enableSorting: true,
    size: 100,
    filterVariant: 'multi-select',
    filterSelectOptions: vendorName,
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
  };

  const columns = [
    {
      header: "KIT NAME",
      accessorKey: "KitName",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      header: "VENDOR NAME",
      accessorKey: "Vendor",
      enableColumnFilter: true,
      enableSorting: true,
      size: 100,
      filterVariant: 'multi-select',
      filterSelectOptions: vendorName,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
    },
    {
      header: "PO DATE",
      accessorKey: "PODate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        const poDate = cell.row?.original.PODate;
        if (!poDate || poDate === "null") {
          return "N/A";
        }
        const formattedPoDate = formatDate(poDate);
        return formattedPoDate;
      }
    },
    {
      header: "STATUS",
      accessorKey: "StatusText",
      enableColumnFilter: false,
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        const statusText = cell.row?.original.StatusText;
        const status =
          statusText === "Active"
            ? "Active"
            : statusText === "Archived"
              ? "Archived"
              : "Inactive";

        return <ColorTag type={status.toLowerCase()}>{statusText}</ColorTag>;
      }
    },
    {
      header: "OFFICE",
      accessorKey: "OfficeNumbers",
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        return <OfficeLocations
          locationName={cell.row?.original.OfficeNumbers}
          modalTitle="Office Locations"
        />;
      }
    },
    {
      header: "PROCESSED DATE",
      accessorKey: "ProcessedDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      Cell: ({ cell }: { cell: any }) => {
        const processedDate = cell.row?.original.ProcessedDate;
        if (!processedDate || processedDate === "null") {
          return "N/A";
        }
        const formattedProcessedDate = formatDate(processedDate);
        return formattedProcessedDate;
      }
    },
    {
      header: "ACTION",
      accessorKey: "operation",
      // align: "center",
      size: 85,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <div className="d-flex">
            {cell.row?.original.ProcessStatus === "3" ? (
              <>
                <Button
                  icon={<SearchOutlined />}
                  children={undefined}
                  disabled={true}
                  // onClick={() => editDrawer(record)}
                ></Button>
  
                <Tooltip placement="top" title=" Packing Slip">
                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<FileOutlined />}
                    children={undefined}
                    onClick={() => handlePDFFile(cell.row?.original, "slip")}
                  ></Button>
                </Tooltip>
                <Tooltip placement="top" title="Price Bar Codes">
                  <Button
                    style={{ marginLeft: "10px" }}
                    icon={<FileOutlined />}
                    children={undefined}
                    onClick={() => handlePDFFile(cell.row?.original, "barcode")}
                  ></Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Button
                  icon={<EditOutlined />}
                  children={undefined}
                  onClick={() => editDrawer(cell.row?.original)}
                ></Button>
                <Button
                  style={{ marginLeft: "20px" }}
                  disabled={true}
                  icon={<DeleteOutlined />}
                  onClick={() => deleteModal(cell.row?.original)}
                  children={undefined}
                  danger={true}
                ></Button>
              </>
            )}
  
            {cell.row?.original.ProcessStatus === "3" && cell.row?.original.IsArchived && (
              <Tooltip placement="top" title="Unarchive">
                <Button
                  style={{ marginLeft: "20px" }}
                  icon={<UpSquareOutlined />}
                  children={undefined}
                  onClick={() => archiveModal(cell.row?.original)}
                  danger={true}
                ></Button>
              </Tooltip>
            )}
            {cell.row?.original.ProcessStatus === "3" && !cell.row?.original.IsArchived && (
              <Tooltip placement="top" title="Archive">
                <Button
                  style={{ marginLeft: "20px" }}
                  icon={<DownSquareOutlined />}
                  children={undefined}
                  onClick={() => archiveModal(cell.row?.original)}
                  danger={true}
                  disabled={!hasPermission("kitmaintenance_archive")}
                ></Button>
              </Tooltip>
            )}
  
            {stream ? (
              <Modal
                open={true}
                onOk={() => setStream("")}
                onCancel={() => setStream("")}
                footer={null}
                width={1000}
                destroyOnClose
              >
                <PDFStream
                  onPrint={() => setStream("")}
                  stream={stream}
                  fileName={fileName}
                />
              </Modal>
            ) : null}
          </div>
        );
      },
    }
  ];

  const [fileName, setFileName] = useState("");
  const handlePDFFile = async (record: any, type: any) => {
    setConfirmLoading(true);
    const reportName = type === "slip" ? "StockTransferReport" : "PrintTags";
    setFileName(reportName);
    let param = null;
    if (type == "slip") {
      param = { Kitid: record?.KitId };
    } else {
      param = { KitId: record?.KitId };
    }
    const params = type === "slip" ? "StockTransferReport" : "PrintTags";
    try {
      const reportPayload = {
        reportName: reportName,
        parameters: param,
      };
      await printLabelReport(reportPayload).then((result: string) => {
        if (result) {
          setStream(result);
          setConfirmLoading(false);
        }
      });
    } catch (error: any) {
      showErrorNotification(error?.response?.data?.error?.messages[0]);
      setConfirmLoading(false);
    }
  };

  return { columns, data, loading };
}
