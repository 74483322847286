import { useSelector } from "react-redux";
import { ACTION, insertAt } from "../../util/utils";
import { DAILY_REPORT_MODULE_KEY } from "./actions";
import { formatDate } from "src/util/date";

export default function filterColumns() {
    const { data, loading } = useSelector((state: any) => state[`${DAILY_REPORT_MODULE_KEY}${ACTION.STATE_KEY}`]);
    const collectionName: any | null | undefined = [];
    const vendors: any | null | undefined = [];
    const regions: any | null | undefined = [];
    const districts: any | null | undefined = [];
    const upcCodes: any | null | undefined = [];
    const items: any | null | undefined = [];
    const labs: any | null | undefined = [];
    const offices: any | null | undefined = [];
    data?.map((row: any) => {
        collectionName.push({ text: row.FrameCollection, value: row.FrameCollection });
        vendors.push({ text: row.VendorName, value: row.VendorName });
        regions.push({ text: row.Region, value: row.Region });
        districts.push({ text: row.District, value: row.District });
        upcCodes.push({ text: row.UPCCode, value: row.UPCCode });
        items.push({ text: row.ItemName, value: row.ItemName });
        labs.push({ text: row.LabStatus, value: row.LabStatus });
        offices.push({ text: row.OfficeName, value: row.OfficeName });
    });
    let frameCollectionName = collectionName.filter((ele: any, ind: any) => ind === collectionName.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let vendorName = vendors.filter((ele: any, ind: any) => ind === vendors.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let regionName = regions.filter((ele: any, ind: any) => ind === regions.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let DistrictName = districts.filter((ele: any, ind: any) => ind === districts.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let officesName = offices.filter((ele: any, ind: any) => ind === offices.findIndex((elem: any) => elem.text === ele.text && elem.value !== null))
    const columns = [
        {
            header: "ORDER DATE",
            accessorKey: "OrderDate",
            enableColumnFilter: false,
            enableSorting: true,
            Cell: ({ cell }: { cell: any }) => {
                const orderDate = cell.row?.original.OrderDate;
                if (!orderDate || orderDate === "null") {
                    return "N/A";
                }
                const formattedOrderDate = formatDate(orderDate);
                return formattedOrderDate;
            },
            size: 100,
        },
        {
            header: "FRAME SOURCE",
            accessorKey: "FrameSource",
            size: 100,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "LAB NAME",
            accessorKey: "LabName",
            size: 100,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "Region",
            accessorKey: "Region",
            filterVariant: 'multi-select',
            filterSelectOptions: regionName,
            enableColumnFilter: true,
            enableSorting: false,
            size: 100
        },
        {
            header: "District",
            accessorKey: "District",
            filterVariant: 'multi-select',
            filterSelectOptions: DistrictName,
            enableColumnFilter: true,
            enableSorting: false,
            size: 100
        },
        {
            header: "DISTRICT MANAGER",
            accessorKey: "DistrictManager",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "OFFICE NAME",
            accessorKey: "OfficeName",
            filterVariant: 'multi-select',
            filterSelectOptions: officesName,
            enableColumnFilter: true,
            enableSorting: false,
            size: 150
        },
        {
            header: "ORDER NUMBER",
            accessorKey: "OrderNumber",
            enableColumnFilter: true,
            filterFn: 'contains',
            enableSorting: true,
            size: 150,
        },
        {
            header: "VENDOR NAME",
            accessorKey: "VendorName",
            enableColumnFilter: true,
            enableSorting: true,
            filterVariant: 'multi-select',
            filterSelectOptions: vendorName,
            size: 150
        },
        {
            header: "FRAME COLLECTION",
            accessorKey: "FrameCollection",
            enableSorting: true,
            enableColumnFilter: true,
            filterVariant: 'multi-select',
            filterSelectOptions: frameCollectionName,
            size: 160
        },
        {
            header: "UPC CODE",
            accessorKey: "UPCCode",
            filterSearch: true,
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "LAB STATUS",
            accessorKey: "LabStatus",
            size: 150,
            enableColumnFilter: false,
            enableSorting: true,
        },
        {
            header: "LOCATION STATUS",
            accessorKey: "LocationStatus",
            size: 120,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "NOTES",
            accessorKey: "Notes",
            size: 200,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "ITEM STATUS",
            accessorKey: "ItemStatus",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "STATUS",
            accessorKey: "Status",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "REF#",
            accessorKey: "ReferenceNumber",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
    ];

    return { columns, data, loading };
}