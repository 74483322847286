import { useSelector } from "react-redux";

// export function useRolePermission() {
//   const roles = useSelector((state) => state) as any;
//   const hasPermission = (permission: string) => {
//     const roleName = roles?.MenuPermissionData?.role;
//     if (permission === undefined || (permission === null && roleName == null)) {
//       return false;
//     } else {
//       console.log(permission, "permission");
//       return roles?.MenuPermissionData?.permissions[roleName].scopes.includes(
//         permission
//       );
//     }
//   };
//   return {
//     hasPermission,
//   };
// }

interface RolePermissions {
  [roleName: string]: {
    scopes: string[];
  };
}

interface MenuPermissionData {
  role: string | null;
  permissions: RolePermissions;
}

interface RootState {
  MenuPermissionData: MenuPermissionData;
}

export function useRolePermission() {
  const roles = useSelector((state: RootState) => state.MenuPermissionData);

  const hasPermission = (permission: string) => {
    if (!permission) return false;
    const roleName = roles?.role;
    if (!roleName || !roles?.permissions[roleName]) {
      return false;
    }

    return roles.permissions[roleName].scopes.includes(permission);
  };

  return {
    hasPermission,
  };
}

export default useRolePermission;
