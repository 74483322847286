import { MaterialReactTable } from "material-react-table";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import "./GenericGrid.css";

interface GenericGridProps {
  columns: any[];
  data: any;
  enableRowSelection?: boolean;
  onRowSelectionChange?: any;
  rowSelection?: any;
  setRowSelection?: any;
  columnVisibility?: any;
  columnPinning?: any;
  filterDisplayMode?: any;
  highlightFilterMatch?: boolean;
  paginationDisplayMode?: any;
  paginationProps?: any;
  enableStickyHeader?: boolean;
  enableStickyFooter?: boolean;
  enableColumnPinning?: boolean;
  enableColumnOrdering?: boolean;
  isLoading?: boolean;
  muiTableBodyCellProps?: any;
  muiTableHeadCellProps?: any;
  muiTableBodyRowProps?: any;
  enableEditing?: any;
  onSaveChanges?: any;
  enablePagination?: boolean;
}

const GenericGrid: React.FC<GenericGridProps> = ({
  columns,
  data,
  enableRowSelection,
  onRowSelectionChange,
  rowSelection,
  setRowSelection,
  columnVisibility,
  columnPinning,
  filterDisplayMode,
  highlightFilterMatch,
  paginationDisplayMode,
  paginationProps,
  enableStickyHeader,
  enableStickyFooter,
  enableColumnPinning,
  enableColumnOrdering,
  isLoading,
  muiTableBodyCellProps,
  muiTableHeadCellProps,
  muiTableBodyRowProps,
  enableEditing,
  onSaveChanges,
  enablePagination,
}: GenericGridProps) => {
  const [columnFilters, setColumnFilters] = useState<any>([]);
  useEffect(() => {
    if (rowSelection.length !== 0) {
      onRowSelectionChange(rowSelection);
    }
  }, [rowSelection]);

  const [columnVisibile, setColumnVisibility] = useState(columnVisibility);
  const handleColumnVisibilityChange = (updatedColumnVisibility: any) => {
    setColumnVisibility(updatedColumnVisibility);
  };

  const [columnPin, setColumnPinning] = useState(columnPinning);
  const handleColumnPinningChange = (newPinning: any) => {
    console.log("Column pinning changed:", newPinning);
    setColumnPinning(newPinning);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MaterialReactTable
        columns={columns}
        data={data}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        enableRowSelection={enableRowSelection}
        onRowSelectionChange={setRowSelection}
        columnFilterDisplayMode={filterDisplayMode}
        enableFilterMatchHighlighting={highlightFilterMatch}
        onColumnFiltersChange={setColumnFilters}
        globalFilterFn="contains"
        paginationDisplayMode={paginationDisplayMode}
        muiPaginationProps={paginationProps}
        enableStickyHeader={enableStickyHeader}
        enableStickyFooter={enableStickyFooter}
        enableColumnPinning={enableColumnPinning}
        enablePagination={enablePagination}
        onColumnPinningChange={handleColumnPinningChange}
        enableColumnActions={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableRowVirtualization={true}
        enableColumnVirtualization={true}
        enableColumnOrdering={enableColumnOrdering}
        muiCircularProgressProps={{
          color: "secondary",
          thickness: 5,
          size: 55,
        }}
        onEditingRowSave={onSaveChanges}
        enableEditing={enableEditing}
        enableCellActions={true}
        enableRowActions={false}
        getRowId={(row) => row.id}
        editDisplayMode={"cell"}
        muiTableBodyCellProps={muiTableBodyCellProps}
        muiTableHeadCellProps={muiTableHeadCellProps}
        muiTableBodyRowProps={muiTableBodyRowProps}
        state={{
          rowSelection,
          columnFilters,
          isLoading,
          columnVisibility: columnVisibile,
          columnPinning: columnPin,
        }}
        renderBottomToolbarCustomActions={({ table }) => (
          <div className="total-div">
            TOTAL RECORDS{" "}
            <span className="total-count">
              {table.getFilteredRowModel().rows?.length}
            </span>
          </div>
        )}
      ></MaterialReactTable>
    </LocalizationProvider>
  );
};

export default GenericGrid;
