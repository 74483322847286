import { useSelector } from "react-redux";
import { MODULE_KEY } from "./action";
import { ACTION } from "../../util/utils";
import { formatDate } from "src/util/date";

export default function filterColumns() {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const vendors: any | null | undefined = [];
  data?.map((row: any) => {
    vendors.push({ text: row.VendorName, value: row.VendorName });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind === vendors.findIndex((elem: any) => elem.text === ele.text)
  );

  const columns = [
    {
      id: "SKU",
      header: "SKU",
      accessorKey: "SKU",
      size: 100,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "FRAME NAME",
      accessorKey: "FrameName",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "VENDOR NAME",
      accessorKey: "VendorName",
      size: 135,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "COLLECTION",
      accessorKey: "Collection",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "ORDER ID",
      accessorKey: "OrderID",
      size: 110,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "ORDER QTY",
      accessorKey: "OrderQuantity",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "OVERRIDE ORDER QUANTITY",
      accessorKey: "OverrideOrderQuantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "MED COST",
      accessorKey: "UnitCost",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const med = cell.row?.original?.UnitCost;
        return med ? "$" + med : "$0";
      },
    },
    {
      header: "EXT COST",
      accessorKey: "ExtCost",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const med = cell.row?.original?.ExtCost;
        return med ? "$" + med : "$0";
      },
    },
    {
      header: "ONHAND QTY",
      accessorKey: "OnHandQuantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "BACK ORDER QTY",
      accessorKey: "BackOrderQuantity",
      size: 110,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "MIN QTY",
      accessorKey: "MinQuantity",
      size: 90,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "MAX QTY",
      accessorKey: "MaxQuantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "ORDER DATE",
      accessorKey: "OrderDate",
      size: 120,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const OrderDate = cell.row?.original.OrderDate;
        return OrderDate ? formatDate(OrderDate) : "N/A";
      },
    },
    {
      header: "PREV ORDER ID",
      accessorKey: "PreviousOrderId",
      size: 110,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "PREV ORDER DATE",
      accessorKey: "PreviousOrderDate",
      size: 130,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const PreviousOrderDate = cell.row?.original.PreviousOrderDate;
        return PreviousOrderDate ? formatDate(PreviousOrderDate) : "N/A";
      },
    },
    {
      header: "APPROVAL STATUS",
      accessorKey: "ApprovalStatus",
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "CREATED DATE",
      accessorKey: "CreatedDate",
      size: 110,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const CreatedDate = cell.row?.original.CreatedDate;
        return CreatedDate ? formatDate(CreatedDate) : "N/A";
      },
    },
    {
      header: "MODIFIED DATE",
      accessorKey: "ModifiedDate",
      size: 120,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const ModifiedDate = cell.row?.original.ModifiedDate;
        return ModifiedDate ? formatDate(ModifiedDate) : "N/A";
      },
    },
    {
      header: "MODIFIED BY",
      accessorKey: "ModifiedBy",
      size: 110,
      enableColumnFilter: false,
      enableSorting: false,
    },
  ];

  return { columns, data, loading, vendorName };
}
