import { useSelector } from "react-redux";
import { ACTION, insertAt } from "../../util/utils";
import { MODULE_KEY } from "./actions";
import { formatDate } from "src/util/date";

export default function orderStatusFilterColumns() {
    const { data, loading } = useSelector((state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]);
    const columns = [
        {
            header: "#OFFICE",
            accessorKey: "OfficeNumber",
            enableColumnPinning: false,
            enableColumnFilter: false,
            enableSorting: false,
            fixed: "left",
            size: 120,
        },
        {
            header: "ORDER NUMBER",
            accessorKey: "OrderNumber",
            enableColumnFilter: true,
            filterFn: 'contains',
            enableSorting: true,
            size: 150,
        },
        {
            header: "ORDER DATE",
            accessorKey: "OrderDate",
            enableColumnFilter: false,
            enableSorting: true,
            Cell: ({ cell }: { cell: any }) => {
                const orderDate = cell.row?.original.OrderDate;
                if (!orderDate || orderDate === "null") {
                    return "N/A";
                }
                const formattedOrderDate = formatDate(orderDate);
                return formattedOrderDate;
            },
            size: 100,
        },
        {
            header: "ORDER STATUS",
            accessorKey: "OrderStatus",
            size: 250,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "FRAME COLLECTION",
            accessorKey: "FrameCollection",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "LABORATORY NAME",
            accessorKey: "LaboratoryName",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },

        {
            header: "VENDOR NAME",
            accessorKey: "VendorName",
            enableColumnFilter: true,
            filterFn: 'contains',
            enableSorting: true,
            size: 150,
        },
        {
            header: "FRAME NAME",
            accessorKey: "FrameName",
            size: 250,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "UPC CODE",
            accessorKey: "UpcCode",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "PROCESSED BY",
            accessorKey: "PickerFullName",
            size: 120,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "SHIPPING STATUS",
            accessorKey: "ShippingStatus",
            size: 120,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "FRAME STATUS",
            accessorKey: "FrameStatus",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "WORKFLOW STATUS",
            accessorKey: "WorkflowStatus",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "VENDOR ORDER STATUS",
            accessorKey: "VendorOrderStatus",
            size: 150,
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "LABORATORY STATUS",
            accessorKey: "LaboratoryStatus",
            enableColumnFilter: true,
            filterFn: 'contains',
            enableSorting: true,
            size: 150,
        },
    ];
    return { columns, data, loading };
}