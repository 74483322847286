import { Descriptions, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { getKitData } from "./sagas";
import styled from "styled-components";
import { processKitFrameColumns, processKitOfficesColumns } from "./processKitFrameColumns";
import GenericGrid from "src/components/common/Grid/GenericGrid";

function Preview({
  visible,
  setVisibleStatus,
  kitId,
  selectedOffices,
  poDate,
}: any) {
  const [kitData, setKitData] = useState({
    KitName: "",
    Vendor: "",
    Frames: [],
  });
  
  let [productShippingData, setProductShippingData] = useState<any>([]);
  let [totalQuntity, setTotalQuntity] = useState(0);
  const handleClose = () => {
    setVisibleStatus(false);
  };

  useEffect(() => {
    if (visible) {
      getKitData(kitId).then((res: any) => {
        setKitData(res);
      });
    }
  }, [visible]);

  useEffect(() => {
    handleOffice(selectedOffices);
    setTotalQuntity(selectedOffices?.length * kitData?.Frames?.length);
  }, [kitData?.Frames?.length, selectedOffices]);
  
  const handleOffice = (officeList: any) => {
    const location: { LocationNumber: any; Quantity: number }[] = [];
    officeList?.map((ele: any, index: any) => {
      const found = location?.some(
        (e: any) => e.LocationNumber === ele.LocationNumber
      );
      if (found) {
        location[index] = {
          ...ele,
          Quantity: kitData?.Frames?.length,
        };
        setProductShippingData(location);
      } else {
        location?.push({
          LocationNumber: ele?.LocationNumber,
          Quantity: kitData?.Frames?.length,
        });
        setProductShippingData(location);
      }
    });
  };
  
  return (
    <>
      <Modal
        title="Kit Processing Preview"
        open={visible}
        onCancel={handleClose}
        width={1000}
        centered
        className="dc-drawer-panel"
        forceRender={true}
        footer={false}
      >
        {visible && kitData && (
          <>
            <Descriptions column={3}>
              <Descriptions.Item label="PO Date">{poDate}</Descriptions.Item>
              <Descriptions.Item label="Kit Name">
                {kitData?.KitName}
              </Descriptions.Item>
              <Descriptions.Item label="Vendor Name">
                {kitData?.Vendor}
              </Descriptions.Item>
            </Descriptions>
            <GenericGrid
            columns={processKitFrameColumns}
            data={kitData?.Frames}
            enableRowSelection={false}
            rowSelection={[]}
            paginationDisplayMode="pages"
            paginationProps={{
              color: 'primary',
              shape: 'rounded',
              showRowsPerPage: true,
              variant: 'outlined',
              rowsPerPageOptions: [5, 10, 50, 100]
            }}
            filterDisplayMode="popover"
            highlightFilterMatch={true}
            enableStickyHeader={true}
            enableStickyFooter={true}
            enableColumnPinning={true}
            columnPinning={{}}
            muiTableBodyCellProps={{
              sx: {
                fontSize: '11px',
                textAlign: 'center',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontSize: '10px',
                fontWeight: '800',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                textAlign: 'center',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                "& td": {
                  padding: "1px 15px",
                },
                marginLeft: '16px'
              },
            }}>
          </GenericGrid>
            <StyledHeader>
              <b>Product Shipping / Transfer Created</b>
            </StyledHeader>
            <GenericGrid
            columns={processKitOfficesColumns}
            data={productShippingData}
            enableRowSelection={false}
            rowSelection={[]}
            paginationDisplayMode="pages"
            paginationProps={{
              color: 'primary',
              shape: 'rounded',
              showRowsPerPage: true,
              variant: 'outlined',
              rowsPerPageOptions: [5, 10, 50, 100]
            }}
            filterDisplayMode="popover"
            highlightFilterMatch={true}
            enableStickyHeader={true}
            enableStickyFooter={true}
            enableColumnPinning={true}
            columnPinning={{}}
            muiTableBodyCellProps={{
              sx: {
                fontSize: '11px',
                textAlign: 'center',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontSize: '10px',
                fontWeight: '800',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                textAlign: 'center',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                "& td": {
                  padding: "1px 15px",
                },
                marginLeft: '16px'
              },
            }}>
          </GenericGrid>
            <StyledHeader>
              <b>Vendor Order & Receipt Section</b>
            </StyledHeader>
            <b>Total Quantity :</b> {totalQuntity}
            <br />
            <b>Total Locations:</b> {productShippingData?.length}
          </>
        )}
      </Modal>
    </>
  );
}

export default Preview;

const StyledHeader = styled.h6`
  color: #1b61a7;
`;
