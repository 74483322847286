import moment, { Moment } from 'moment';

export const getMomentDate = (date: any) => moment(date);

export const getDateFromMoment = (date: any) => moment(date).format('yyyy-MM-DD');

export const getIsSameOrBefore = (currentDate: any, date: any) => moment(currentDate).isSameOrBefore(date, 'd');

export const getInvalidDates = (date: Moment) => {
  return (
    date < moment('31/12/2000', 'DD/MM/YYYY').endOf('day') || date > moment('31/12/2050', 'DD/MM/YYYY').endOf('day')
  );
};

export const formatDate = (date: string | number | Date | null): string => {
  if (!date) {
    return ''; 
  }
  const d = new Date(date);
  if (isNaN(d.getTime())) {
    return ''; 
  }
  // Format the date as 'yyyy-MM-dd'
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); 
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
