export const DEFAULT_GRID_SIZE = 15;

export const reactApp = () => {
    const { host } = window.location;
    const params = {
        apiUrl: "",
        clientId: "d4731c04-da1c-4861-ac9a-1c4a5fafa631",
        tenantId: "e3ddc75a-a893-4372-a9ae-006db73001df",
        redirectUri: "",
        routerBasename: "/",
        reportsBaseUrl: "",
        reportDomainName: "",
    };
    if (host.includes("-qa")) {
        params.apiUrl = `https://medplatformapiqa.azure-api.net/`;
        params.reportDomainName = "http://vnext-testreports.myeyedr.corp/Reports/report/Distribution/";
    } else if (host.includes("-dev")) {
        http: params.apiUrl = `https://medplatformapidev.azure-api.net/`;
        params.reportDomainName = "http://vm-ssrs-test/Reports/report/Distribution/";
    } else if (host.includes("localhost")) {
        http: params.apiUrl = `https://medplatformapidev.azure-api.net/`; // Use local API URL(ie. http://localhost:7114/api/) to test with local DC APIs
        params.reportDomainName = "http://vm-ssrs-test/Reports/report/Distribution/";
    } else if (host.includes("training")) {
        http: params.apiUrl = `https://medplatformapitraining.azure-api.net/`;
        params.reportDomainName = "http://vnext-testreports.myeyedr.corp/Reports/report/Distribution_UAT/";
    }
    else {
        params.apiUrl = "https://medplatformapiprod.azure-api.net/";
        params.reportDomainName = "http://medreports.myeyedr.corp/Reports/report/Distribution/";
    }

    params.redirectUri = window.location.origin;

    return params;
};

export const PHONE_VALIDATION_RULE = {
    pattern: /^([0-9]{3})-([0-9]{3})-([0-9]{4})$/,
    message: "Write number in format: '111-111-1111",
};
