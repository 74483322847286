import {
  FRAME_PICK_DATA_TYPE,
} from "../../util/columns";
import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION} from "../../util/utils";
import ColorTag from "../common/ColorTag/ColorTag";
import { Checkbox, IconButton } from "@mui/material";
import { formatDate } from "src/util/date";
import "../common/Grid/GenericGrid.css";

interface filterColumns {
  selectedRow: any;
  setSelectedRow: any;
  notFoundRow: any;
  setNotFoundRow: any;
  rowSelection: any;
  setRowSelection: any;
}
export default function filterColumns({
  selectedRow,
  setSelectedRow,
  notFoundRow,
  setNotFoundRow,
  rowSelection,
  setRowSelection
}: filterColumns) {
  const notFoundCheckHandler = (
    selectedRow: any,
    notFoundSelectedRow: any,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRow(
        selectedRow.filter(
          (row: FRAME_PICK_DATA_TYPE) =>
            row.OrderNum != notFoundSelectedRow.OrderNum
        ));

      var rowSelectionIds = Object.keys(rowSelection).filter(id => data[parseInt(id)].OrderNum != notFoundSelectedRow.OrderNum);
      const newRowSelection: any = [];
      rowSelectionIds.forEach((rowSelectionId) => {
        const rowData = rowSelection[rowSelectionId];
        if (rowData) {
          newRowSelection[rowSelectionId] = rowData;
        }
      });

      setRowSelection(newRowSelection);
      setNotFoundRow([...notFoundRow, notFoundSelectedRow]);
    } else {
      setNotFoundRow(
        notFoundRow.filter(
          (row: FRAME_PICK_DATA_TYPE) =>
            row.OrderNum != notFoundSelectedRow.OrderNum
        )
      );
    }
  };

  const { picker, data ,loading} = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const collectionName: any | null | undefined = [];
  const orDate: any | null | undefined = [];
  data?.map((row: any) => {
    collectionName.push({
      text: row.FrameCollection,
      value: row.FrameCollection,
    });
    orDate.push({
      text: formatDate(row.OrderDate),
      value: formatDate(row.OrderDate),
    });
  });
  let frameCollectionName = collectionName.filter(
    (ele: any, ind: any) =>
      ind === collectionName.findIndex((elem: any) => elem.text === ele.text)
  );
  let orderDate = orDate.filter(
    (ele: any, ind: any) =>
      ind === orDate.findIndex((elem: any) => elem.text === ele.text)
  );

  const unAssignedPicker = picker?.filter(
    (a: { PickerName: string }) => a.PickerName === "Unassigned"
  );
  const remainingPicker = picker?.filter(
    (a: { PickerName: string }) => a.PickerName !== "Unassigned"
  );
  const pickerArr = remainingPicker?.sort(
    (a: { PickerName: string }, b: { PickerName: string }) => {
      return a.PickerName.localeCompare(b.PickerName);
    }
  );
  const finalPickerArr = [...unAssignedPicker, ...pickerArr];

  const columns = [
    {
      id: 'OfficeNum',
      header: 'OFFICE #',
      accessorKey: 'OfficeNum',
      size: 100, 
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    },
    {
      header: 'ORDER NUMBER',
      accessorKey: 'OrderNum',
      size: 120, 
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    },
    {
      header: 'ORDER DATE',
      accessorKey: 'OrderDate',
      size: 120, 
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: orderDate,
      Cell: ({ cell }: { cell: any }) => { const cellValue = cell.getValue(); return formatDate(cellValue); },
    },
    {
      header: 'FRAME COLLECTION',
      accessorKey: 'FrameCollection',
      size: 180, 
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: frameCollectionName
    },
    {
      header: 'NOT FOUND',
      accessorKey: 'NotFound',
      size: 80, 
      Cell: ({ cell }: { cell: any }) => (
        <Checkbox
          checked={notFoundRow.some(
            (notFoundSelectedRow: FRAME_PICK_DATA_TYPE) =>
              notFoundSelectedRow.OrderNum == cell.row.original.OrderNum
          )}
          onChange={(e: any) =>
            notFoundCheckHandler(selectedRow, cell.row.original, e.target.checked)
          }
        />
      ),
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'VENDOR NAME',
      accessorKey: 'VendorName',
      size: 140, 
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: 'FRAME NAME',
      accessorKey: 'FrameName',
      size: 280, 
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    }
    ,
    {
      header: 'UPC CODE',
      accessorKey: 'UPCCode',
      size: 120, 
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'ITEM STATUS',
      accessorKey: 'ItemStatus',
      size: 120, 
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'LOCATION STATUS',
      accessorKey: 'LocationStatus',
      size: 110, 
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: 'LABEL PRINTED',
      accessorKey: 'LabelPrinted',
      size: 80, 
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        const status = cell.getValue() ? "Yes" : "No";
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      }
    }
  ]

  return {
    data,
    columns,
    finalPickerArr,
    loading
  };
}
