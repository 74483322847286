import { Divider, Modal, Popconfirm, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import Button from "../common/Button/Button";
import { formatDate } from "../../util/utils";
import handleCancelOrder from "./actionMethods";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import TableFilter from "../common/TableFilter/TableFilter";
import { fetchPendingOrderItems } from "./sagas";
import { showErrorNotification } from "../../util/notifications";
import { getUserName } from "../common/UserDetails";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "../common/Grid/GenericGrid";
import { MRT_RowSelectionState } from "material-react-table";

function PendingOrder() {
  const userName = getUserName();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [pendingOrders, setPendingOrders] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const { hasPermission } = useRolePermission();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    let data: any = {
      UserName: userName,
      offset: 0,
      limit: 5000,
    };
    setLoading(true);
    const pOrders = fetchPendingOrderItems(data);
    pOrders
      .then((res) => {
        setPendingOrders(res);
      })
      .catch((error) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
    setLoading(false);
  };
  const handleCancel = () => {
    setPendingOrders([]);
    handleData();
    setOpen(false);
    setSelectedRow([]);
  };

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection).filter(
      (id) => rowSelection[id]
    );
    const selectedRows = selectedIds.map(
      (index) => pendingOrders[parseInt(index)]
    );
    setSelectedRow(selectedRows);
  }, [rowSelection]);

  const columns = [
    {
      id: "OrderNumber",
      header: "ORDER NUMBER",
      accessorKey: "OrderNumber",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "ORDER DATE",
      accessorKey: "OrderDate",
      size: 100,
      enableColumnFilter: false,
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const orderDate = cell.row?.original.OrderDate;
        return orderDate ? formatDate(orderDate) : "N/A";
      },
    },
    {
      header: "ITEM DESCRIPTION",
      accessorKey: "ItemName",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "UPCCODE",
      accessorKey: "UPCCode",
      size: 100,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "VENDOR NAME",
      accessorKey: "VendorName",
      size: 100,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "ORDERED QTY",
      accessorKey: "Quantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "BACKORDER QTY",
      accessorKey: "BackOrderQuantity",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "PREV RECEIVED QTY",
      accessorKey: "PreviousReceived",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: "PREV ITEM PRICE",
      accessorKey: "PreviousCost",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        const previousCost = cell.row?.original?.PreviousCost;
        return "$" + previousCost;
      },
    },
    {
      header: "ACTIONS",
      accessorKey: "actions",
      enableColumnFilter: false,
      enableSorting: false,
      size: 80,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            {pendingOrders.length >= 1 ? (
              <Popconfirm
                title="Sure to cancel this order?"
                onConfirm={() =>
                  handleCancelOrder(
                    userName,
                    setConfirmLoading,
                    handleCancel,
                    [],
                    cell.row?.original.ID,
                    cell.row?.original.ItemID
                  )
                }
                placement="topRight"
                okText="Yes"
                cancelText="No"
                icon={""}
              >
                <Button
                  icon={<CloseOutlined />}
                  danger={true}
                  children={undefined}
                ></Button>
              </Popconfirm>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Vendor Backorders</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <div>
              {hasPermission("pendingbackorder_cancel") && (
                <Button
                  size="middle"
                  onClick={closeModal}
                  danger={true}
                  style={{ marginRight: "10px" }}
                  disabled={!selectedRow.length}
                  ant-click-animating-without-extra-node="true"
                >
                  Cancel Orders
                </Button>
              )}
            </div>
          </div>
        </TableFilter>
        <GenericGrid
          columns={columns}
          data={pendingOrders}
          enableRowSelection={true}
          onRowSelectionChange={setRowSelection}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          filterDisplayMode="popover"
          highlightFilterMatch={true}
          paginationDisplayMode="pages"
          paginationProps={{
            color: "primary",
            shape: "rounded",
            showRowsPerPage: true,
            variant: "outlined",
            rowsPerPageOptions: [10, 50, 100, 500, 1000],
          }}
          enableStickyHeader={true}
          enableStickyFooter={true}
          enableColumnPinning={true}
          columnPinning={{}}
          isLoading={loading}
          muiTableBodyCellProps={{
            sx: {
              fontSize: "10px",
              textAlign: "left",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: "10px",
              fontWeight: "800",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              textAlign: "left",
              padding: "5px 10px",
            },
          }}
          muiTableBodyRowProps={{
            sx: {
              "& td": {
                padding: "5px 10px",
              },
            },
          }}
        ></GenericGrid>
      </Spin>
      <Modal
        title="Cancel Order Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={open}
        onOk={() =>
          handleCancelOrder(
            userName,
            setConfirmLoading,
            handleCancel,
            selectedRow
          )
        }
        onCancel={handleCancel}
      >
        <span>Are you sure you want to cancel pending back orders?</span>
      </Modal>
    </>
  );
}

export default PendingOrder;
