export const skuColumns = [
    {
        header: 'UPC',
        accessorKey: 'UPC',
        size: 50,
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'MODEL',
        accessorKey: 'Model',
        size: 30,
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'COLOR',
        accessorKey: 'Color',
        size: 30,
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'COLOR DESCRIPTION',
        accessorKey: 'ColorDescription',
        size: 70,
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'EYE SIZE',
        accessorKey: 'EyeSize',
        size: 30,
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'FRAME TYPE',
        accessorKey: 'FrameType',
        size: 50,
        enableColumnFilter: false,
        enableSorting: true,
    },
    {
        header: 'MED COST',
        accessorKey: 'MEDCost',
        size: 50,
        enableColumnFilter: false,
        enableSorting: true,
    },
];