import { Divider, Modal, Spin, Switch } from "antd";
import {
  MODULE_KEY,
  fetchOfficeCollectionMapping,
  importFile,
} from "./actions";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import {
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useRef, useState } from "react";
import filterColumns from "./filterColumns";
import "./../../../styles/vendor.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOPlanogram,
  getFADetail,
  getFrameCollectionForOffice,
} from "./sagas";
import AddOCMapping from "./AddOCMapping";
import { UploadModal } from "src/components/common/Uploads/UploadModal";
import { ACTION } from "src/util/utils";
import { ValidationModal } from "./ValidationModal";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import dayjs from "dayjs";
import { OFFICE_COLLECTION_EXCEL_COLUMNS } from "src/util/columns";
const ExcelJS = require("exceljs");
import { ImportType } from "src/util/utils";
import { useMsal } from "@azure/msal-react";
import CloseOffice from "./closeOffice";
import useRolePermission from "src/hooks/useRolePermission";
import Label from "src/components/common/Label/Label";
import GenericGrid from "src/components/common/Grid/GenericGrid";
import { MRT_RowSelectionState } from "material-react-table";

function OfficeCollectionMapping() {
  const dispatch = useDispatch();
  const { accounts }: any = useMsal();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [closeOfficeVisible, setCloseOfficeVisible] = useState(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [frameCollection, setFrameCollection] = useState();
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const { hasPermission } = useRolePermission();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [bulkEditModal, setBulkEditModal] = useState(false);
  const [sameType, setSameType] = useState<any>([]);
  const [showActive, setShowActive] = useState(true);
  const hasMounted = useRef(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const stateKey = `${MODULE_KEY}${ACTION.STATE_KEY}`;

  let importData = useSelector(
    (state: any) => state[stateKey]
  ).importValidationData;
  const [errorModal, setErrorModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  useEffect(() => {
    if (hasMounted.current) return; 
    hasMounted.current = true;
    dispatch(fetchOfficeCollectionMapping(showActive));
  }, []);
  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };
  const bulkEditModalOpen = () => {
    setVisible(false);
    setBulkEditModal(true);
  };
  const editDrawer = (record: any) => {
    let payload: any = 0;
    let skuData: any = [];
    if (selectedRow?.length > 1) {
      setVisible(false);
      selectedRow?.map((item: any) => {
        selectedRow?.map((ele: any, index: any) => {
          if (
            item.AssortmentName !== ele.AssortmentName ||
            item.CollectionType !== ele.CollectionType
          ) {
            skuData.push({
              Assortment: ele.AssortmentName,
              CollectionType: ele?.CollectionType,
            });
            bulkEditModalOpen();
          }
        });
      });
      const uniqueAssortments = Array.from(
        new Set(skuData.map((item: any) => item.Assortment))
      );
      const uniqueCollectionTypes = Array.from(
        new Set(skuData.map((item: any) => item.CollectionType))
      );
      setSameType({
        Assortment: uniqueAssortments.join(", "),
        CollectionType: uniqueCollectionTypes.join(", "),
      });
      if (skuData.length <= 0) {
        const res: any = {
          FrameArrangerID: selectedRow[0]?.FrameArrangerID,
          CollectionType: selectedRow[0]?.CollectionType,
        };
        setVisible(true);
        setModalData(res);
      }
      const ids = selectedRow.map((row: any) => row.ID);
    } else {
      payload = [record?.ID];
      setVisible(true);
      const res = getFADetail(payload);
      res.then((response: any) => {
        setModalData(response);
      });
    }
    const frames = getFrameCollectionForOffice(payload);
    frames.then((response: any) => {
      setFrameCollection(response);
    });
  };
  const deleteModal = (record: any) => {
    setVisibleDelete(true);
    setDeleteData(record);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedRow([]);
    setCloseOfficeVisible(false);
    dispatch(fetchOfficeCollectionMapping(showActive));
  };

  let filterValues = filterColumns(editDrawer, deleteModal);
  useEffect(() => {
    try {
      if (typeof importData === "string") {
        showSuccessNotification(importData);
        dispatch(fetchOfficeCollectionMapping(showActive));
      } else if (Object.keys(importData)?.length) {
        setErrorModal(true);
        dispatch(fetchOfficeCollectionMapping(showActive));
      }
    } catch (error: any) {
      if (error && error?.response) {
        if (error?.response?.data) {
          showErrorNotification(error.response.data.error.messages[0]);
        } else {
          showErrorNotification(error.response);
        }
      }
    }
  }, [importData]);
  const exportExcelFile = async () => {
    setConfirmLoading(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    //sheet.properties.defaultRowHeight = 25;

    sheet.columns = OFFICE_COLLECTION_EXCEL_COLUMNS;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    try {
      if (data?.length > 0) {
        setOpen(false);
        setConfirmLoading(false);
        let color;
        const promise = Promise.all(
          data?.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              OfficeNum: item.OfficeNum,
              LocationName: item.LocationName,
              Vendor: item.Vendor,
              Collection: item.Collection,
              CollectionType: item.CollectionType,
              FrameArrangerID: item.FrameArrangerID,
              ArrangerQty: item.ArrangerQty,
              UpdatedDate:
                item.UpdatedDate !== null
                  ? dayjs(item.UpdatedDate).format("YYYY-MM-DD")
                  : "",
              UpdateNotes: item.UpdateNotes,
              AssortmentName: item.AssortmentName,
              StopDate:
                item.StopDate !== null
                  ? dayjs(item.StopDate).format("YYYY-MM-DD")
                  : "",
            });
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Office-Collection-Report.csv";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        setOpen(false);
        setConfirmLoading(false);
        showErrorNotification("No data available for the selected date");
      }
    } catch (error: any) {
      setOpen(false);
      setConfirmLoading(false);
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const payload = {
      ID: deleteData,
      CreatedBy: accounts?.[0]?.username,
    };
    deleteOPlanogram(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        handleCancel();
        setConfirmLoading(false);
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
        setConfirmLoading(false);
      });

    setVisibleDelete(false);
  };
  
   useEffect(() => {
      const selectedIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
      const selectedRows = selectedIds.map(index => filterValues.data[parseInt(index)]);
      setSelectedRow(selectedRows);
    }, [rowSelection]);
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Office Planograms</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end gap-2">
          <Label
              label="Show Active"
              className="flex-row align-items-center fs-6 gap-1"
            >
              <Switch
                size="small"
                checked={showActive}
                onChange={(flag) => {
                  setShowActive(flag);
                  dispatch(fetchOfficeCollectionMapping(flag));
                }}
              />
            </Label>
            <Button
              size="middle"
              icon={<DownloadOutlined />}
              ant-click-animating-without-extra-node="true"
              onClick={closeModal}
              disabled={filteredData[0] === "none"}
            >
              {" "}
              Export
            </Button>
            {hasPermission("officecollectionsmapping_import") && (
              <Button
                size="middle"
                icon={<UploadOutlined />}
                ant-click-animating-without-extra-node="true"
                onClick={() => setOpenUpload(true)}
              >
                {" "}
                Import
              </Button>
            )}
            <div>
              {hasPermission("officecollectionsmapping_add") && (
                <Button
                  size="middle"
                  icon={<PlusOutlined />}
                  style={{ marginRight: "10px" }}
                  ant-click-animating-without-extra-node="true"
                  onClick={addDrawer}
                >
                  {" "}
                  Add New
                </Button>
              )}
            </div>
            {hasPermission("officecollectionsmapping_bulkedit") && (
              <Button
                size="middle"
                icon={<EditOutlined />}
                ant-click-animating-without-extra-node="true"
                disabled={selectedRow?.length < 2}
                onClick={editDrawer}
              >
                {" "}
                Bulk Edit
              </Button>
            )}
            {hasPermission("officecollectionsmapping_close") && (
              <Button
                size="middle"
                icon={<CloseOutlined />}
                ant-click-animating-without-extra-node="true"
                onClick={() => setCloseOfficeVisible(true)}
              >
                {" "}
                Close Office
              </Button>
            )}
          </div>
        </TableFilter>
        <GenericGrid
        columns={filterValues.columns}
        data={filterValues.data}
        enableRowSelection={true}
        onRowSelectionChange={setRowSelection}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        filterDisplayMode="popover"
        highlightFilterMatch={true}
        paginationDisplayMode="pages"
        paginationProps={{
          color: 'primary',
          shape: 'rounded',
          showRowsPerPage: true,
          variant: 'outlined',
          rowsPerPageOptions: [10, 50, 100, 500, 1000]
        }}
        enableStickyHeader={true}
        enableStickyFooter={true}
        enableColumnPinning={true}
        enableColumnOrdering={false}
        columnPinning={{}}
        isLoading={filterValues.loading}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '11px',
            textAlign: 'center',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '10px',
            fontWeight: '800',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            textAlign: 'center',
            padding: "5px 10px",
          },
        }}
        muiTableBodyRowProps={{
          sx: {
            "& td": {
              padding: "5px 10px", 
            }
          },
        }}>
        </GenericGrid>
        <AddOCMapping
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
          frameCollection={frameCollection}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
        <CloseOffice
          visibleClose={closeOfficeVisible}
          setVisibleClose={setCloseOfficeVisible}
          userName={accounts?.[0]?.username}
          onLoad={handleCancel}
        />
        <UploadModal
          visible={openUpload}
          onCancel={() => setOpenUpload(false)}
          stateKey={stateKey}
          importFile={importFile}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
        />
        <ValidationModal
          visible={errorModal}
          data={importData}
          onCancel={() => setErrorModal(false)}
          importType={ImportType.OfficeCollectionImport}
        />
        <Modal
          title="Excel export Confirmation"
          okText="Ok"
          open={open}
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          onOk={() => exportExcelFile()}
          onCancel={() => setOpen(false)}
        >
          <span>Are you sure you want to export this data?</span>
        </Modal>
        <Modal
          title="Delete Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={visibleDelete}
          onOk={handleDelete}
          onCancel={() => setVisibleDelete(false)}
        >
          <span>
            Are you sure you wish to delete this office collection mapping?
          </span>
        </Modal>
        <Modal
          title="Bulk Edit Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={bulkEditModal}
          onOk={(e) => setBulkEditModal(false)}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={(e) => setBulkEditModal(false)}
        >
          <span>
            Please ensure all selected rows are for the same Assortment and
            Collection Type.
            <br />
            <p>
              <b>Assortments:</b> {sameType?.Assortment}
              <br />
              <b>Collection Types:</b> {sameType?.CollectionType}
            </p>
          </span>
        </Modal>
      </Spin>
    </>
  );
}
export default OfficeCollectionMapping;
