import {  useEffect, useState } from "react";
import { Modal} from "antd";
import styled from "styled-components";
import "../OfficeLocations/officeLocation.scss";
import { fetchSKUDetails } from "src/components/FrameArranger/FrameArrangerQty/sagas";
import { showErrorNotification } from "src/util/notifications";
import { fetchAssortments } from "src/components/FrameArranger/ProductPlanogram/sagas";
import { LinkOutlined } from "@ant-design/icons";
import GenericGrid from "../Grid/GenericGrid";
import { skuColumns } from "src/components/FrameArranger/FrameArrangerQty/skuColumns";

interface Props {
  param: any;
  modalTitle: any;
}

function Links({ param, modalTitle }: Props) {
  const [open, setOpen] = useState(false);
  const [skuData, setSkuData] = useState<any>();

  useEffect(() => {
      if (open) {
          handleLink();
    }
  }, [open]);
  
  const handleLink = () => {
    let res;
    if (modalTitle === "Assortment(s)") {
      res = fetchAssortments(param);
    } else {
      res = fetchSKUDetails(param);
    }
    res
      ?.then((res: any) => {
        if (res?.length) {
          setSkuData(res);
          setOpen(true);
        } else {
          showErrorNotification(`No assortment found for this UPC (${param})`);
        }
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
  };
  const modelWidth = modalTitle === "Assortment(s)" ? 400 : 800;
  return (
    <>
      {modalTitle === "Assortment(s)" ? (
        <StyledLocation>
          <span onClick={handleLink}>
            <LinkOutlined />
          </span>
        </StyledLocation>
      ) : (
        <StyledLocation>
          <span onClick={handleLink}>SKUs</span>
        </StyledLocation>
      )}

      <Modal
        title={modalTitle}
        okText="Ok"
        centered
        width={modelWidth}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        {" "}
        {modalTitle === "Assortment(s)" ? (
          skuData?.map((item: any) => {
            return <StyledItem>{item}</StyledItem>;
          })
        ) : (
          <GenericGrid
            columns={skuColumns}
            data={skuData}
            enableRowSelection={false}
            rowSelection={[]}
            filterDisplayMode="popover"
            highlightFilterMatch={true}
            enablePagination={false}
            enableStickyHeader={true}
            enableStickyFooter={true}
            enableColumnPinning={true}
            columnPinning={{}}
            muiTableBodyCellProps={{
              sx: {
                fontSize: '11px',
                textAlign: 'center',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontSize: '10px',
                fontWeight: '800',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                textAlign: 'center',
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                "& td": {
                  padding: "1px 15px",
                },
                marginLeft: '16px'
              },
            }}>
          </GenericGrid>
        )}
      </Modal>
    </>
  );
}

const StyledLocation = styled.div`
  color: #40668c;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledItem = styled.div`
  align: "center";
`;

export default Links;
