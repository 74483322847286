import { Divider,  Modal, Spin } from "antd";
import {
  MODULE_KEY,
  fetchBulkCostUpdateSummary,
  importFile,
  cleanImportLayout,
} from "./actions";
import GenericGrid from "src/components/common/Grid/GenericGrid";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import {
  UploadOutlined,
} from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useRef, useState } from "react";
import filterColumns from "./filterColumns";
import "./../../styles/vendor.scss";
import { useDispatch, useSelector } from "react-redux";
 import {
  runBulkCostUpdateValidation,
  finalUpdate,
 } from "./sagas";
import { BulkCostUpdateUploadModal } from "./BulkCostUpdateUploadModal";
import { ACTION } from "src/util/utils";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { useMsal } from "@azure/msal-react";
import styled from "styled-components";
import { usePDF } from "react-to-pdf";
import useRolePermission from "src/hooks/useRolePermission";

function BulkCostUpdate() {
  const dispatch = useDispatch();
  const { accounts }: any = useMsal();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [visibleRunValidation, setVisibleRunValidation] = useState(false);
  const [runValidationForSummaryId, setRunValidationForSummaryId] = useState<string>('');
  const [visibleFinalUpdate, setVisibleFinalUpdate] = useState(false);
  const [finalUpdateForSummaryId, setFinalUpdateForSummaryId] = useState<string>('');
  const { hasPermission } = useRolePermission();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [data, setData] = useState<string | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const hasMounted = useRef(false);
  const stateKey = `${MODULE_KEY}${ACTION.STATE_KEY}`;
  let importData = useSelector(
    (state: any) => state[stateKey]
  ).importValidationData;
  const [errorModal, setErrorModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const { toPDF, targetRef } = usePDF({ filename: "ValidationReport.pdf" });
  useEffect(() => {
    if (hasMounted.current) return; 
    hasMounted.current = true;
    dispatch(fetchBulkCostUpdateSummary({}));
  }, []);
  const runValidationModal = (record: any) => {
    setVisibleRunValidation(true);
    setRunValidationForSummaryId(record);
  };
  const finalUpdateModal = (record: any) => {
    setVisibleFinalUpdate(true);
    setFinalUpdateForSummaryId(record);
    setRunValidationForSummaryId(record);
  }
  const handleCancel = () => {
    setSelectedRow([]);
    dispatch(fetchBulkCostUpdateSummary({}));
  };
  let filterValues = filterColumns(runValidationModal, finalUpdateModal);
  useEffect(() => {
    try {
      if (typeof importData === "string")  {
         dispatch(fetchBulkCostUpdateSummary({}));
         handleCancel();
         setConfirmLoading(false);
         setData(importData);
         setShowPopup(true); // Show popup after fetching data
      } else if (Object.keys(importData)?.length) {
        setErrorModal(true);
        dispatch(fetchBulkCostUpdateSummary({}));
      }
    } catch (error: any) {
      if (error && error?.response) {
        if (error?.response?.data) {
          showErrorNotification(error.response.data.error.messages[0]);
        } else {
          showErrorNotification(error.response);
        }
      }
    }
  }, [importData]);
  const handleApiCall = async (apiFunction: Function, payload: any, onSuccess?: Function) => {
    try {
      setConfirmLoading(true);
      const res = await apiFunction(payload);
      if (onSuccess) onSuccess(res);
    } catch (error: any) {
      showErrorNotification(error?.response?.data?.error?.messages?.[0] || "An error occurred");
    } finally {
      setConfirmLoading(false);
    }
  };
  const runValidation = async (summaryId: string, createdBy: string) => {
    const payload = {
      BulkCostUpdateSummaryId: summaryId,
      CreatedBy: createdBy,
    };
    await handleApiCall(runBulkCostUpdateValidation, payload, (res: any) => {
      setData(res?.ValidationResult);
      setShowPopup(true); // Show popup after fetching data
    });
    setVisibleRunValidation(false);
  };
  const handleRunValidation = async () => {
    if (!runValidationForSummaryId || !accounts?.[0]?.username) return;
    await runValidation(runValidationForSummaryId, accounts[0].username);
  };
  const handleValidationResultClose = () => {
      dispatch(cleanImportLayout());
      setShowPopup(false);
  };
  const handleFinalUpdate = async () => {
    if (!finalUpdateForSummaryId || !accounts?.[0]?.username) return;
  
    const payload = {
      BulkCostUpdateSummaryId: finalUpdateForSummaryId,
      FinalUpdateBy: accounts[0].username,
    };
    
    await handleApiCall(finalUpdate, payload, async () => {
      await  dispatch(fetchBulkCostUpdateSummary({}));
      await runValidation(runValidationForSummaryId, accounts[0].username);
    });
    setVisibleFinalUpdate(false);
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Bulk Cost Update</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end gap-2">
            {hasPermission("officecollectionsmapping_import") && (
              <Button
                size="middle"
                icon={<UploadOutlined />}
                ant-click-animating-without-extra-node="true"
                onClick={() => setOpenUpload(true)}
              >
                {" "}
                Import
              </Button>
            )}
          </div>
        </TableFilter>
        <GenericGrid
          columns={filterValues.columns}
          data={filterValues.data}
          enableRowSelection={false}
          rowSelection={[]}
          columnPinning={{}}
          filterDisplayMode="popover"
          highlightFilterMatch={true}
          paginationDisplayMode="pages"
          paginationProps={{
            color: 'primary',
            shape: 'rounded',
            showRowsPerPage: true,
            variant: 'outlined',
            rowsPerPageOptions: [10, 50, 100, 500, 1000]
          }}
          enableStickyHeader={true}
          enableStickyFooter={true}
          enableColumnPinning={true}
          isLoading={filterValues.loading}
          muiTableBodyCellProps={{
            sx: {
              fontSize: '11px',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              padding: '2px 10px'
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: '10px',
              fontWeight: '800',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
              padding: '2px 10px'
            }
          }}
          muiTableBodyRowProps={{
            sx: {
              "& td": {
                padding: "2px 10px",
              },
            },
          }}
        >
        </GenericGrid>
        <BulkCostUpdateUploadModal
          visible={openUpload}
          onCancel={() => setOpenUpload(false)}
          stateKey={stateKey}
          importFile={importFile}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
        />
        <Modal
          title="Validation Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={visibleRunValidation}
          onOk={handleRunValidation}
          onCancel={() => setVisibleRunValidation(false)}
        >
          <span>
            Are you sure you wish to run validation?
          </span>
        </Modal>
        <Modal
          title="Final Update Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={visibleFinalUpdate}
          onOk={handleFinalUpdate}
          onCancel={() => setVisibleFinalUpdate(false)}
        >
          <span>
            Are you sure you wish to run final update?
          </span>
        </Modal>
        <Modal 
          title="Validation Result"
          closable={false} 
          okText="Ok"
          onOk={handleValidationResultClose}
          width={1000}
          centered
          confirmLoading={confirmLoading}
          style={{  top: 20}}
          footer={
            <div className="d-flex justify-content-end mb-2">
                <Button
                  type="primary"
                  className="ghost-button"
                  onClick={() => {
                    toPDF();
                  }}
                 >
                  PDF
                </Button>
              <Button
                type="primary"
                className="ghost-button"
                style={{ marginLeft: "5px" }}
                onClick={handleValidationResultClose}
              >
                Ok
              </Button>
            </div>
          }
          open={showPopup} 
          onCancel={handleValidationResultClose}>
          <VRContainer ref={targetRef} className="pdf-content"
              style={{ padding: 20}}>
            <div dangerouslySetInnerHTML={{ __html: data as string }} />
          </VRContainer>
        </Modal>
      </Spin>
    </>
  );
}

export default BulkCostUpdate;

const VRContainer = styled.div`
table { width: 100%; border-collapse: collapse; }
th, td { border: 1px solid black; padding: 1px; text-align: center; }
th { background-color: #f2f2f2; }
`;