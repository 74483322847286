import * as Eff from "redux-saga/effects";
import { VENDORS_FETCH } from "../components/vendor/vendor-actions";
import { fetchVendor } from "../components/vendor/vendor-sagas";
import {
  FETCH_OFFICE_LOCATION,
  FETCH_PRINT_TAGS,
} from "../components/printTags/printTags-actions";
import {
  fetchOfficeLocationList,
  fetchPrintTags,
} from "../components/printTags/printTags-sagas";
import { OPEN_PULL_FETCH } from "../components/openPulls/openPull-actions";
import { fetchOpenPull } from "../components/openPulls/openPull-sagas";
import {
  FRAME_PICK_FETCH,
  PICKER_FETCH,
} from "../components/framePick/actions";
import { fetchFramePick, fetchPick } from "../components/framePick/sagas";
import { fetchReplenishment } from "../components/Replenishment/saga";
import { DC_REPLENISHMENT_FETCH } from "../components/Replenishment/action";
import {
  PENDING_ORDER_FETCH,
  VENDOR_ORDER_FETCH,
} from "../components/vendorOrders/actions";
import {
  fetchPendingOrders,
  fetchVendorOrder,
} from "../components/vendorOrders/sagas";
import {
  fetchOrderStatus,
  fetchDailyReport,
  fetchVendorExclusion,
  fetchDailyExcelReport,
} from "../components/orderStatus/sagas";
import {
  DAILY_REPORT_FETCH,
  ORDER_STATUS_FETCH,
  VENDOR_EXLUSION_FETCH,
} from "../components/orderStatus/actions";
import { ACCESSORY_REPLENISHMENT_FETCH } from "../components/systemPreference/actions";
import { fetchAccessoryReplenishment } from "../components/systemPreference/sagas";
import {
  KITS_MAINTENANCE_FETCH,
  KIT_FRAME_FETCH,
} from "src/components/kits/kitMaintenance/actions";
import {
  fetchKitFrame,
  fetchKitsMaintenance,
} from "src/components/kits/kitMaintenance/sagas";
import {
  ASSORTMENT_IMPORT_FILE,
  ASSORTMENT_SKU_FETCH,
  FRAME_ARRANGER_QTY_FETCH,
  IMPORT_SKU_FILE,
} from "src/components/FrameArranger/FrameArrangerQty/actions";
import {
  fetchAssortmentSku,
  fetchFrameArrangerQty,
  validateSKUFile,
  importFileAssortment
} from "src/components/FrameArranger/FrameArrangerQty/sagas";
import { VENDOR_COLLECTION_MAPPING_FETCH } from "src/components/FrameArranger/VendorsAndCollections/actions";
import { fetchVendorCollectionMapping } from "src/components/FrameArranger/VendorsAndCollections/sagas";
import {
  PRODUCT_PLANOGRAM_IMPORT_FILE,
  PRODUCT_PLANOGRAM_FETCH,
} from "src/components/FrameArranger/ProductPlanogram/actions";
import {
  fetchProductPlanogramMapping,
  importFileProductPlanogram,
} from "src/components/FrameArranger/ProductPlanogram/sagas";
import {
  IMPORT_FILE,
  OFFICE_COLLECTION_MAPPING_FETCH,
  UPDATE_VALIDATED_IMPORT,
} from "src/components/FrameArranger/OfficeCollection/actions";
import {
  fetchOfficeCollectionMapping,
  importBulkUpdateFile,
} from "src/components/FrameArranger/OfficeCollection/sagas";
import { VALIDATE_ACUITY_IMPORT_FILE } from "src/components/FrameArranger/ValidateAcuityFrameImport/actions";
import { validateActuityFrameImport } from "src/components/FrameArranger/ValidateAcuityFrameImport/sagas";
import {
  AVAILABLE_OFFICE_FETCH,
  KITS_PROCESSING_FETCH,
  SELECTED_OFFICE_FETCH,
} from "src/components/kits/KitProcessing/actions";
import {
  fetchAvailableOffices,
  fetchKitProcessing,
  fetchSelectedOffices,
} from "src/components/kits/KitProcessing/sagas";
import { FEDEX_FETCH } from "src/components/FedEx/Fedex/actions";
import { fetchFedEx } from "src/components/FedEx/Fedex/sagas";
import { EXPRESS_OFFICES_FETCH } from "src/components/FedEx/ExpressOffices/actions";
import { fetchExpressOffice } from "src/components/FedEx/ExpressOffices/sagas";
import {
  fetchBulkCostUpdateSummary,
  importBulkCostUpdateFile,
} from "src/components/bulkCostUpdate/sagas";
import { BULK_COST_UPDATE_FETCH, BCU_IMPORT_FILE } from "src/components/bulkCostUpdate/actions";
const takeEvery: any = Eff.takeEvery;

export default function* rootSaga() {
  yield takeEvery(VENDORS_FETCH, fetchVendor);
  yield takeEvery(FETCH_OFFICE_LOCATION, fetchOfficeLocationList);
  yield takeEvery(FETCH_PRINT_TAGS, fetchPrintTags);
  yield takeEvery(OPEN_PULL_FETCH, fetchOpenPull);
  yield takeEvery(FRAME_PICK_FETCH, fetchFramePick);
  yield takeEvery(PICKER_FETCH, fetchPick);
  yield takeEvery(DC_REPLENISHMENT_FETCH, fetchReplenishment);
  yield takeEvery(VENDOR_ORDER_FETCH, fetchVendorOrder);
  yield takeEvery(ORDER_STATUS_FETCH, fetchOrderStatus);
  yield takeEvery(DAILY_REPORT_FETCH, fetchDailyReport);
  yield takeEvery(PENDING_ORDER_FETCH, fetchPendingOrders);
  yield takeEvery(VENDOR_EXLUSION_FETCH, fetchVendorExclusion);
  yield takeEvery(ACCESSORY_REPLENISHMENT_FETCH, fetchAccessoryReplenishment);
  yield takeEvery(KITS_MAINTENANCE_FETCH, fetchKitsMaintenance);
  yield takeEvery(KIT_FRAME_FETCH, fetchKitFrame);
  yield takeEvery(FRAME_ARRANGER_QTY_FETCH, fetchFrameArrangerQty);
  yield takeEvery(IMPORT_SKU_FILE, validateSKUFile);
  yield takeEvery(
    VENDOR_COLLECTION_MAPPING_FETCH,
    fetchVendorCollectionMapping
  );
  yield takeEvery(PRODUCT_PLANOGRAM_FETCH, fetchProductPlanogramMapping);
  yield takeEvery(
    OFFICE_COLLECTION_MAPPING_FETCH,
    fetchOfficeCollectionMapping
  );
  yield takeEvery(IMPORT_FILE, importBulkUpdateFile);
  //yield takeEvery(UPDATE_VALIDATED_IMPORT, updateValidatedImportStartWorker);
  yield takeEvery(PRODUCT_PLANOGRAM_IMPORT_FILE, importFileProductPlanogram);
  yield takeEvery(VALIDATE_ACUITY_IMPORT_FILE, validateActuityFrameImport);
  yield takeEvery(KITS_PROCESSING_FETCH, fetchKitProcessing);
  yield takeEvery(AVAILABLE_OFFICE_FETCH, fetchAvailableOffices);
  yield takeEvery(SELECTED_OFFICE_FETCH, fetchSelectedOffices);
  yield takeEvery(FEDEX_FETCH, fetchFedEx);
  yield takeEvery(ASSORTMENT_SKU_FETCH, fetchAssortmentSku);
  yield takeEvery(EXPRESS_OFFICES_FETCH, fetchExpressOffice);
  yield takeEvery(ASSORTMENT_IMPORT_FILE, importFileAssortment);
  yield takeEvery(BULK_COST_UPDATE_FETCH, fetchBulkCostUpdateSummary);
  yield takeEvery(BCU_IMPORT_FILE, importBulkCostUpdateFile);
}
