import { call, put, select } from 'redux-saga/effects';
import { fetchOfficeLocation, fetchOfficeLocationSuccess, fetchOfficeLocationFailed, fetchPrintTagsSuccess, fetchPrintTagsFailed } from './printTags-actions';
import { get, omit } from 'lodash';
import { default as api } from '../../util/api';
import { showErrorNotification } from '../../util/notifications'
import { PRINT_TAGS_URL } from '../../util/constants/api.constants';


export function* fetchOfficeLocationList({ payload }: any): Generator {
  try {
    let url = PRINT_TAGS_URL.ACTIVEOFFICES;
    const { page = 0 } = payload;
    const data: any = yield call(api.get, url);
    yield put(fetchOfficeLocationSuccess(data));
  } catch (e) {
    showErrorNotification(null, e);
  }
}

export function* fetchPrintTags({ payload }: any): Generator {
  try {
    const { active } = payload;
    let url = `${PRINT_TAGS_URL.REQUEST_TAG}?IsActiveOnly=${active}`;
    const data: any = yield call(api.get, url);
    yield put(fetchPrintTagsSuccess(data));
  } catch (e:any) {
    yield put(fetchPrintTagsFailed({ message: e.message }));
    showErrorNotification(null, e);
  }
}

export function createPrintTag(payload: any) {
  let url = PRINT_TAGS_URL.REQUEST_TAG;
  return api.post( url, payload);
}

export function archiveTag(payload: any) {
  let url = PRINT_TAGS_URL.ARCHIVE_TAG;
  return api.post( url, payload);
}

export function updateTag(payload: any) {
  let url = PRINT_TAGS_URL.UPDATE_TAG;
  return api.post( url, payload);
}