import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import ColorTag from "src/components/common/ColorTag/ColorTag";
import useRolePermission from "src/hooks/useRolePermission";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((arg0: any) => void) | any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const { hasPermission } = useRolePermission();

  const columns = [
    {
      id: "ItemName",
      header: "PRODUCT",
      accessorKey: "ItemName",
      size: 110,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "UPCCODE",
      accessorKey: "SKU",
      size: 110,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "REPLENISHMENT TYPE",
      accessorKey: "ReplenishmentType",
      size: 170,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "TRIGGER QUANTITY",
      accessorKey: "ReplenishThreshold",
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    },
    {
      header: "ORDER QUANTITY",
      accessorKey: "OrderQuantity",
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    },
    {
      header: "ON HAND QUANTITY",
      accessorKey: "CurrentOnHandQuantity",
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "IN TRANSIT",
      accessorKey: "InTransitQuantity",
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "STOCKED BY DC",
      accessorKey: "IsStockedAtDC",
      size: 140,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        const status = cell.getValue() ? "Yes" : "No";
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      },
    },
    {
      header: "OFFICE",
      accessorKey: "LocationID",
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: "CONSIDER BACKORDER",
      accessorKey: "IsConsiderBackorder",
      size: 175,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        const status = cell.getValue() ? "Yes" : "No";
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      },
    },
    {
      header: "CURRENT SALES VOLUME",
      accessorKey: "SalesAfterLastReplenishDate",
      size: 130,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: "LAST REPLENISHMENT DATE",
      accessorKey: "LastReplenishmentDate",
      size: 160,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      accessorKey: "actions",
      header: "ACTIONS",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <div className="d-flex w-100">
            <div>
              {hasPermission("replenishmentpreferences_edit") && (
                <Button
                  icon={<EditOutlined />}
                  children={undefined}
                  onClick={() => editDrawer(cell.row?.original)}
                ></Button>
              )}
            </div>
            <div>
              {hasPermission("replenishmentpreferences_delete") && (
                <Button
                  style={{ marginLeft: "20px" }}
                  icon={<DeleteOutlined />}
                  onClick={() => deleteModal(cell.row?.original)}
                  children={undefined}
                  danger={true}
                ></Button>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return { columns, data, loading };
}
