import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { DragOutlined} from "@ant-design/icons";
import { useMemo } from "react";
import Images from "src/components/common/Images/Images";
import Links from "src/components/common/Links/Links";
import { Tooltip } from "antd";
import useRolePermission from "src/hooks/useRolePermission";
import { formatDate } from "src/util/date";
import { DeleteOutlined, EditOutlined, Visibility } from "@mui/icons-material";
import { positions } from "@mui/system";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((record: any) => void) | any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const { hasPermission } = useRolePermission();
  // Memoize filtered data
  const filteredData = useMemo(() => {
    // Helper function to filter and map data
    const filterAndMap = (sourceData: any[], key: string) => {
      const uniqueValues = new Set(
        sourceData
          .map((row: { [x: string]: any }) => row[key])
          .filter((value: string) => value && value !== "")
      );
      return Array.from(uniqueValues)
        .map((value) => ({
          text: value,
          value,
        }))
        ?.sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        });
    };

    return {
      vendors: filterAndMap(data, "Vendor"),
      collections: filterAndMap(data, "Collection"),
      materials: filterAndMap(data, "Material"),
      frameTypes: filterAndMap(data, "FrameType"),
      genders: filterAndMap(data, "Gender"),
      itemGroups: filterAndMap(data, "ItemGroup"),
      lifeCycleStatuses: filterAndMap(data, "LifeCycleStatus"),
      skuGrades: filterAndMap(data, "SKUGrade"),
      colorFamily: filterAndMap(data, "FrameColorFamily"),
      frameShape: filterAndMap(data, "FrameShape"),
      ageGroup: filterAndMap(data, "AgeGroup"),
    };
  }, [data?.length]);
 
    const columns = [
    {
      id: 'UPC',
      header: 'UPC',
      accessorKey: 'UPC',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true
    },
    {
      header: 'VENDOR',
      accessorKey: 'Vendor',
      size: 140,
      enableColumnFilter: true,
      enableSorting: true,
      enableHiding : true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.vendors
    },
    {
      header: 'COLLECTION',
      accessorKey: 'Collection',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      enableHiding : true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.collections
    },
    {
      header: 'FRAME NAME',
      accessorKey: 'FrameName',
      size: 180,
      enableSorting: true,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableHiding : true
    },
    {
      header: 'MODEL',
      accessorKey: 'Model',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true, 
      enableHiding : true

    },
    {
      header: 'STYLE NAME',
      accessorKey: 'StyleName',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true

    },
    {
      header: 'COLOR NUMBER',
      accessorKey: 'ColorNumber',
      size: 170,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true
    },
    {
      header: 'COLOR DESCRIPTION',
      accessorKey: 'ColorDescription',
      size: 200,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true
    },
    {
      header: 'EYE SIZE',
      accessorKey: 'EyeSize',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true,
    },
    {
      header: 'EDGE',
      accessorKey: 'Edge',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true,
    },
    {
      header: 'FRAME TYPE',
      accessorKey: 'FrameType',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      enableHiding : true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.frameTypes
    },
    {
      header: 'GENDER',
      accessorKey: 'Gender',
      size: 130,
      enableColumnFilter: true,
      enableSorting: true,
      enableHiding : true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.genders
    },
    {
      header: 'MATERIAL',
      accessorKey: 'Material',
      size: 140,
      enableColumnFilter: true,
      enableSorting: true,
      enableHiding : true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.materials
    },
    {
      header: 'NO REPLENISH DATE',
      accessorKey: 'NoReplenishmentDate',
      size: 200,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true,
      Cell: ({ cell }: { cell: any }) => {
        const noReplenishmentDate = cell.row?.original.NoReplenishmentDate;
        if (!noReplenishmentDate || noReplenishmentDate === "null") {
          return "N/A";
        }
        const formattedNoReplenishmentDate = formatDate(noReplenishmentDate);
        return formattedNoReplenishmentDate;

      }
    },
    {
      header: 'ITEM GROUP',
      accessorKey: 'ItemGroup',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      enableHiding : true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.itemGroups
    },
    {
      header: 'EFFECTIVE DATE',
      accessorKey: 'EffectiveDate',
      size: 160,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true,
      Cell: ({ cell }: { cell: any }) => {
        const effectiveDate = cell.row?.original.EffectiveDate;
        if (!effectiveDate || effectiveDate === "null") {
          return "N/A";
        }
        const formattedEffectiveDate = formatDate(effectiveDate);
        return formattedEffectiveDate;

      }
    },
    {
      header: 'RANK',
      accessorKey: 'Rank',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      enableHiding : true
    },
    {
      accessorKey: 'actions',
      header: 'ACTIONS',
      size: 160,
      enableColumnFilter: false,
      enableSorting: false,
      enablePinning: true,
      pin: 'right',
      enableHiding : true,
      enableColumnDragging: false,
      enableColumnOrdering: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            <div className="d-flex w-100">
              <Tooltip placement="top" title="Edit">
                <Button
                  icon={<EditOutlined sx={{ fontSize: 20 ,marginLeft: "10px", marginRight: "10px"}}/>}
                  children={undefined}
                  onClick={() => editDrawer(cell?.row?.original?.ID1)}
                  disabled={!hasPermission("productplanogram_edit")}
                ></Button>
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<DeleteOutlined sx={{ fontSize: 20 ,marginLeft: "10px", marginRight: "10px"}}/>}
                  onClick={() => deleteModal(cell?.row?.original)}
                  children={undefined}
                  danger={true}
                  disabled={!hasPermission("productplanogram_delete")}
                ></Button>
              </Tooltip>
              <span style={{ marginLeft: '10px' , marginRight:'10px'}}>
                {cell?.row?.original?.AssortmentNames != null && (
                  <Tooltip placement="top" title="Assortments">
                    {" "}
                    <Button>
                      <Links param={cell?.row?.original?.UPC} modalTitle={"Assortment(s)"} />
                    </Button>
                  </Tooltip>
                )}
              </span>
              <span style={{ marginRight: '10px' }}>
                {" "}
                <Images param={cell?.row?.original?.ImageURLs} modalTitle={"Product Image(s)"} />
              </span>
            </div>
          </>
        );
      }
    },
    {
      header: 'PRODUCT PLANOGRAM ID',
      accessorKey: 'ID1',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
      enableHiding : true,
     
    },
    {
      header: 'SKU QTY',
      accessorKey: 'SKUQty',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false,
    },
    {

      header: 'BRIDGE',
      accessorKey: 'Bridge',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'TEMPLE',
      accessorKey: 'Temple',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'A',
      accessorKey: 'A',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'B',
      accessorKey: 'B',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'ED',
      accessorKey: 'ED',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },

    {
      header: 'MED COST',
      accessorKey: 'MEDCost',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'WHOLESALE',
      accessorKey: 'Wholesale',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'RETAIL',
      accessorKey: 'Retail',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'RECALL DATE',
      accessorKey: 'RecallDate',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'PHASING IN STYLE',
      accessorKey: 'PhasingInStyle',
      size: 160,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'MIN',
      accessorKey: 'Min',
      size: 110,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'MAX',
      accessorKey: 'Max',
      size: 110,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'STOCKED AT DC',
      accessorKey: 'StockedAtDC',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'LIVE COST',
      accessorKey: 'LiveCost',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'LIVE WHOLESALE',
      accessorKey: 'LiveWholeSale',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'LIVE RETAIL',
      accessorKey: 'LiveRetail',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'RECEIPT DATE',
      accessorKey: 'ReceiptDate',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const receiptDate = cell.row?.original.ReceiptDate;
        if (!receiptDate || receiptDate === "null") {
          return "N/A";
        }
        const formattedReceiptDate = formatDate(receiptDate);
        return formattedReceiptDate;

      }
    },
    {
      header: 'FRAME COLOR FAMILY',
      accessorKey: 'FrameColorFamily',
      size: 190,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.colorFamily
    },
    {
      header: 'FRAME SHAPE',
      accessorKey: 'FrameShape',
      size: 160,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.frameShape
    },
    {
      header: 'AGE GROUP',
      accessorKey: 'AgeGroup',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.ageGroup
    },
    {
      header: 'ITEM TYPE',
      accessorKey: 'ItemType',
      size: 120,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: false
    },
    {
      header: 'LIFE CYCLE STATUS DATE',
      accessorKey: 'LifeCycleStatusDate',
      size: 120,
      enableColumnFilter: false,
      enableSorting: false
    },
    {
      header: 'LIFE CYCLE STATUS',
      accessorKey: 'LifeCycleStatus',
      size: 180,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.lifeCycleStatuses
    },
    {
      header: 'SKU GRADE',
      accessorKey: 'SKUGrade',
      size: 140,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: filteredData.skuGrades
    }
  ]

   return { columns, data, loading };
}
