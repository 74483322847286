import { call, put } from "redux-saga/effects";
import { fetchVendorCollectionMappingSuccess } from "./actions";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import {
  FRAME_ARRANGER_QTY_URL,
  VENDOR_COLLECTION_URL,
} from "src/util/constants/api.constants";

export function* fetchVendorCollectionMapping({ payload }: any): Generator {
  try {
    
    let url = FRAME_ARRANGER_QTY_URL.DATABASE_COLLECTION_LIST;
    const data: any = yield call(api.get, url, payload);
    yield put(fetchVendorCollectionMappingSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function saveVendorCollectionMapping(payload: any) {
  let url = `${VENDOR_COLLECTION_URL.VENDOR_COLLECTION_SAVE}`;
  return api.post(url, payload);
}

export function updateVendorCollectionMapping(payload: any) {
  let url = `${VENDOR_COLLECTION_URL.VENDOR_COLLECTION_SAVE}`;
  return api.put(url, payload);
}

export function getCollection() {
  let url = `${VENDOR_COLLECTION_URL.COLLECTION_LIST}`;
  return api.get(url);
}

export function getCollectionType() {
  let url = `${VENDOR_COLLECTION_URL.COLLECTION_TYPE_LIST}`;
  return api.get(url);
}

export function getFADetail(payload: any) {
  let url = VENDOR_COLLECTION_URL.EDIT_VC + payload;
  return api.get(url);
}
