import { Card, Descriptions, Divider, Spin } from "antd";
import GenericGrid from "src/components/common/Grid/GenericGrid";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import filterColumns from "./filterColumns";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { DATE_FORMAT } from "src/util/columns";

function KitDetailPage() {
  const history = useNavigate();
  const location = useLocation();
  const kitData: any = location.state;
  let filterValues = filterColumns();
  let columns = filterValues?.frameColumns?.filter(
    (item: any) => item.header !== "ACTION"
  );

  const handleCancel = () => {
    history("/kitMaintenance");
  };

  return (
    <>
      <Spin spinning={false}>
        <Divider />
        <Breadcrumb>
          <span onClick={() => history("/kitMaintenance")}>
            Kit Maintenance
          </span>
          <span>Kit Maintenance Details Pages</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              onClick={handleCancel}
              ant-click-animating-without-extra-node="true"
              danger
            >
              Close
            </Button>
          </div>
        </TableFilter>
        <Card>
          <Descriptions column={3} className="mb-5">
            <Descriptions.Item label="Kit ID">
              {kitData.KitId}
            </Descriptions.Item>
            <Descriptions.Item label="Kit Name">
              {kitData.KitName}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor">
              {kitData.Vendor}
            </Descriptions.Item>
            <Descriptions.Item label="Date">
              {kitData.ModifiedDate
                ? dayjs(kitData.ModifiedDate).format(DATE_FORMAT)
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Planned Ship Date">
              {kitData.PlannedShipDate
                ? dayjs(kitData.PlannedShipDate).format(DATE_FORMAT)
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {kitData?.StatusText}
            </Descriptions.Item>
            <Descriptions.Item label="Kit Processed Status">
              {kitData.ProcessStatus &&
                (kitData.ProcessStatus === 3 || kitData.ProcessStatus === 4)
                ? "Yes"
                : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Collection">
              {kitData.Collection}
            </Descriptions.Item>
          </Descriptions>
          <GenericGrid
            columns={columns}
            data={kitData?.Frames || []}
            enableRowSelection={false}
            rowSelection={[]}
            columnPinning={{}}
            filterDisplayMode="popover"
            highlightFilterMatch={true}
            paginationDisplayMode="pages"
            paginationProps={{
              color: 'primary',
              shape: 'rounded',
              showRowsPerPage: true,
              variant: 'outlined',
              rowsPerPageOptions: [10, 50, 100, 500, 1000]
            }}
            enableStickyHeader={true}
            enableStickyFooter={true}
            enableColumnPinning={true}
            isLoading={filterValues.loading}
            muiTableBodyCellProps={{
              sx: {
                fontSize: '11px',
                textAlign: 'center',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                padding: '2px 10px'
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                fontSize: '10px',
                fontWeight: '800',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
                textAlign: 'center',
                padding: '2px 10px'
              }
            }}
            muiTableBodyRowProps={{
              sx: {
                "& td": {
                  padding: "2px 10px",
                },
                textAlign: 'left',
              },
            }}
          >
          </GenericGrid>
        </Card>
      </Spin>
    </>
  );
}
export default KitDetailPage;
