import { reactApp } from "./common.constants";

export const BASE_URL = `${reactApp().apiUrl}`;

export const PRINT_TAGS_URL = {
  ACTIVEOFFICES: "office/v1/officeListByStatus/Active",
  NONCLOSEDOFFICES: "office/v1/officeListByStatus/Active,PendingOpen",
  ALLOFFICES:"office/v1/alloffices",
  REQUEST_TAG: BASE_URL.includes("localhost") ? "printtagrequest" : "distribution/v1/printtagrequest",
  ARCHIVE_TAG: BASE_URL.includes("localhost") ? "archivetagrequest" : "distribution/v1/archivetagrequest",
  UPDATE_TAG: BASE_URL.includes("localhost") ? "printtagstatusbycolor" : "distribution/v1/printtagstatusbycolor",
};

export const OPEN_PULL_URL = {
  REQUEST_PULL: BASE_URL.includes("localhost") ? "openpull" : "distribution/v1/openpull",
  LOAD_ORDER: BASE_URL.includes("localhost") ? "Loadorderpick" : "distribution/v1/Loadorderpick",
};

export const FRAME_PICK_URL = {
  REQUEST_FRAME: BASE_URL.includes("localhost") ? "framepick" : "distribution/v1/framepick",
  REQUEST_PICKER: BASE_URL.includes("localhost") ? "picker" : "distribution/v1/picker",
  UPRATE_ACUITY: BASE_URL.includes("localhost") ? "updateacuityorderstatus" : "distribution/v1/updateacuityorderstatus",
  PRINT_LABEL: BASE_URL.includes("localhost") ? "printlabelstatus" : "distribution/v1/printlabelstatus",
  GENERATE_REPORT: BASE_URL.includes("localhost") ? "generatepdfreport" : "distribution/v1/generatepdfreport",
  REPRINT_LABELS: BASE_URL.includes("localhost") ? "reprintlabel" : "distribution/v1/reprintlabel",
};

export const VENDOR_ORDER_URL = {
  VENDOR_ORDER_LIST: BASE_URL.includes("localhost") ? "vendororder" : "distribution/v1/vendororder",
  VENDOR_ORDER_ITEMS: BASE_URL.includes("localhost") ? "vendororder/" : "distribution/v1/vendororder/",
  SAVE_ORDER_ITEMS: BASE_URL.includes("localhost") ? "vendororder/details" : "distribution/v1/vendororder/details",
  CANCEL_ORDER_ITEMS: BASE_URL.includes("localhost") ? "vendororder/cancel" : "distribution/v1/vendororder/cancel",
  PENDING_ORDER_LIST: BASE_URL.includes("localhost") ? "backorderpending" : "distribution/v1/backorderpending",
};

export const ORDER_STATUS_URL = {
  ORDER_STATUS_LIST: BASE_URL.includes("localhost") ? "orders" : "distribution/v1/orders",
  SAVE_ORDER_ITEMS: BASE_URL.includes("localhost") ? "vendororder/details" : "distribution/v1/vendororder/details",
};

export const DAILY_REPORT_URL = {
  DAILY_REPORT_LIST: BASE_URL.includes("localhost") ? "orders/daily" : "distribution/v1/orders/daily",
  UPDATE_ORDER_STATUS: BASE_URL.includes("localhost") ? "orders/status" : "distribution/v1/orders/status",
  VENDOR_EXLUSION_LIST: BASE_URL.includes("localhost") ? "vendorexclusionlist" : "distribution/v1/vendorexclusionlist",
  SAVE_VENDOR_EXLUSION: BASE_URL.includes("localhost") ? "vendorexclusion/update" : "distribution/v1/vendorexclusion/update",
};

export const VENDOR_URL = {
  VENDOR_LIST: BASE_URL.includes("localhost") ? "Vendors" : "distribution/v1/Vendors",
  VENDOR_PICKER_ASSIGNMENT: BASE_URL.includes("localhost") ? "vendorpickerassignment" : "distribution/v1/vendorpickerassignment",
  CREATE_UPDATE_PICKER: BASE_URL.includes("localhost") ? "picker" : "distribution/v1/picker",
};

export const REPLENISHMENT_URL = {
  REPLENISHMENT_LIST: BASE_URL.includes("localhost") ? "replenishmentOrders" : "distribution/v1/replenishmentOrders",
  REPLENISHMENT_APPROVE: BASE_URL.includes("localhost") ? "replenishmentorderapproval" : "distribution/v1/replenishmentorderapproval",
  REPLENISHMENT_REFRESH: BASE_URL.includes("localhost") ? "replenishmentrefresh" : "distribution/v1/replenishmentrefresh",
  REPLENISHMENT_VENDOR_LIST: BASE_URL.includes("localhost") ? "replenishmentVendors" : "distribution/v1/replenishmentVendors",
  OVERRIDE_ORDER_QTY_URL: BASE_URL.includes("localhost") ? "replenishmentorder" : "distribution/v1/replenishmentorder",
};

export const FEATURE_FLAG_URL = {
  DC_REPLENISHMENT_ENABLED: BASE_URL.includes("localhost") ? "featureflag/DC_ReplenishmentEnabled" : "util/v1/featureflag/DC_ReplenishmentEnabled",
  DC_VIEW_ORDERS_ENABLED: BASE_URL.includes("localhost") ? "featureflag/DC_ViewOrdersEnabled" : "util/v1/featureflag/DC_ViewOrdersEnabled",
  DC_KITS_MANAGEMENT_ENABLED: BASE_URL.includes("localhost") ? "featureflag/DC_KitsManagementEnabled" : "util/v1/featureflag/DC_KitsManagementEnabled",
};

export const ACCESSORY_REPLENISHMENT_URL = {
  ACCESSORY_REPLENISHMENT_LIST: BASE_URL.includes("localhost") ? "systempreferences" : "distribution/v1/systempreferences",
  PREFERENCES: BASE_URL.includes("localhost") ? "skuconfig" : "distribution/v1/skuconfig",
  GET_PRODUCT_CATEGORY_LIST: BASE_URL.includes("localhost") ? "itemgroup" : "distribution/v1/itemgroup",
  GET_PRODUCT_LIST: BASE_URL.includes("localhost") ? "item" : "distribution/v1/item",
};
export const KITS_MAINTENANCE_URL = {
  KITS_MAINTENANCE: BASE_URL.includes("localhost") ? "kit" : "distribution/v1/kit",
  KITS_MAINTENANCE_LIST: BASE_URL.includes("localhost") ? "kits/" : "distribution/v1/kits/",
  KITS_MAINTENANCE_DETAILS: BASE_URL.includes("localhost") ? "kit/" : "distribution/v1/kit/",
  CHECK_UPC_VENDOR_COLLECTION: BASE_URL.includes("localhost") ? "kit/checkupcvendorcollection" : "distribution/v1/kit/checkupcvendorcollection",
  COLLECTION_BY_VENDOR: BASE_URL.includes("localhost") ? "collectionbyvendor/" : "distribution/v1/collectionbyvendor/",
  VENDOR_LIST_WITH_KITS: BASE_URL.includes("localhost") ? "GetVendorsWithKits" : "distribution/v1/GetVendorsWithKits",
  DELETE_KIT: BASE_URL.includes("localhost") ? "kit/delete" : "distribution/v1/kit/delete",
  ARCHIVE_KIT: BASE_URL.includes("localhost") ? "kit/Archive" : "distribution/v1/kit/Archive",
};

export const KITS_PROCESSING_URL = {
  KITS_PROCESSING_LIST: BASE_URL.includes("localhost") ? "ProcessKit/" : "distribution/v1/ProcessKit/",
  KITS_PROCESSING_DETAILS: BASE_URL.includes("localhost") ? "kit/" : "distribution/v1/kit/",
  PRE_POPULATED_OFFICES: BASE_URL.includes("localhost") ? "kit/prepopulatedoffices/" : "distribution/v1/kit/prepopulatedoffices/",
  SELECTED_OFFICES: BASE_URL.includes("localhost") ? "selectedOffices" : "distribution/v1/selectedOffices",
  KIT_BY_VENDOR: BASE_URL.includes("localhost") ? "kit/namelist/" : "distribution/v1/kit/namelist/",
  VALIDATE_OFFICES: BASE_URL.includes("localhost") ? "kit/quickofficeentry" : "distribution/v1/kit/quickofficeentry",
};

export const FRAME_ARRANGER_QTY_URL = {
  FRAME_ARRANGER_QTY_LIST: BASE_URL.includes("localhost") ? "Assortments" : "distribution/v1/Assortments",
  FRAME_ARRANGER_QTY_SAVE: BASE_URL.includes("localhost") ? "FrameCollectionMapping" : "distribution/v1/FrameCollectionMapping",
  FRAME_TYPE_LIST: BASE_URL.includes("localhost") ? "frametypes" : "distribution/v1/frametypes",
  DATABASE_COLLECTION_LIST: BASE_URL.includes("localhost") ? "vendortocollectionlisting" : "distribution/v1/vendortocollectionlisting",
  EDIT_FA_QTY: BASE_URL.includes("localhost") ? "FrameCollectionMapping/" : "distribution/v1/FrameCollectionMapping/",
  FRAME_ARRANGER_LIST_FOR_OFFICE: BASE_URL.includes("localhost") ? "framearrangers/" : "distribution/v1/framearrangers/",
  ASSORTMENT_SKU: BASE_URL.includes("localhost") ? "assortment/getframes/" : "distribution/v1/assortment/getframes/",
  VALIDATE_SKU: BASE_URL.includes("localhost") ? "validateupcforassortment" : "distribution/v1/validateupcforassortment",
  IMPORT_SKU_VALIDATION: BASE_URL.includes("localhost") ? "validateupcforimportassortment/" : "distribution/v1/validateupcforimportassortment/",
  ASSORTMENT_COUNT: BASE_URL.includes("localhost") ? "AssortmentCount/" : "distribution/v1/AssortmentCount/",
  ASSORTMENT_DELETE: BASE_URL.includes("localhost") ? "DeleteAssortment" : "distribution/v1/DeleteAssortment",
  ASSORTMENT_IMPORT: BASE_URL.includes("localhost") ? "ImportAssortment/" : "distribution/v1/ImportAssortment/",
};
export const VENDOR_COLLECTION_URL = {
  VENDOR_COLLECTION_LIST: BASE_URL.includes("localhost") ? "vendortocollectionlisting" : "distribution/v1/vendortocollectionlisting",
  VENDOR_COLLECTION_SAVE: BASE_URL.includes("localhost") ? "vendortocollectionlisting" : "distribution/v1/vendortocollectionlisting",
  COLLECTION_LIST: BASE_URL.includes("localhost") ? "framecollections" : "distribution/v1/framecollections",
  EDIT_VC: BASE_URL.includes("localhost") ? "vendortocollectionlisting/" : "distribution/v1/vendortocollectionlisting/",
  COLLECTION_TYPE_LIST: BASE_URL.includes("localhost") ? "collectionTypes" : "distribution/v1/collectionTypes",
};

export const PRODUCT_PLANOGRAM_URL = {
  PRODUCT_PLANOGRAM_LIST: BASE_URL.includes("localhost") ? "getplanogramdata" : "distribution/v1/getplanogramdata",
  PRODUCT_PLANOGRAM_UPDATE_INSERT: BASE_URL.includes("localhost") ? "productplanogram" : "distribution/v1/productplanogram",
  PRODUCT_PLANOGRAM_IMPORT_FILE: BASE_URL.includes("localhost") ? "importproductplanograms" : "distribution/v1/importproductplanograms",
  PRODUCT_PLANOGRAM_BY_ID: BASE_URL.includes("localhost") ? "productplanogram/" : "distribution/v1/productplanogram/",
  VALIDATE_ACUITY_FRAME_IMPORT: BASE_URL.includes("localhost") ? "productimportacuityvalidation" : "distribution/v1/productimportacuityvalidation",
  DELETE_PP_ITEM: BASE_URL.includes("localhost") ? "deleteproductplanogarm/" : "distribution/v1/deleteproductplanogarm/",
  GET_ASSORTMENTS: BASE_URL.includes("localhost") ? "assortmentNames/" : "distribution/v1/assortmentNames/",
  PRODUCT_COST_BY_UPCCODE: BASE_URL.includes("localhost") ? "productcost/" : "distribution/v1/productcost/",
};

export const OFFICE_COLLECTION_URL = {
OFFICE_COLLECTION_LIST: BASE_URL.includes("localhost") ? "officeCollectionStock/" : "distribution/v1/officeCollectionStock/",
  OFFICE_COLLECTION_SAVE: BASE_URL.includes("localhost") ? "OfficeCollectionMapping" : "distribution/v1/OfficeCollectionMapping",
  COLLECTION_TYPE_LIST: BASE_URL.includes("localhost") ? "collectionTypes" : "distribution/v1/collectionTypes",
  EDIT_OC: BASE_URL.includes("localhost") ? "OfficeCollectionMapping/" : "distribution/v1/OfficeCollectionMapping/",
  BULK_EDIT: BASE_URL.includes("localhost") ? "OfficeCollectionMapping/bulkedit" : "distribution/v1/OfficeCollectionMapping/bulkedit",
  CLOSE_OC: BASE_URL.includes("localhost") ? "closeoffice" : "distribution/v1/closeoffice",
};

export const BULK_COST_UPDATE_URL = {
  BULK_COST_UPDATE_SUMMARY_LIST: BASE_URL.includes("localhost") ? "getbulkcostupdatesummary" : "distribution/v1/getbulkcostupdatesummary",
  BULK_COST_UPDATE_DETAILS: BASE_URL.includes("localhost") ? "GetBulkCostUpdateDetailsBySummaryId/" : "distribution/v1/GetBulkCostUpdateDetailsBySummaryId/",
  BULK_COST_UPDATE_VALIDATIONS: BASE_URL.includes("localhost") ? "GetBulkCostUpdateValidationsBySummaryId/" : "distribution/v1/GetBulkCostUpdateValidationsBySummaryId/",
  BULK_COST_UPDATE_VALIDATION: BASE_URL.includes("localhost") ? "BulkCostUpdateValidation" : "distribution/v1/BulkCostUpdateValidation",
  BULK_COST_UPDATE_FINAL_UPDATE: BASE_URL.includes("localhost") ? "BulkCostFinalUpdate" : "distribution/v1/BulkCostFinalUpdate",
};

export const FEDEX_URL = {
  SHIPPING_STATUS: BASE_URL.includes("localhost") ? "dailyshipping" : "distribution/v1/dailyshipping",
  CREATE_SHIPPING_URL: BASE_URL.includes("localhost") ? "CreateFedExShipment" : "distribution/v1/CreateFedExShipment",
  REPRINT_URL: BASE_URL.includes("localhost") ? "RePrintShipmentLabel" : "distribution/v1/RePrintShipmentLabel",
};
export const EXPRESS_OFFICE_URL = {
  EXPRESS_OFFICE_LIST: BASE_URL.includes("localhost") ? "ListShipmentPreference" : "distribution/v1/ListShipmentPreference",
  DELETE_OFFICE: BASE_URL.includes("localhost") ? "DeleteShipmentPreference" : "distribution/v1/DeleteShipmentPreference",
  ADD_OFFFICE: BASE_URL.includes("localhost") ? "AddShipmentPreference" : "distribution/v1/AddShipmentPreference",
};
export const FQDN = {
  PRINT_LABEL_FORM:
    "http://medreports.myeyedr.corp/reports/report/Distribution/LabelFormNTEST",
};

export default {
  OPEN_PULL_URL,
  PRINT_TAGS_URL,
  FRAME_PICK_URL,
  VENDOR_URL,
  REPLENISHMENT_URL,
  VENDOR_ORDER_URL,
};
