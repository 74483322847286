import { Divider, Spin } from "antd";
import { fetchVendorCollectionMapping } from "./actions";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import "./../../../styles/vendor.scss";
import { useDispatch } from "react-redux";
import { getFADetail } from "./sagas";
import AddVCMapping from "./AddVCMapping";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "src/components/common/Grid/GenericGrid";

function VendorsAndCollection() {
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [visible, setVisible] = useState(false);
  const { hasPermission } = useRolePermission();
  useEffect(() => {
    dispatch(fetchVendorCollectionMapping({}));
  }, []);
  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };
  const editDrawer = (record: any) => {
    let payload;
    payload = [record?.AcuityCollectionID];
    setVisible(true);
    const res = getFADetail(payload);
    res.then((response: any) => {
      setModalData(response);
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setModalData({});
    dispatch(fetchVendorCollectionMapping({}));
  };

  let filterValues = filterColumns(editDrawer);

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Vendor to Collection Mapping</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            {hasPermission("vendorscollectionsmapping_add") && (
              <Button
                size="middle"
                icon={<PlusOutlined />}
                style={{ marginRight: "10px" }}
                ant-click-animating-without-extra-node="true"
                onClick={addDrawer}
              >
                {" "}
                Add New
              </Button>
            )}
          </div>
        </TableFilter>
        <GenericGrid
        columns={filterValues.columns}
        data={filterValues.data}
        enableRowSelection={false}
        rowSelection={[]}
        filterDisplayMode="popover"
        highlightFilterMatch={true}
        paginationDisplayMode="pages"
        paginationProps={{
          color: 'primary',
          shape: 'rounded',
          showRowsPerPage: true,
          variant: 'outlined',
          rowsPerPageOptions: [10, 50, 100, 500, 1000]
        }}
        enableStickyHeader={true}
        enableStickyFooter={true}
        enableColumnPinning={true}
        enableColumnOrdering={false}
        columnPinning={{}}
        isLoading={filterValues.loading}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '11px',
            textAlign: 'left',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '10px',
            fontWeight: '800',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            textAlign: 'center',
          },
        }}
        muiTableBodyRowProps={{
          sx: {
            "& td": {
              padding: "1px 27px", 
            }
          },
        }}>
        </GenericGrid>
        {" "}
        <AddVCMapping
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
        />
      </Spin>
    </>
  );
}
export default VendorsAndCollection;
