import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "BULK_COST_UPDATE";
export const BCU_IMPORT_FILE = "BCU_IMPORT_FILE";
export const BCU_IMPORT_FILE_SUCCESS = "BCU_IMPORT_FILE_SUCCESS";
export const BCU_IMPORT_FILE_FAILED = "BCU_IMPORT_FILE_FAILED";

export const BCU_CLEAN_IMPORT_LAYOUT = "BCU_CLEAN_IMPORT_LAYOUT";
export const BCU_UPDATE_VALIDATED_IMPORT = "BCU_UPDATE_VALIDATED_IMPORT";
export const BCU_UPDATE_VALIDATED_IMPORT_SUCCESS =  "BCU_UPDATE_VALIDATED_IMPORT_SUCCESS";
export const BCU_UPDATE_VALIDATED_IMPORT_FAILED = "BCU_UPDATE_VALIDATED_IMPORT_FAILED";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const BULK_COST_UPDATE_FETCH = KEYS[MODULE_KEY + ACTION.FETCH];

export const fetchBulkCostUpdateSummary = (payload: any) => ({
  type: BULK_COST_UPDATE_FETCH,
  payload,
});

export const fetchBulkCostUpdateSummarySuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchBulkCostUpdateSummaryFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});

export const importFile = (payload: any) => ({
  type: BCU_IMPORT_FILE,
  payload,
});

export const importFileSuccess = (payload: any) => ({
  type: BCU_IMPORT_FILE_SUCCESS,
  payload,
});

export const importFileFailed = (payload: any) => ({
  type: BCU_IMPORT_FILE_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: BCU_CLEAN_IMPORT_LAYOUT,
});

export const updateValidatedImport = (
  payload: any,
  actionType: any,
  flag: any
) => ({
  type: BCU_UPDATE_VALIDATED_IMPORT,
  payload,
  actionType,
  flag,
});
