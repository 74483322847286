import { DatePicker, Divider, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { formatDate } from "../../util/utils";
import { fetchOpenPullList } from "./openPull-actions";
import { MODULE_KEY } from "./openPull-actions";
import "./../../styles/OpenPull.scss";
import useTableProps from "../../hooks/table/useTableProps";
import dayjs from "dayjs";
import TableFilter from "../common/TableFilter/TableFilter";
import Button from "../common/Button/Button";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import filterColumns from "./filterColumns";
import { useMsal } from "@azure/msal-react";
import GenericGrid from "../common/Grid/GenericGrid";

let memoizeStartDate = dayjs().subtract(1, "days").format("MM/DD/YYYY");
let memoizeEndDate = dayjs().subtract(1, "days").format("MM/DD/YYYY");
function OpenPull() {
  const { accounts }: any = useMsal();
  const dispatch = useDispatch();
  const tableProps = useTableProps(MODULE_KEY, ["rowSelection"]);
  const { dataSource, loading } = tableProps;
  const [loader, setLoader] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: memoizeStartDate,
    endDate: memoizeEndDate,
  });
  let filterValues = filterColumns();
  const tagColumns = useMemo(() => filterValues.columns, [dataSource]);

  const startDateChange = (date: any) => {
    if (!date) {
      setDateRange({ endDate: date, startDate: date });
    } else {
      memoizeStartDate = date;
      setDateRange({ ...dateRange, startDate: date });
    }
  };
  const endDateChange = (date: any) => {
    memoizeEndDate = date;
    setDateRange({ ...dateRange, endDate: date });
  };

  const onLoad = () => {
    const payload = {
      startDate: formatDate(dateRange.startDate),
      endDate: formatDate(dateRange.endDate),
      offset: 0,
      limit: 100000,
      createdBy: accounts[0]?.username,
    };
    dispatch(fetchOpenPullList(payload));
  };

  const spinning = loading || loader;
  const { startDate, endDate }: any = dateRange;
  return (
    <div>
      <Divider />
      <Breadcrumb>
        <span>Open Pull</span>
      </Breadcrumb>
      <TableFilter>
        <div className="form align-items-end">
          <DatePicker
            value={startDate && dayjs(startDate)}
            disabled={spinning}
            disabledDate={(d: any) =>
              !d ||
              d.isAfter(dayjs()) ||
              dayjs().add(-365, "days") >= d ||
              dayjs(endDate)?.isBefore(d)
            }
            placeholder="Start Date"
            onChange={startDateChange}
          />
          <DatePicker
            value={endDate && dayjs(endDate)}
            disabled={spinning || !startDate}
            disabledDate={(d: any) =>
              !d ||
              d.isAfter(dayjs()) ||
              d.isBefore(startDate) ||
              dayjs(startDate)?.isAfter(d)
            }
            placeholder="End Date"
            onChange={endDateChange}
          />

          <Button
            disabled={!(dateRange.startDate && dateRange.endDate) || spinning}
            onClick={onLoad}
            size="middle"
          >
            Load Data
          </Button>
        </div>
      </TableFilter>
      <GenericGrid
        columns={filterValues.columns}
        data={filterValues.data}
        enableRowSelection={false}
        rowSelection={[]}
        columnVisibility={{}}
        columnPinning={{ left: ['OfficeNum'] }}
        enableColumnOrdering={false}
        filterDisplayMode="popover"
        highlightFilterMatch={true}
        paginationDisplayMode="pages"
        paginationProps={{
          color: 'primary',
          shape: 'rounded',
          showRowsPerPage: true,
          variant: 'outlined',
          rowsPerPageOptions: [10, 50, 100, 500, 1000]
        }}
        enableStickyHeader={true}
        enableStickyFooter={true}
        enableColumnPinning={true}
        isLoading={filterValues.loading}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '11px',
            textAlign: 'left',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            padding: '2px 10px'
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '10px',
            fontWeight: '800',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            textAlign: 'center',
            padding: '2px 10px'
          }
        }}
        muiTableBodyRowProps={{
          sx: {
            "& td": {
              padding: "2px 10px",
            },
            textAlign: 'left',
          },
        }}
      >
      </GenericGrid>
    </div>
  );
}

export default OpenPull;
