import {
  MODULE_KEY,
  fetchFrameArrangerQty,
  assortmentImportFile,
} from "./actions";
import { Divider, Form, Input, Modal, Spin, Switch } from "antd";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import {
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import AddFAQty from "./AddFAQty";
import "./../../../styles/vendor.scss";
import { useDispatch } from "react-redux";
import { deleteAssortment, getFADetail } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Label from "src/components/common/Label/Label";
import { useInputs } from "src/hooks/useInput";
import { useMsal } from "@azure/msal-react";
import { UploadModal } from "src/components/common/Uploads/UploadModal";
import { ASSORTMENT_MANAGEMENT_EXCEL_COLUMNS } from "src/util/columns";
const ExcelJS = require("exceljs");
import { useSelector } from "react-redux";
import { ACTION, ImportType } from "src/util/utils";
import { ValidationModal } from "../OfficeCollection/ValidationModal";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "src/components/common/Grid/GenericGrid";

function FrameArrangerQty() {
  const defaultData = {};
  const { accounts }: any = useMsal();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [frameDetailForm] = Form.useForm();
  const [skuData, setSkuData] = useState<any>([]);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [showInactive, setShowInactive] = useState(false);
  const [datasource, setDataSource] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const { hasPermission } = useRolePermission();
  const [inputs, onInputChange, updateValue, resetInputs] = useInputs({
    defaultData,
  });
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setExportOpen(true);
  };
  const [actionType, setActionType] = useState("");

  const {
    data,
    activeAssortments,
    inactiveAssortments,
    importValidationData,
    importFailed,
  } = useSelector((state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]);

  useEffect(() => {
    setDataSource(showInactive ? data : activeAssortments);
  }, [data, showInactive]);

  useEffect(() => {
    setDataSource(activeAssortments);
    dispatch(fetchFrameArrangerQty({}));
  }, []);

  useEffect(() => {
    if (importValidationData)
      if (
        importValidationData.Failed_Count === 0 &&
        importValidationData.Success_Count === importValidationData.Total_Count
      ) {
        showSuccessNotification("Data added successfully.");
        dispatch(fetchFrameArrangerQty({}));
      } else if (importValidationData.Failed_Count > 0) {
        setErrorModal(true);
      }
  }, [importValidationData]);

  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setModalData({});
    frameDetailForm.resetFields();
    setVisible(true);
  };
  const editDrawer = (record: any, type: string) => {
    let payload;
    payload = record?.AssortmentId;
    setVisible(true);
    const res = getFADetail(payload);
    res.then((response: any) => {
      setActionType(type);
      setModalData(response);
    });
  };

  const deleteModal = (record: any) => {
    setVisibleDelete(true);
    setDeleteData(record);
  };

  const handleCancel = () => {
    dispatch(fetchFrameArrangerQty({}));
    form.resetFields();
    resetInputs();
  };

  let filterValues = filterColumns(editDrawer, deleteModal);
  const handleDelete = () => {
    if (skuData?.length) {
      const found = skuData?.some((item: any) => item.UPC === deleteData);
      if (found) {
        const pos = skuData.map((e: any) => e.UPC).indexOf(deleteData);
        skuData.splice(pos, 1);
        setSkuData([...skuData]);
        showSuccessNotification("SKU(s) deleted successfully");
      }
    } else {
      const payload = {
        ID: deleteData,
        CreatedBy: accounts?.[0]?.username,
      };
      deleteAssortment(payload)
        .then((res: any) => {
          showSuccessNotification(res);
          handleCancel();
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data.error.messages[0]);
        });
    }
    setVisibleDelete(false);
  };

  const handleSkuSearch = () => {
    const payload = {
      SKUs: inputs?.SkuSearch?.split(",")?.map((item: any) => item?.trim()),
    };
    dispatch(fetchFrameArrangerQty(payload));
  };

  const handleResetSearch = () => {
    handleCancel();
  };

  const exportExcelFile = async () => {
    setConfirmLoading(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    //sheet.properties.defaultRowHeight = 25;
    sheet.columns = ASSORTMENT_MANAGEMENT_EXCEL_COLUMNS;
    const data = filteredData?.length > 0 ? filteredData : datasource;
    try {
      if (data?.length > 0) {
        setExportOpen(false);
        setConfirmLoading(false);
        let color;
        const promise = Promise.all(
          data?.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              ...item,
            });
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Assortment-Management-Report.csv";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        setExportOpen(false);
        setConfirmLoading(false);
        showErrorNotification("No data available for the selected date");
      }
    } catch (error: any) {
      setExportOpen(false);
      setConfirmLoading(false);
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Assortment Management</span>
        </Breadcrumb>
        <TableFilter>
          <Form
            layout={"horizontal"}
            form={form}
            initialValues={{ layout: "horizontal" }}
          >
            <div className="form align-items-end">
              <Label label="Search for SKU(s), separated by commas (,)">
                <Input
                  autoComplete="false"
                  name="SkuSearch"
                  value={inputs.SkuSearch}
                  onChange={onInputChange}
                  style={{ width: "400px" }}
                ></Input>
              </Label>
              <Button
                disabled={!inputs?.SkuSearch || confirmLoading}
                onClick={handleSkuSearch}
                size="middle"
              >
                Search
              </Button>
              <Button
                disabled={confirmLoading}
                onClick={handleResetSearch}
                size="middle"
              >
                Reset
              </Button>
            </div>
          </Form>

          <div className="d-flex justify-content-end gap-2">
            {showInactive}
            <Label
              label="Show Inactive"
              className="flex-row align-items-center fs-6 gap-1"
            >
              <Switch
                size="small"
                checked={showInactive}
                onChange={(flag) => {
                  setShowInactive(flag);
                }}
              />
            </Label>
            {hasPermission("assortmentmanagement_import") && (
              <Button
                size="middle"
                icon={<UploadOutlined />}
                ant-click-animating-without-extra-node="true"
                onClick={() => setOpenUpload(true)}
              >
                {" "}
                Import
              </Button>
            )}
            <Button
              size="middle"
              icon={<DownloadOutlined />}
              ant-click-animating-without-extra-node="true"
              onClick={closeModal}
              disabled={defaultData === "none"}
            >
              {" "}
              Export
            </Button>
            {hasPermission("assortmentmanagement_add") && (
              <Button
                size="middle"
                icon={<PlusOutlined />}
                ant-click-animating-without-extra-node="true"
                onClick={addDrawer}
              >
                {" "}
                Add New
              </Button>
            )}
          </div>
        </TableFilter>
        <GenericGrid
        columns={filterValues.columns}
        data={datasource}
        enableRowSelection={false}
        rowSelection={[]}
        filterDisplayMode="popover"
        highlightFilterMatch={true}
        paginationDisplayMode="pages"
        paginationProps={{
          color: 'primary',
          shape: 'rounded',
          showRowsPerPage: true,
          variant: 'outlined',
          rowsPerPageOptions: [10, 50, 100, 500, 1000]
        }}
        enableStickyHeader={true}
        enableStickyFooter={true}
        enableColumnPinning={true}
        columnPinning={{}}
        isLoading={filterValues.loading}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '11px',
            textAlign: 'center',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '10px',
            fontWeight: '800',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            textAlign: 'center',
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', 
          },
        }}
        muiTableBodyRowProps={{
          sx: {
            "& td": {
              padding: "1px 15px", 
            },
            marginLeft: '15px'
          },
        }}>
        </GenericGrid>
        {" "}
        <AddFAQty
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
          filterValues={filterValues}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
          frameDetailForm={frameDetailForm}
          skuData={skuData}
          setSkuData={setSkuData}
          actionType={actionType}
          accounts={accounts}
        />
      </Spin>
      <UploadModal
        visible={openUpload}
        onCancel={() => setOpenUpload(false)}
        stateKey={`${MODULE_KEY}${ACTION.STATE_KEY}`}
        importFile={assortmentImportFile}
        confirmLoading={confirmLoading}
        setConfirmLoading={setConfirmLoading}
      />
      <ValidationModal
        visible={errorModal}
        data={importValidationData}
        onCancel={() => setErrorModal(false)}
        importType={ImportType.AssortmentImport}
      />
      <Modal
        title="Delete Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={visibleDelete}
        onOk={handleDelete}
        onCancel={() => setVisibleDelete(false)}
      >
        <span>
          Are you sure you wish to delete this{" "}
          {skuData?.length ? "UPC" : "assortment"}?
        </span>
      </Modal>
      <Modal
        title="Excel export Confirmation"
        okText="Ok"
        open={exportOpen}
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        onOk={() => exportExcelFile()}
        onCancel={() => setExportOpen(false)}
      >
        <span>Are you sure you want to export this data?</span>
      </Modal>
    </>
  );
}
export default FrameArrangerQty;
