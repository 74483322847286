import { useMsal } from "@azure/msal-react";
import { Card, Form, Input, Drawer, Space, Spin, Select } from "antd";
import { useState } from "react";
import { showErrorNotification, showSuccessNotification } from "../../util/notifications";
import Button from '../common/Button/Button';
import { updateOrderStatus } from "./sagas";
import useRolePermission from "src/hooks/useRolePermission";

interface UpdateOrderStatusType {
    visible: boolean
    loading?: boolean
    setVisibleStatus: any
    setSelectedRow: any
    selectedRow: any
    onLoad: any
    setRowSelection: any
}

function UpdateOrderStatus({ visible, loading = false, setVisibleStatus, setSelectedRow, selectedRow, onLoad, setRowSelection }: UpdateOrderStatusType) {
    const { Option } = Select;
    const [form] = Form.useForm();
    const [orderStatus, setOrderStatus] = useState<any>("");
    const { accounts } = useMsal();
    const userName = accounts?.length ? accounts[0].name : "";
    const { hasPermission } = useRolePermission();
    const handleClose = () => {
        setVisibleStatus(false);
        form.resetFields();
        setSelectedRow([]);
        setRowSelection({});
    };

    const handleOk = async () => {
        if (orderStatus) {
            setVisibleStatus(true);
        }
        try {
            const obj = {
                "OrderNumbers": selectedRow.map((row: any) => row.OrderNumber),
                "NewOrderStatus": orderStatus,
                "ModifiedBy": userName
            }
            const res = await updateOrderStatus(obj);
            if (res) {
                showSuccessNotification(res);
                handleClose();
                onLoad();
            } else {
                showErrorNotification(res);
            }
        } catch (error: any) {
            showErrorNotification(error?.response?.data);
        }
    };
    return (
        <>
            <Drawer
                title="Update Order Status"
                placement="right"
                open={visible}
                onClose={handleClose}
                width={400}
                closable={false}
                className="dc-drawer-panel"
                forceRender={true}
                extra={
                    <Space>
                        <Button danger onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleOk} disabled={!orderStatus} >
                            Update status
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={{ remember: false }}
                        autoComplete="off"
                        layout='vertical'
                        className='add-picker-form'
                        style={{
                            backgroundColor: "#f3f3f3",
                            borderRadius: "16px",
                            marginRight: "24px",
                            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
                        }}
                    >
                        <Card>
                            <Form.Item
                                name='OrderStatus'
                            >
                                {/* <Select
                                    placeholder="Select"
                                    showSearch
                                    value={orderStatus}
                                    style={{ width: 300, marginLeft: 10 }}
                                    optionFilterProp="children"
                                    size="middle"
                                    onSelect={(val: any) => {
                                        setOrderStatus(val)
                                    }}
                                >
                                    <Option value=""><Input placeholder="Enter status" /></Option>
                                    {ORDER_STATUS_VALUES?.map(({ name }: any) => {
                                        return <Option key={name} value={name}>{name}</Option>
                                    })}
                                </Select> */}
                                <Form.Item
                                    name="OrderStatus"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Please enter order status`,
                                        }
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter order status"
                                        onChange={(val: any) => {
                                            setOrderStatus(val.target.value)
                                        }}
                                    />
                                </Form.Item>
                            </Form.Item>
                        </Card>
                    </Form>
                </Spin>
            </Drawer>
        </>
    )
}

export default UpdateOrderStatus;
