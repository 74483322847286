import { Divider, Form, Modal, Spin } from "antd";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import filterColumns from "./filterColumns";
import { fetchExpressOffice } from "./actions";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { deleteOffice } from "./sagas";
import AddOffice from "./AddOffice";
import useRolePermission from "src/hooks/useRolePermission";
import GenericGrid from "src/components/common/Grid/GenericGrid";

function ExpressOffices() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { accounts }: any = useMsal();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [visible, setVisible] = useState(false);
  const {hasPermission} = useRolePermission();
  const deleteModal = (record: any) => {
    setVisibleDelete(true);
    setDeleteData(record);
  };

  let filterValues = filterColumns(deleteModal);
  useEffect(() => {
    dispatch(fetchExpressOffice({}));
  }, []);

  const handleCancel = () => {
    dispatch(fetchExpressOffice({}));
  };
  const handleDelete = () => {
    const payload = {
      OfficeNumber: deleteData,
      CreatedBy: accounts?.[0]?.username,
    };
    deleteOffice(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        handleCancel();
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
      });
    setVisibleDelete(false);
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Fed Ex Express Offices Utility</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            {
              hasPermission('expressoffices_add') &&
              <Button
              size="middle"
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              icon={<PlusOutlined />}
              onClick={() => setVisible(true)}
            >
              Add Office
            </Button>
            }
          </div>
        </TableFilter>
        <GenericGrid
        columns={filterValues.columns}
        data={filterValues.data}
        enableRowSelection={false}
        rowSelection={[]}
        filterDisplayMode="popover"
        highlightFilterMatch={true}
        paginationDisplayMode="pages"
        paginationProps={{
          color: 'primary',
          shape: 'rounded',
          showRowsPerPage: true,
          variant: 'outlined',
          rowsPerPageOptions: [10, 50, 100, 500, 1000]
        }}
        enableStickyHeader={true}
        enableStickyFooter={true}
        enableColumnPinning={true}
        columnPinning={{}}
        isLoading={filterValues.loading}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '11px',
            textAlign: 'center',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: '10px',
            fontWeight: '800',
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
            textAlign: 'center',
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%', 
          },
        }}
        muiTableBodyRowProps={{
          sx: {
            "& td": {
              padding: "1px 15px", 
            },
            marginLeft: '15px'
          },
        }}>
        </GenericGrid>
        <AddOffice
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          setConfirmLoading={setConfirmLoading}
        />
      </Spin>
      <Modal
        title="Delete Confirmation"
        okText="Ok"
        centered
        confirmLoading={confirmLoading}
        style={{ top: 20 }}
        open={visibleDelete}
        onOk={handleDelete}
        onCancel={() => setVisibleDelete(false)}
      >
        <span>Are you sure you wish to delete this office?</span>
      </Modal>
    </>
  );
}
export default ExpressOffices;
