import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION  } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { Button as AntButton } from "antd";
import useRolePermission from "src/hooks/useRolePermission";
import { Tooltip } from "antd";
import {
  DownSquareOutlined,
  ProfileOutlined,
  RightSquareOutlined
} from "@ant-design/icons";
import { formatDate } from "src/util/date";
import BulkCostUpdateLinks from "./BulkCostUpdateLinks";

export default function filterColumns(
  runValidationModal?: ((record: any) => void) | any,
  finalUpdateModal?: ((record: any) => void) | any,
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const { hasPermission } = useRolePermission();
  const columns = [
    {
      header: "ID",
      accessorKey: "Id",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 70,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "FILE NAME",
      accessorKey: "OriginalFileName",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        textAlign: 'left',
      },
      muiTableBodyCellProps: {
        textAlign: 'left',
      }
    },
    {
      header: "UPLOADED BY",
      accessorKey: "UploadedBy",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "UPLOADED DATE",
      accessorKey: "UploadedDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ cell }: { cell: any }) => {
        const uploadedDate = cell.row?.original.UploadedDate;
        if (!uploadedDate || uploadedDate === "null") {
          return "N/A";
        }
        const formattedUploadedDate = formatDate(uploadedDate);
        return formattedUploadedDate;
      }
    },
    {
      header: "FINAL UPDATE DATE",
      accessorKey: "FinalUpdateDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ cell }: { cell: any }) => {
        const finalUpdateDate = cell.row?.original.FinalUpdateDate;
        if (!finalUpdateDate || finalUpdateDate === "null") {
          return "N/A";
        }
        const formattedFinalUpdateDate = formatDate(finalUpdateDate);
        return formattedFinalUpdateDate;
      }
    },
    {
      header: "FINAL UPDATE BY",
      accessorKey: "FinalUpdateBy",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "DETAILS",
      accessorKey: "Details",
      enableColumnFilter: false,
      enableSorting: false,
      size: 100,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
        <BulkCostUpdateLinks
          param={cell.row?.original.Id}
          modalTitle={"Bulk Cost Update Details"}
        />
        </>
        );
      }
    },
    {
      header: "VALIDATIONS",
      accessorKey: "Validations",
      enableColumnFilter: false,
      enableSorting: false,
      size: 100,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <BulkCostUpdateLinks
            param={cell.row?.original.Id}
            modalTitle={"Bulk Cost Update Validations"}
          />
        );
      },
    },
    {
      header: "ACTION",
      accessorKey: "operation",
      enableColumnFilter: false,
      enableSorting: false,
      size: 90,
      align: 'right',
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
          <div className="d-flex w-100">
            <div>
              <Tooltip placement="top" title="Run Validation">
                <Button
                  icon={<ProfileOutlined />}
                  onClick={() => runValidationModal(cell.row?.original.Id)}
                  children={undefined}
                  disabled={!hasPermission("bulkcostupdate_runvalidation")}
                ></Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip placement="top" title="Final Update">
                <Button
                  style={{ marginLeft: "10px", display: (cell.row?.original.ValidationCount > 0 && cell.row?.original.UpdateStatus === false) ? "block" : "none" }}
                  icon={<RightSquareOutlined />}
                  onClick={() => finalUpdateModal(cell.row?.original.Id)}
                  children={undefined}
                  danger={true}
                  disabled={!hasPermission("bulkcostupdate_finalupdate")}
                ></Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip placement="top" title="Download File">
                <section className="btn-wrapper">
                  <AntButton
                    type="link"
                    size="small"
                    style={{ marginLeft: "10px", background: "#003366" }}
                    icon={<DownSquareOutlined style={{ color: "white" }} />}
                    href={`${cell.row?.original.BlobPath}`} download>
                  </AntButton>
                </section>
              </Tooltip>
            </div>
          </div>
          </>
        );
      }
    },
  ];
  return { columns, data, loading };
}
