import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import {ASSORTMENT_SKU_COLUMN} from "src/util/columns";
import Button from "src/components/common/Button/Button";
import ColorTag from "src/components/common/ColorTag/ColorTag";
import { Tooltip } from "antd";
import useRolePermission from "src/hooks/useRolePermission";
import { formatDate } from "src/util/date";
import Links from "src/components/common/Links/Links";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { CopyOutlined } from "@ant-design/icons";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((record: any) => void) | any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const { hasPermission } = useRolePermission();
  const vendors: any | null | undefined = [];
  const collection: any | null | undefined = [];
  const active: any | null | undefined = [];
  const frameType: any | null | undefined = [];
  data?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    collection.push({ text: row.Collection, value: row.Collection });
    frameType.push({
      text: row.FrameTypeDescription,
      value: row.FrameTypeDescription,
    });
    if (row.Active === true) {
      active.push({ text: "Active", value: row.Active });
    } else if (row.Active === false) {
      active.push({ text: "Inactive", value: row.Active });
    } else {
      active.push({ text: "NA", value: row.Active });
    }
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
 
  let FrameTypeDescription = frameType.filter(
    (ele: any, ind: any) =>
      ind ===
      frameType.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  
  const skuActionColumn = {
    title: "action",
    dataIndex: "operation",
    fixed: "right",
    width: 15,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            style={{ marginLeft: "20px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record?.UPC)}
            children={undefined}
            danger={true}
          ></Button>
        </div>
      );
    },
  };

  let skuColumns = insertAt(ASSORTMENT_SKU_COLUMN, 7, 0, skuActionColumn);

  const columns = [
    {
      id: 'AssortmentId',
      header: 'ASSORTMENT ID',
      accessorKey: 'AssortmentId',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'ASSORTMENT NAME',
      accessorKey: 'AssortmentName',
      size: 150,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'FRAME TYPE DESCRIPTION',
      accessorKey: 'FrameTypeDescription',
      size: 150,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: FrameTypeDescription
    },
    {
      header: 'DATABASE COLLECTION ID',
      accessorKey: 'DatabaseCollection',
      size: 140,
      enableSorting: true,
      enableColumnFilter: true,
      filterFn: 'contains',
    },
    {
      header: 'VENDOR NAME',
      accessorKey: 'Vendor',
      size: 140,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: vendorName
    },
    {
      header: 'COLLECTION NAME',
      accessorKey: 'Collection',
      size: 160,
      enableColumnFilter: true,
      enableSorting: true,
      filterVariant: 'multi-select',
      filterSelectOptions: collectionName
    },
    {
      header: 'FRAME TYPE ID',
      accessorKey: 'FrameTypeID',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      header: 'ASSORTMENT QUANTITY',
      accessorKey: 'ArrangerQty',
      size: 170,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: 'ASSORTMENT COUNT',
      accessorKey: 'AssortmentCount',
      size: 160,
      enableColumnFilter: false,
      enableSorting: true,
    },
    {
      header: 'ACTIVE',
      accessorKey: 'Active',
      size: 130,
      enableColumnFilter: true,
      filterVariant: 'multi-select',  
      filterFn: (row: any, columnId: any, filterValue: any) => {
        let value = row?.original?.Active === true ? "Active" : "Inactive";
        if (Array.isArray(filterValue)) {
          if (Array.isArray(filterValue)) {
            if (filterValue.length === 0) {
              return true;
            }
            return filterValue.includes(value);
          }
        }
        return value === filterValue
      },
      filterSelectOptions: [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
      ],
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const status = cell?.row?.original?.Active === true ? "Active" : "Inactive";
        return <ColorTag type={status.toLowerCase()}>{status}</ColorTag>;
      }
    }, 
    {
      accessorKey: 'SKU',
      header: 'SKU',
      size: 80,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return cell?.row?.original.ArrangerQty > 0 ? (
          <Links
            param={cell?.row?.original?.AssortmentId}
            modalTitle={"SKUs in Assortment"}
          />
        ) : (
          "N/A"
        );
      }
    },
    {
      header: 'ASSORTMENT START DATE',
      accessorKey: 'StartDate',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const startDate = cell.row?.original.StartDate;
        if (!startDate || startDate === "null") {
          return "N/A";
        }
        const formattedStartDate = formatDate(startDate);
        return formattedStartDate;

      }
    },
    {
      header: 'ASSORTMENT STOP DATE',
      accessorKey: 'StopDate',
      size: 130,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      Cell: ({ cell }: { cell: any }) => {
        const stopDate = cell.row?.original.StopDate;
        if (!stopDate || stopDate === "null") {
          return "N/A";
        }
        const formattedStopDate = formatDate(stopDate);
        return formattedStopDate;

      }
    },
    {
      accessorKey: 'actions',
      header: 'ACTION',
      size: 150,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            <div className="d-flex w-100">
              <Tooltip placement="top" title="Edit">
                <Button
                  icon={<EditOutlined sx={{ fontSize: 20 ,marginLeft: "10px", marginRight: "10px"}}/>}
                  children={undefined}
                  onClick={() => editDrawer(cell?.row?.original)}
                  disabled={!hasPermission("assortmentmanagement_add")}
                ></Button>
              </Tooltip>
              <Tooltip placement="top" title="Delete">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<DeleteOutlined sx={{ fontSize: 20 ,marginLeft: "10px", marginRight: "10px"}}/>}
                  onClick={() => deleteModal(cell?.row?.original?.AssortmentId)}
                  children={undefined}
                  danger={true}
                  disabled={!hasPermission("assortmentmanagement_delete")}
                ></Button>
              </Tooltip>
              <Tooltip placement="top" title="Clone">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<CopyOutlined />}
                  onClick={() => editDrawer(cell?.row?.original, "Clone")}
                  children={undefined}
                  disabled={!hasPermission('assortmentmanagement_clone')}
                ></Button>
              </Tooltip>
            </div>
          </>
        );
      }
    }
  ]
  return { columns, skuColumns, data, loading };
}
