import { useSelector } from "react-redux";
import { ACTION} from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { MODULE_KEY } from "./actions";
import useRolePermission from "src/hooks/useRolePermission";
import { Tooltip } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";

export default function filterColumns(
  deleteModal?: ((record: any) => void) | any
) {
  const { data, loading } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  const { hasPermission } = useRolePermission();
  const columns = [
    {
      id: 'LocationNumber',
      header: 'OFFICE ID',
      accessorKey: 'LocationNumber',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: 'LocationName',
      header: 'OFFICE NAME',
      accessorKey: 'LocationName',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: 'REgion',
      header: 'REGION',
      accessorKey: 'Region',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      id: 'District',
      header: 'DISTRICT',
      accessorKey: 'District',
      size: 140,
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
    },
    {
      accessorKey: 'actions',
      header: 'ACTION',
      size: 150,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ cell }: { cell: any }) => {
        return (
          <>
            <div className="d-flex w-100">
              <Tooltip placement="top" title="Delete">
                <Button
                  icon={<DeleteOutlined sx={{ fontSize: 20 ,marginLeft: "10px", marginRight: "10px"}}/>}
                  onClick={() => deleteModal(cell?.row?.original?.LocationNumber)}
                  children={undefined}
                  danger={true}
                  disabled={!hasPermission("expressoffices_delete")}
                ></Button>
              </Tooltip>
            </div>
          </>
        );
      }
    }
  ]

  return { columns, data, loading };
}
