import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { ReactNode } from "react";
import ColorTag from "../components/common/ColorTag/ColorTag";
import { HolderOutlined } from "@ant-design/icons";
import { SortableHandle } from "react-sortable-hoc";
import Links from "src/components/common/Links/Links";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm A";
export const DATE_TIME_FORMAT_REPLENISHMENT = "YYYY-MM-DD hh:mm A";
export const DATE_FORMAT = "YYYY-MM-DD";

interface BASE_MODAL {
  id: string;
  key: React.Key;
  OfficeNum: string;
}
export interface PRINT_TAGS_DATA_TYPE {
  CountsByColor: any;
  ReceivedDateTime: string;
  ErrorTagCount: number;
  ProcessedDateTime: string;
  RemedyTicketNumber: string;
  RequestProcessedBy: string;
  ProcessedBy: string;
  IsActive: boolean;
  RequestId: string;
  Id: number;
  OfficeNum: string;
}

export interface OPEN_PULL_DATA_TYPE extends BASE_MODAL {
  OfficeNumber: string;
  NickName: string;
  FullName: string;
  OrderDate: string;
  VendorId: string;
  FrameSource: string;
  Status: string;
  LabName: string;
  OfficeName: string;
  OrderNum: string;
  ColorRow: string;
  Vendor: string;
  Employee: string;
  Collection: string;
  ItemName: string;
  ItemStatus: string;
  LabStatus: string;
  LocationStatus: string;
  UPCCode: string;
}
export interface FRAME_PICK_DATA_TYPE extends BASE_MODAL {
  VendorName: any;
  FrameCollection: any;
  OrderNum: string;
  ItemID: string;
  OrderDate: string;
  FullName: string;
  VendorId: string;
  FrameSource: string;
  Status: string;
  LabName: string;
  OfficeName: string;
}
export interface REPLENISHMENT_DATA_TYPE extends BASE_MODAL {
  OrderQuantity: any;
  Id: any;
  SKU: any;
  FrameName: string;
  ItemGroup: string;
  Collection: string;
  VendorName: any;
  Location: string;
  MinQuantity: string;
  MaxQuantity: string;
  OrderID: string;
  OrderDate: string;
  PreviousOrderId: string;
  PreviousOrderStatus: string;
  PreviousOrderDate: string;
  ApprovalStatus: string;
  CreatedDate: string;
  ModifiedDate: string;
  ModifiedBy: string;
  OverrideOrderQuantity: any;
  UnitCost: any;
  ExtCost: any;
}

export interface VENDOR_ORDER_DATA_TYPE extends BASE_MODAL {
  ID: string;
  ItemID: string;
  ItemName: string;
  UPCCode: any;
  Quantity: string;
  Received: string;
  Cost: string;
  VendorReceiptNumber: any;
}

export interface PENDING_ORDER_DATA_TYPE extends BASE_MODAL {
  VendorName: any;
  ID: string;
  ItemID: string;
  ItemName: string;
  UPCCode: any;
  Quantity: string;
  Received: string;
  Cost: string;
  OrderDate: any;
}

export interface VENDOR_DATA_TYPE extends BASE_MODAL {
  ID: number;
  VendorName: string;
}
export interface OFFICE_DATA_TYPE extends BASE_MODAL {
  ID: number;
  LocationName: string;
}

export interface PICKER_DATA_TYPE extends BASE_MODAL {
  PickerId: string;
  PickerName: string;
}

export interface ORDER_STATUS_DATA_TYPE extends BASE_MODAL {
  OrderNumber: any;
  OrderDate: string;
  OrderStatus: string;
  OfficeNumber: string;
  VendorName: any;
  LaboratoryName: any;
  FrameCollection: string;
  FrameName: string;
  UpcCode: string;
  PickerFullName: string;
  ShippingStatus: string;
  FrameStatus: string;
  WorkflowStatus: string;
  VendorOrderStatus: string;
  LaboratoryStatus: string;
  ColorTag: string;
}

export interface DAILY_REPORT_DATA_TYPE {
  OrderDate: any;
  FrameSource: string;
  LabName: string;
  Region: string;
  District: any;
  OfficeManager: any;
  OfficeName: string;
}

export interface KITS_MAINTENANCE_DATA_TYPE extends BASE_MODAL {
  KitId: any;
  KitName: string;
  Vendor: any;
  Collection: any;
  ProductOrder: string;
  ModifiedDate: any;
  StatusText: any;
  PlannedShipDate: any;
}
export interface KITS_FRAME_DATA_TYPE extends BASE_MODAL {
  Collection: string;
  Model: any;
  Color: string;
  ColorDescription: any;
  EyeSize: any;
  UPC: any;
  FrameType: string;
  MEDCost: any;
}

export interface KITS_PROCESSING_DATA_TYPE extends BASE_MODAL {
  KitId: any;
  KitName: string;
  Vendor: any;
  Collection: any;
  PODate: string;
  StatusText: any;
  OfficeNumbers: any;
  ProcessedDate: any;
}

export interface OFFICE_COLLECTION_LISTING_DATA_TYPE extends BASE_MODAL {
  OfficeNum: any;
  LocationName: any;
  Vendor: any;
  Collection: any;
  CollectionType: any;
  Removed: any;
  SellThrough: any;
  UpdatedDate: any;
  FrameArrangerID: any;
  UpdateNotes: any;
  ArrangerQty: any;
  District: any;
  Region: any;
  StopDate: any;
  AssortmentName: any;
}
export interface COLLECTION_LISTING_DATA_TYPE extends BASE_MODAL {
  AssortmentId: any;
  AssortmentName: any;
  AcuityCollectionID: any;
  DatabaseCollection: any;
  AcuityVendorID: any;
  Vendor: any;
  Collection: any;
  FrameTypeID: any;
  FrameTypeDescription: any;
  ArrangerQty: any;
  AssortmentCount: any;
  CountperCollection: any;
  SunQty: any;
  Active: any;
  DateAdded: any;
  SKU: any;
  StartDate: any;
  StopDate: any;
}
export interface VENDOR_COLLECTION_LISTING_DATA_TYPE extends BASE_MODAL {
  DCReplen: any;
  AcuityCollectionID: any;
  DatabaseCollectionID: any;
  AcuityVendorID: any;
  Vendor: any;
  Collection: any;
  Notes: any;
}

export interface PRODUCT_PLANOGRAM_LISTING_DATA_TYPE extends BASE_MODAL {
  ID1: any;
  FrameArrangerID: any;
  Collection: any;
  Vendor: any;
  Model: any;
  StyleName: any;
  ColorNumber: any;
  ColorDescription: any;
  EyeSize: any;
  Bridge: any;
  Temple: any;
  A: any;
  B: any;
  ED: any;
  Material: any;
  Edge: any;
  UPC: any;
  SKUQty: any;
  ty: any;
  FrameType: any;
  Gender: any;
  FrameName: any;
  MEDCost: any;
  Wholesale: any;
  Retail: any;
  RecallDate: any;
  ItemGroup: any;
  NoReplenishmentDate: any;
  EffectiveDate: any;
  PhasingInStyle: any;
  Min: any;
  Max: any;
  StockedAtDC: any;
  LiveCost: any;
  LiveWholeSale: any;
  LiveRetail: any;
  FrameColorFamily: any;
  FrameShape: any;
  AgeGroup: any;
  Rank: any;
  ReceiptDate: any;
  ItemType: any;
  LifeCycleStatus: any;
  LifeCycleStatusDate: any;
  SKUGrade: any;
}

export const PRINT_TAGS_COLUMN: ColumnsType<PRINT_TAGS_DATA_TYPE> = [
  {
    title: "Office #",
    dataIndex: "OfficeNum",
    filterMultiple: true,
    sorter: (a: { OfficeNum: string }, b: { OfficeNum: any }) =>
      a.OfficeNum?.localeCompare(b.OfficeNum),
    sortDirections: ["ascend", "descend"],
    fixed: "left",
    width: 150,
  },
  {
    title: "Remedy Ticket #",
    dataIndex: "RemedyTicketNumber",
    filterMultiple: true,
    sorter: (
      a: { RemedyTicketNumber: string },
      b: { RemedyTicketNumber: any }
    ) => a.RemedyTicketNumber?.localeCompare(b.RemedyTicketNumber),
    sortDirections: ["ascend", "descend"],
    fixed: "left",
    width: 150,
  },
  {
    title: "Received Date",
    dataIndex: "ReceivedDateTime",
    render: (text: string) => {
      return text ? dayjs(text).format(DATE_TIME_FORMAT) : "N/A";
    },
    width: 150,
    filterMultiple: true,
    sorter: (a: { ReceivedDateTime: any }, b: { ReceivedDateTime: any }) =>
      dayjs(a.ReceivedDateTime || 0).unix() -
      dayjs(b.ReceivedDateTime || 0).unix(),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Processed Date",
    dataIndex: "ProcessedDateTime",
    filterMultiple: true,
    sorter: (a: { ProcessedDateTime: any }, b: { ProcessedDateTime: any }) =>
      dayjs(a.ProcessedDateTime || 0).unix() -
      dayjs(b.ProcessedDateTime || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? dayjs(text).format(DATE_TIME_FORMAT) : "N/A";
    },
    width: 150,
  },
  {
    title: "Processed By",
    dataIndex: "ProcessedBy",
    filterMultiple: true,
    sorter: (a: { ProcessedBy: string }, b: { ProcessedBy: any }) =>
      a.ProcessedBy?.localeCompare(b.ProcessedBy),
    sortDirections: ["ascend", "descend"],
    width: 150,
  },
  // ,
  // {
  //   title: "Total Count",
  //   dataIndex: "ErrorTagCount",
  // }
];

export const PICKER_COLUMN: ColumnsType<PICKER_DATA_TYPE> = [
  {
    title: "Picker Id",
    dataIndex: "PickerId",
    filterMultiple: true,
  },
  {
    title: "Picker Name",
    dataIndex: "PickerName",
    filterMultiple: true,
  },
];

export const VENDOR_COLUMN: ColumnsType<VENDOR_DATA_TYPE> = [
  {
    title: "Vendor Id",
    dataIndex: "ID",
    filterMultiple: true,
  },
  {
    title: "Vendor Name",
    dataIndex: "VendorName",
    filterMultiple: true,
  },
];
export const VENDOR_AVIALBLE_COLUMN: ColumnsType<VENDOR_DATA_TYPE> = [
  {
    title: "Available Vendors",
    dataIndex: "VendorName",
    filterMultiple: true,
  },
];
export const VENDOR_EXCLUSION_COLUMN: ColumnsType<VENDOR_DATA_TYPE> = [
  {
    title: "Excluded Vendors",
    dataIndex: "VendorName",
    filterMultiple: true,
  },
];

export const DAILY_REPORT_EXCEL_COLUMNS = [
  {
    header: "Order Date",
    key: "OrderDate",
    width: 20,
  },
  {
    header: "Frame Source",
    key: "FrameSource",
    width: 20,
  },
  {
    header: "Lab Name",
    key: "LabName",
    width: 20,
  },
  {
    header: "Region",
    key: "Region",
    width: 20,
  },
  {
    header: "District",
    key: "District",
    width: 20,
  },

  {
    header: "Office Name",
    key: "OfficeName",
    width: 20,
  },
  {
    header: "Order Number",
    key: "OrderNumber",
    width: 20,
    align: "center",
  },
  {
    header: "Vendor Name",
    key: "VendorName",
    width: 20,
  },
  {
    header: "Item Name",
    key: "ItemName",
    width: 40,
  },
  {
    header: "UPC Code",
    key: "UPCCode",
    width: 20,
  },
  {
    header: "Lab Status",
    key: "LabStatus",
    width: 20,
  },
  {
    header: "Location Status",
    key: "LocationStatus",
    width: 20,
  },
  {
    header: "Notes",
    key: "Notes",
    width: 20,
  },
  {
    header: "Item Status",
    key: "ItemStatus",
    width: 20,
  },
  {
    header: "Status",
    key: "Status",
    width: 20,
  },
  {
    header: "Ref#",
    key: "ReferenceNumber",
    width: 20,
  },
];

export const DC_REPLENISHMENT_EXCEL_COLUMN = [
  {
    header: "SKU",
    key: "SKU",
    width: 20,
  },
  {
    header: "Frame Name",
    key: "FrameName",
    width: 20,
  },
  {
    header: "Vendor",
    key: "VendorName",
    width: 20,
  },
  {
    header: "Collection",
    key: "Collection",
    width: 20,
  },
  {
    header: "Order ID",
    key: "OrderID",
    width: 20,
  },
  {
    header: "Order Quantity",
    key: "OrderQuantity",
    width: 20,
  },
  {
    header: "Override Quantity",
    key: "OverrideOrderQuantity",
    width: 20,
  },
  {
    header: "MED Cost",
    key: "UnitCost",
    width: 20,
  },
  {
    header: "Ext Cost",
    key: "ExtCost",
    width: 20,
  },
  {
    header: "OnHand Quantity",
    key: "OnHandQuantity",
    width: 20,
  },
  {
    header: "Back Order Quantity",
    key: "BackOrderQuantity",
    width: 20,
  },
  {
    header: "Min Quantity",
    key: "MinQuantity",
    width: 20,
  },
  {
    header: "Max Quantity",
    key: "MaxQuantity",
    width: 20,
  },
  {
    header: "Order Date",
    key: "OrderDate",
    width: 20,
  },
  {
    header: "Previous Order Id",
    key: "PreviousOrderId",
    width: 20,
  },
  {
    header: "Prev Order Date",
    key: "PreviousOrderDate",
    width: 20,
  },
  {
    header: "Approval Status",
    key: "ApprovalStatus",
    width: 20,
  },
  {
    header: "Created Date",
    key: "CreatedDate",
    width: 20,
  },
  {
    header: "Modified Date",
    key: "ModifiedDate",
    width: 20,
  },
  {
    header: "Modified By",
    key: "ModifiedBy",
    width: 20,
  },
];

export const ORDER_STATUS_VALUES = [
  {
    name: "Shipped",
  },
  {
    name: "Ordered 3-5 business days",
  },
  {
    name: "Ordered 7-10 business days",
  },
  {
    name: "Coming directly from the vendor - expect delays",
  },
  {
    name: "BackOrdered",
  },
  {
    name: "Ordered - waiting on confirmation from vendor",
  },
  {
    name: "Discontinued",
  },
];

export const PROCESS_KIT_FRAME_COLUMN: ColumnsType<KITS_FRAME_DATA_TYPE> = [
  { title: "Collection", dataIndex: "Collection" },
  { title: "Model", dataIndex: "Model" },
  { title: "Color", dataIndex: "Color" },
  {
    title: "Color Description",
    dataIndex: "ColorDescription",
  },
  {
    title: "Eye Size",
    dataIndex: "EyeSize",
  },
  {
    title: "UPCCode",
    dataIndex: "UPC",
  },
  { title: "Frame Type", dataIndex: "FrameType" },
  {
    title: "MED Cost",
    dataIndex: "MEDCost",
  },
];

export const PROCESS_KIT_OFFICES_COLUMN = [
  { title: "LocationNumber", dataIndex: "LocationNumber" },
  { title: "Quantity", dataIndex: "Quantity" },
];

export const AVIALBLE_OFFICE_COLUMN: ColumnsType<OFFICE_DATA_TYPE> = [
  {
    title: "Available Offices",
    dataIndex: "LocationNumber",
    filterMultiple: true,
  },
];
export const SELECTED_OFFICES_COLUMN: ColumnsType<OFFICE_DATA_TYPE> = [
  {
    title: "selected Offices",
    dataIndex: "LocationNumber",
    filterMultiple: true,
  },
];

export const FRAME_COLLECTION_LISTING_COLUMN: ColumnsType<COLLECTION_LISTING_DATA_TYPE> =
  [
    {
      title: "Assortment ID",
      dataIndex: "AssortmentId",
      filterMultiple: true,
      sorter: (a: { AssortmentId: number }, b: { AssortmentId: number }) =>
        a.AssortmentId - b.AssortmentId,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Assortment Name",
      dataIndex: "AssortmentName",
      filterMultiple: true,
      sorter: (a: { AssortmentName: string }, b: { AssortmentName: any }) =>
        a.AssortmentName?.localeCompare(b.AssortmentName),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Database Collection ID",
      dataIndex: "DatabaseCollection",
      filterMultiple: true,
      sorter: (
        a: { DatabaseCollection: number },
        b: { DatabaseCollection: number }
      ) => a.DatabaseCollection - b.DatabaseCollection,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Frame Type ID",
      dataIndex: "FrameTypeID",
      filterMultiple: true,
      sorter: (a: { FrameTypeID: number }, b: { FrameTypeID: number }) =>
        a.FrameTypeID - b.FrameTypeID,
      sortDirections: ["ascend", "descend"],
      width: 90,
      align: "center",
    },
    {
      title: "Assortment Quantity",
      dataIndex: "ArrangerQty",
      sorter: (a: { ArrangerQty: any }, b: { ArrangerQty: any }) =>
        a.ArrangerQty - b.ArrangerQty,
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Assortment Count",
      dataIndex: "AssortmentCount",
      sorter: (a: { AssortmentCount: any }, b: { AssortmentCount: any }) =>
        a.AssortmentCount - b.AssortmentCount,
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "SKU",
      dataIndex: "SKU",
      width: 60,
      render: (_: any, record: any): ReactNode => {
        return record.ArrangerQty > 0 ? (
          <Links
            param={record?.AssortmentId}
            modalTitle={"SKUs in Assortment"}
          />
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Assortment Start Date",
      dataIndex: "StartDate",
      filterMultiple: true,
      sorter: (a: { StartDate: any }, b: { StartDate: any }) =>
        dayjs(a.StartDate || 0).unix() - dayjs(b.StartDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? dayjs(text).format(DATE_FORMAT) : "N/A";
      },
      width: 120,
      align: "center",
    },
    {
      title: "Assortment Stop Date",
      dataIndex: "StopDate",
      filterMultiple: true,
      sorter: (a: { StopDate: any }, b: { StopDate: any }) =>
        dayjs(a.StopDate || 0).unix() - dayjs(b.StopDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      render: (text: string) => {
        return text ? dayjs(text).format(DATE_FORMAT) : "N/A";
      },
      width: 125,
      align: "center",
    },
  ];

export const VENDOR_COLLECTION_LISTING_COLUMN: ColumnsType<VENDOR_COLLECTION_LISTING_DATA_TYPE> =
  [
    {
      title: "Database Collection ID",
      dataIndex: "DatabaseCollectionID",
      filterMultiple: true,
      sorter: (
        a: { DatabaseCollectionID: number },
        b: { DatabaseCollectionID: number }
      ) => a.DatabaseCollectionID - b.DatabaseCollectionID,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Acuity Collection ID",
      dataIndex: "AcuityCollectionID",
      filterMultiple: true,
      sorter: (
        a: { AcuityCollectionID: number },
        b: { AcuityCollectionID: number }
      ) => a.AcuityCollectionID - b.AcuityCollectionID,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Acuity Vendor ID",
      dataIndex: "AcuityVendorID",
      filterMultiple: true,
      sorter: (a: { AcuityVendorID: number }, b: { AcuityVendorID: number }) =>
        a.AcuityVendorID - b.AcuityVendorID,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      filterMultiple: true,
      width: 100,
      align: "center",
    },
  ];

export const PRODUCT_PLANOGRAM_LISTING_COLUMN: ColumnsType<PRODUCT_PLANOGRAM_LISTING_DATA_TYPE> =
  [
    {
      title: "UPC",
      dataIndex: "UPC",
      filterMultiple: true,
      sorter: (a: { UPC: number }, b: { UPC: number }) => a.UPC - b.UPC,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Frame Name",
      dataIndex: "FrameName",
      filterMultiple: true,
      sorter: (a: { FrameName: string }, b: { FrameName: any }) =>
        a.FrameName?.localeCompare(b.FrameName),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Model",
      dataIndex: "Model",
      filterMultiple: true,
      sorter: (a: { Model: string }, b: { Model: any }) =>
        a.Model?.localeCompare(b.Model),
      sortDirections: ["ascend", "descend"],
      width: 100,
      align: "center",
    },
    {
      title: "Style Name",
      dataIndex: "StyleName",
      filterMultiple: true,
      sorter: (a: { StyleName: number }, b: { StyleName: number }) =>
        a.StyleName - b.StyleName,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Color Number",
      dataIndex: "ColorNumber",
      filterMultiple: true,
      sorter: (a: { ColorNumber: number }, b: { ColorNumber: number }) =>
        a.ColorNumber - b.ColorNumber,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Color Description",
      dataIndex: "ColorDescription",
      filterMultiple: true,
      sorter: (a: { ColorDescription: string }, b: { ColorDescription: any }) =>
        a.ColorDescription?.localeCompare(b.ColorDescription),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Eye Size",
      dataIndex: "EyeSize",
      filterMultiple: true,
      sorter: (a: { EyeSize: number }, b: { EyeSize: number }) =>
        a.EyeSize - b.EyeSize,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Edge",
      dataIndex: "Edge",
      filterMultiple: true,
      sorter: (a: { Edge: string }, b: { Edge: any }) =>
        a.Edge?.localeCompare(b.Edge),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "No Replenish Date",
      dataIndex: "NoReplenishmentDate",
      filterMultiple: true,
      sorter: (
        a: { NoReplenishmentDate: any },
        b: { NoReplenishmentDate: any }
      ) =>
        dayjs(a.NoReplenishmentDate || 0).unix() -
        dayjs(b.NoReplenishmentDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      width: 140,
      align: "center",
    },
    {
      title: "Effective Date",
      dataIndex: "EffectiveDate",
      filterMultiple: true,
      sorter: (a: { EffectiveDate: any }, b: { EffectiveDate: any }) =>
        dayjs(a.EffectiveDate || 0).unix() - dayjs(b.EffectiveDate || 0).unix(),
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
    {
      title: "Rank",
      dataIndex: "Rank",
      filterMultiple: true,
      sorter: (a: { Rank: number }, b: { Rank: number }) => a.Rank - b.Rank,
      sortDirections: ["ascend", "descend"],
      width: 120,
      align: "center",
    },
  ];

export const OFFICE_COLLECTION_EXCEL_COLUMNS = [
  {
    header: "Office Number",
    key: "OfficeNum",
    width: 20,
  },
  {
    header: "Office Name",
    key: "LocationName",
    width: 20,
  },
  {
    header: "Assortment ID",
    key: "FrameArrangerID",
    width: 30,
  },
  {
    header: "Assortment Name",
    key: "AssortmentName",
    width: 20,
  },
  {
    header: "Assortment Quantity",
    key: "ArrangerQty",
    width: 30,
  },
  {
    header: "Vendor Name",
    key: "Vendor",
    width: 20,
  },
  {
    header: "Collection Name",
    key: "Collection",
    width: 20,
  },
  {
    header: "Collection Type",
    key: "CollectionType",
    width: 20,
  },
  {
    header: "Start Date",
    key: "UpdatedDate",
    width: 20,
    align: "center",
  },
  {
    header: "Changed Notes",
    key: "UpdateNotes",
    width: 20,
  },
  {
    header: "Stop Date",
    key: "StopDate",
    width: 20,
    align: "center",
  },
];

export const ASSORTMENT_MANAGEMENT_EXCEL_COLUMNS = [
  {
    header: "Assortment ID",
    key: "AssortmentId",
    width: 20,
  },
  {
    header: "Assortment Name",
    key: "AssortmentName",
    width: 20,
  },
  {
    header: "Frame Type Description",
    key: "FrameTypeDescription",
    width: 30,
  },
  {
    header: "Database Collection ID",
    key: "DatabaseCollection",
    width: 30,
  },
  {
    header: "VendorName",
    key: "Vendor",
    width: 20,
  },
  {
    header: "CollectionName",
    key: "Collection",
    width: 20,
  },
  {
    header: "Frame Type ID",
    key: "FrameTypeID",
    width: 20,
  },
  {
    header: "Assortment Quantity",
    key: "ArrangerQty",
    width: 30,
  },
  {
    header: "Assortment Count",
    key: "AssortmentCount",
    width: 20,
  },
  {
    header: "Active",
    key: "Active",
    width: 20,
  },
  {
    header: "SKU",
    key: "Upcs",
    width: 20,
  },
  {
    header: "Assortment Start Date",
    key: "StartDate",
    width: 20,
    align: "center",
  },
  {
    header: "Assortment Stop Date",
    key: "StopDate",
    width: 20,
  },
];

export const ACCESSORY_REPLENISHMENT_EXCEL_COLUMNS = [
  {
    header: "Product",
    key: "ItemName",
    width: 20,
  },
  {
    header: "UPC Code",
    key: "SKU",
    width: 20,
  },
  {
    header: "Replenisment Type",
    key: "ReplenishmentType",
    width: 20,
  },
  {
    header: "Trigger Quantity",
    key: "ReplenishThreshold",
    width: 20,
  },
  {
    header: "Order Quantity",
    key: "OrderQuantity",
    width: 20,
  },

  {
    header: "On Hand Quantity",
    key: "CurrentOnHandQuantity",
    width: 20,
  },
  {
    header: "In Transit",
    key: "InTransitQuantity",
    width: 20,
  },
  {
    header: "Stocked By DC",
    key: "IsStockedAtDC",
    width: 20,
    align: "center",
  },
  {
    header: "Office",
    key: "LocationID",
    width: 20,
  },
  {
    header: "Consider Backorder",
    key: "IsConsiderBackorder",
    width: 20,
  },
  {
    header: "Current Sales Volume",
    key: "SalesAfterLastReplenishDate",
    width: 20,
  },
  {
    header: "Last Replenishment Date",
    key: "LastReplenishmentDate",
    width: 20,
  },
];

export const PRODUCT_PLANOGRAM_EXCEL_COLUMNS = [
  {
    header: "Product Planogram ID",
    key: "ID1",
    width: 20,
  },
  {
    header: "Assortment(s)",
    key: "AssortmentNames",
    width: 20,
  },
  {
    header: "Vendor",
    key: "Vendor",
    width: 20,
  },
  {
    header: "Collection",
    key: "Collection",
    width: 20,
  },
  {
    header: "Model",
    key: "Model",
    width: 20,
  },
  {
    header: "Style Name",
    key: "StyleName",
    width: 20,
  },
  {
    header: "Color Number",
    key: "ColorNumber",
    width: 20,
    align: "center",
  },
  {
    header: "Color Description",
    key: "ColorDescription",
    width: 20,
  },
  {
    header: "Eye Size",
    key: "EyeSize",
    width: 20,
  },
  {
    header: "Bridge",
    key: "Bridge",
    width: 20,
  },
  {
    header: "Temple",
    key: "Temple",
    width: 20,
  },
  {
    header: "A",
    key: "A",
    width: 20,
  },
  {
    header: "B",
    key: "B",
    width: 20,
  },
  {
    header: "ED",
    key: "ED",
    width: 20,
  },
  {
    header: "Material",
    key: "Material",
    width: 20,
  },
  {
    header: "Edge",
    key: "Edge",
    width: 20,
  },
  {
    header: "UPC",
    key: "UPC",
    width: 20,
  },
  {
    header: "SKUQty",
    key: "SKUQty",
    width: 20,
  },
  {
    header: "FrameType",
    key: "FrameType",
    width: 20,
  },
  {
    header: "Gender",
    key: "Gender",
    width: 20,
  },
  {
    header: "Frame Name",
    key: "FrameName",
    width: 20,
  },
  {
    header: "MED Cost",
    key: "MEDCost",
    width: 20,
  },
  {
    header: "Wholesale",
    key: "Wholesale",
    width: 20,
  },
  {
    header: "Retail",
    key: "Retail",
    width: 20,
  },
  {
    header: "Recall Date",
    key: "RecallDate",
    width: 20,
  },
  {
    header: "ItemGroup",
    key: "ItemGroup",
    width: 20,
  },
  {
    header: "NoReplenishDate",
    key: "NoReplenishmentDate",
    width: 20,
  },
  {
    header: "Effective Date",
    key: "EffectiveDate",
    width: 20,
  },
  {
    header: "Phasing",
    key: "PhasingInStyle",
    width: 20,
  },
  {
    header: "Min",
    key: "Min",
    width: 20,
  },
  {
    header: "Max",
    key: "Max",
    width: 20,
  },
  {
    header: "Stocked At DC",
    key: "StockedAtDC",
    width: 20,
  },
  {
    header: "LiveCost",
    key: "LiveCost",
    width: 20,
  },
  {
    header: "LiveWholeSale",
    key: "LiveWholeSale",
    width: 20,
  },
  {
    header: "LiveRetail",
    key: "LiveRetail",
    width: 20,
  },
  {
    header: "Frame Color Family",
    key: "FrameColorFamily",
    width: 20,
  },
  {
    header: "Frame Shape",
    key: "FrameShape",
    width: 20,
  },
  {
    header: "Age Group",
    key: "AgeGroup",
    width: 20,
  },
  {
    header: "Rank",
    key: "Rank",
    width: 20,
  },
  {
    header: "Receipt Date",
    key: "ReceiptDate",
    width: 20,
  },
  {
    header: "Item Type",
    key: "ItemType",
    width: 20,
  },
  {
    header: "Life Cycle Status",
    key: "LifeCycleStatus",
    width: 20,
  },
  {
    header: "Life Cycle Status Date",
    key: "LifeCycleStatusDate",
    width: 20,
  },
  {
    header: "SKU Grade",
    key: "SKUGrade",
    width: 20,
  },
  {
    header: "Images",
    key: "ImageURLs",
    width: 20,
  },
];

const DragHandle = SortableHandle(() => (
  <HolderOutlined style={{ cursor: "grab", color: "#999" }} />
));

export const PP_COLUMNS: ColumnsType<any> = [
  {
    title: "Column Name",
    dataIndex: "title",
    className: "drag-visible",
  },
  {
    title: "",
    dataIndex: "dragger",
    align: "right",
    render: () => <DragHandle />,
    className: "drag-visible",
  },
];

export const PRODUCT_PLANOGRAM_DRAWER_COLUMN = [
  {
    title: "Product Planogram ID",
    dataIndex: "ID1",
    filterMultiple: true,
    sorter: (a: { ID1: number }, b: { ID1: number }) => a.ID1 - b.ID1,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "SKU Qty",
    dataIndex: "SKUQty",
    filterMultiple: true,
    sorter: (a: { SKUQty: number }, b: { SKUQty: number }) =>
      a.SKUQty - b.SKUQty,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Bridge",
    dataIndex: "Bridge",
    filterMultiple: true,
    sorter: (a: { Bridge: number }, b: { Bridge: number }) =>
      a.Bridge - b.Bridge,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Temple",
    dataIndex: "Temple",
    filterMultiple: true,
    sorter: (a: { Temple: number }, b: { Temple: number }) =>
      a.Temple - b.Temple,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "A",
    dataIndex: "A",
    filterMultiple: true,
    sorter: (a: { A: number }, b: { A: number }) => a.A - b.A,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "B",
    dataIndex: "B",
    filterMultiple: true,
    sorter: (a: { B: number }, b: { B: number }) => a.B - b.B,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "ED",
    dataIndex: "ED",
    filterMultiple: true,
    sorter: (a: { ED: number }, b: { ED: number }) => a.ED - b.ED,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },

  {
    title: "MED Cost",
    dataIndex: "MEDCost",
    filterMultiple: true,
    sorter: (a: { MEDCost: number }, b: { MEDCost: number }) =>
      a.MEDCost - b.MEDCost,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Wholesale",
    dataIndex: "Wholesale",
    filterMultiple: true,
    sorter: (a: { Wholesale: number }, b: { Wholesale: number }) =>
      a.Wholesale - b.Wholesale,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Retail",
    dataIndex: "Retail",
    filterMultiple: true,
    sorter: (a: { Retail: string }, b: { Retail: any }) =>
      a.Retail?.localeCompare(b.Retail),
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Recall Date",
    dataIndex: "RecallDate",
    filterMultiple: true,
    sorter: (a: { RecallDate: any }, b: { RecallDate: any }) =>
      dayjs(a.RecallDate || 0).unix() - dayjs(b.RecallDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Phasing In Style",
    dataIndex: "PhasingInStyle",
    filterMultiple: true,
    sorter: (a: { PhasingInStyle: number }, b: { PhasingInStyle: number }) =>
      a.PhasingInStyle - b.PhasingInStyle,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Min",
    dataIndex: "Min",
    filterMultiple: true,
    sorter: (a: { Min: number }, b: { Min: number }) => a.Min - b.Min,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Max",
    dataIndex: "Max",
    filterMultiple: true,
    sorter: (a: { Max: number }, b: { Max: number }) => a.Max - b.Max,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Stocked at DC",
    dataIndex: "StockedAtDC",
    filterMultiple: true,
    sorter: (a: { StockedAtDC: number }, b: { StockedAtDC: number }) =>
      a.StockedAtDC - b.StockedAtDC,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Live Cost",
    dataIndex: "LiveCost",
    filterMultiple: true,
    sorter: (a: { LiveCost: number }, b: { LiveCost: number }) =>
      a.LiveCost - b.LiveCost,
    sortDirections: ["ascend", "descend"],
    width: 130,
    align: "center",
  },
  {
    title: "Live WholeSale",
    dataIndex: "LiveWholeSale",
    filterMultiple: true,
    sorter: (a: { LiveWholeSale: number }, b: { LiveWholeSale: number }) =>
      a.LiveWholeSale - b.LiveWholeSale,
    sortDirections: ["ascend", "descend"],
    width: 130,
    align: "center",
  },
  {
    title: "Live Retail",
    dataIndex: "LiveRetail",
    filterMultiple: true,
    sorter: (a: { LiveRetail: number }, b: { LiveRetail: number }) =>
      a.LiveRetail - b.LiveRetail,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Receipt Date",
    dataIndex: "ReceiptDate",
    filterMultiple: true,
    sorter: (a: { ReceiptDate: any }, b: { ReceiptDate: any }) =>
      dayjs(a.ReceiptDate || 0).unix() - dayjs(b.ReceiptDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Item Type",
    dataIndex: "ItemType",
    filterMultiple: true,
    sorter: (a: { ItemType: number }, b: { ItemType: number }) =>
      a.ItemType - b.ItemType,
    sortDirections: ["ascend", "descend"],
    width: 120,
    align: "center",
  },
  {
    title: "Life Cycle Status Date",
    dataIndex: "LifeCycleStatusDate",
    filterSearch: true,
    sorter: (
      a: { LifeCycleStatusDate: any },
      b: { LifeCycleStatusDate: any }
    ) =>
      dayjs(a.LifeCycleStatusDate || 0).unix() -
      dayjs(b.LifeCycleStatusDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? dayjs(text).format(DATE_FORMAT) : "N/A";
    },
    width: 120,
    align: "center",
  },
];

export const ASSORTMENT_SKU_COLUMN: ColumnsType<any> = [
  {
    title: "UPC",
    dataIndex: "UPC",
    filterMultiple: true,
    sorter: (a: { UPC: number }, b: { UPC: number }) => a.UPC - b.UPC,
    sortDirections: ["ascend", "descend"],
    width: 30,
    align: "center",
  },
  {
    title: "Model",
    dataIndex: "Model",
    filterMultiple: true,
    sorter: (a: { Model: string }, b: { Model: string }) =>
      a.Model?.localeCompare(b.Model),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "Color",
    dataIndex: "Color",
    filterMultiple: true,
    sorter: (a: { Color: string }, b: { Color: string }) =>
      a.Color?.localeCompare(b.Color),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "Color Description",
    dataIndex: "ColorDescription",
    filterMultiple: true,
    sorter: (
      a: { ColorDescription: string },
      b: { ColorDescription: string }
    ) => a.ColorDescription?.localeCompare(b.ColorDescription),
    sortDirections: ["ascend", "descend"],
    width: 50,
    align: "center",
  },
  {
    title: "Eye Size",
    dataIndex: "EyeSize",
    filterMultiple: true,
    sorter: (a: { EyeSize: number }, b: { EyeSize: number }) =>
      a.EyeSize - b.EyeSize,
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "Frame Type",
    dataIndex: "FrameType",
    filterMultiple: true,
    sorter: (a: { FrameType: string }, b: { FrameType: string }) =>
      a.FrameType?.localeCompare(b.FrameType),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
  {
    title: "MED Cost",
    dataIndex: "MEDCost",
    filterMultiple: true,
    sorter: (a: { MEDCost: string }, b: { MEDCost: string }) =>
      a.MEDCost?.localeCompare(b.MEDCost),
    sortDirections: ["ascend", "descend"],
    width: 20,
    align: "center",
  },
];
const columns: any = {};

export default columns;
