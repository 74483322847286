import styled from "styled-components";
import { formatDate } from "src/util/date";

interface BASE_MODAL {
  id: string;
  key: React.Key;
  OfficeNum: string;
}

export interface BULK_COST_UPDATE_SUMMARY_DATA_TYPE extends BASE_MODAL {
  Id: any;
  OriginalFileName: string;
  UploadedBy: string;
  UploadedDate: any;
  UpdateStatus: any;
  FinalUpdateDate: any;
  FinalUpdateBy: any;
}
  export const BULK_COST_UPDATE_DETAIL_COLUMN = [
    {
      header: "UPC",
      accessorKey: "UPC",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 100,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "ITEM COST",
      accessorKey: "ItemCost",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "VENDORITEM COST",
      accessorKey: "VendorItemCost",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 170,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "LIST PRICE",
      accessorKey: "ListPrice",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "WHOLESALE PRICE",
      accessorKey: "WholesalePrice",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 180,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "DEFAULT RETAIL",
      accessorKey: "DefaultRetail",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 160,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "REGION2",
      accessorKey: "Region2",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "REGION3",
      accessorKey: "Region3",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "STYLE",
      accessorKey: "Style",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "BRAND",
      accessorKey: "Brand",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "SHEETNAME",
      accessorKey: "SheetName",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 200,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "UPDATE DATE",
      accessorKey: "UpdateDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 150,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ cell }: { cell: any }) => {
        const updateDate = cell.row?.original.UpdateDate;
        if (!updateDate || updateDate === "null") {
          return "";
        }
        const formattedUpdateDate = formatDate(updateDate);
        return formattedUpdateDate;
      }
    },
  ];
  export const BULK_COST_UPDATE_VALIDATION_COLUMN = [
    {
      header: "ID",
      accessorKey: "Id",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 120,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "VALIDATION RESULT",
      accessorKey: "ValidationResult",
      size: 900,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ cell }: { cell: any }) => {
        return (
          <VRContainer>
            <div dangerouslySetInnerHTML={{ __html: cell.row?.original.ValidationResult }} />
          </VRContainer>
        );
      },
    },
    {
      header: "CREATED BY",
      accessorKey: "CreatedBy",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 120,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      header: "CREATED DATE",
      accessorKey: "CreatedDate",
      enableColumnFilter: true,
      filterFn: 'contains',
      enableSorting: true,
      size: 120,
      muiTableHeadCellProps: {
        align: 'left',
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      Cell: ({ cell }: { cell: any }) => {
        const createdDate = cell.row?.original.CreatedDate;
        if (!createdDate || createdDate === "null") {
          return "";
        }
        const formattedCreatedDate = formatDate(createdDate);
        return formattedCreatedDate;
      }
    },
  ];

  const VRContainer = styled.div`
  table { width: 100%; border-collapse: collapse; }
  th, td { border: 1px solid black; padding: 1px; text-align: center; }
  th { background-color: #f2f2f2; }
`;